import SagaReducerFactory from '../saga-reducers-factory-patch';
import {call, put} from 'redux-saga/effects';
import { actions, types } from '../actions/TranslationsActions';
import * as translationsApi from '../api/translations';

let {handle, updateState, saga, reducer} = SagaReducerFactory({
    actionTypes: types,
    actionCreators: actions,
    initState: {
        translations: []
    }
});


handle(types.FETCH, function* (sagaParams, { payload }) {
    const translations = yield call(translationsApi.get);
    yield put(updateState({
        translations
    }));
});

handle(types.UPDATE, function* (sagaParams, { payload }) {
    yield call(translationsApi.update, payload);
});

export default {saga, reducer};
