import _ from "lodash";
import { connect } from "react-redux";
import React, { Component } from "react";
import { actions as statsActions } from '../../actions/resonatorStatsActions';
import { actions as followerActions } from '../../actions/followersActions';
import { actions as followerGroupsActions } from '../../actions/followerGroupsActions';
import { bindActionCreators } from 'redux';
import followersSelector from "../../selectors/followersSelector";
import ExpandableCard from "../ExpandableCard";
import ResonatorStats from "../ResonatorStats";
import { CircularProgress, Typography, Divider, withWidth } from "@material-ui/core";
import { RemoveRedEye } from "@material-ui/icons";
import * as utils from "../utils";
import { push } from "connected-react-router";
import api from "../../api";
import LoadingOverlay from "components/followers/LoadingOverlay";
import { withTranslation } from 'react-multi-lang';

class FollowerShowResonator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            iframeWidth: 0,
            iframeHeight: 0,
            expanded: false,
            resonator: false
        };

        this.handleIframeLoad = this.handleIframeLoad.bind(this);
        this.fetchResonator = this.fetchResonator.bind(this);
    }

    fetchResonator() {
        const resonatorId = this.props.match.params.resonatorId;
        api.get(`/follower/resonators/${resonatorId}`)
            .then(({ resonator }) => {
                this.setState({resonator});
            })
    }

    handleIframeLoad(ev) {
        let { scrollWidth, scrollHeight } = ev.target.contentWindow.document.body;

        this.setState({
            iframeWidth: scrollWidth * 1.04,
            iframeHeight: scrollHeight + 21,
        });
    }

    renderSectionTitle({ title, bottomLeftActions, bottomRightActions, rightActions }) {
        return (
            <div style={{ marginBottom: 20 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <Typography variant="h5" align='center' noWrap style={{ direction: utils.getDir(title) }}>
                            {title}
                        </Typography>
                    </div>
                    <div>
                        {rightActions}
                    </div>
                </div>
                <Divider style={{ margin: 10 }} />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        {bottomLeftActions}
                    </div>
                    <div>
                        {bottomRightActions}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { t } = this.props;
        const resonator  = this.state.resonator;
        const resonatorId = this.props.match.params.resonatorId;
        if (!resonatorId || !resonator) {
            resonatorId && this.fetchResonator();
            return <LoadingOverlay loading={!resonator} />;
        }

        return (
            <div>
                {this.renderSectionTitle({ title: resonator.title })}
                <div>
                    <ExpandableCard
                        onExpandChange={(expanded) => {
                            if (!expanded) {
                                this.setState({
                                    iframeWidth: 0,
                                    iframeHeight: 0,
                                    expanded: expanded
                                })
                            } else {
                                this.setState({
                                    expanded: expanded
                                })
                            }
                        }}
                        width={this.state.iframeWidth || 497}
                        height={this.state.iframeHeight || 60}
                        id={`resonatorPreview-${resonator.resonator_id}`}
                        defaultCardData={{
                            expanded: _.size(resonator.questions) === 0,
                        }}
                        title={t('resonators.resonator') + " Preview"}
                        avatar={<RemoveRedEye />}
                    >
                        <div style={{ height: this.state.iframeHeight }}>
                            {!this.state.expanded && (
                                <CircularProgress style={{ margin: "0 auto", display: "block" }} />
                            )}
                            {this.state.expanded && (
                                <iframe
                                    onLoad={this.handleIframeLoad}
                                    style={{ border: 0, height: "100%", width: "100%" }}
                                    src={`/api/reminders/${resonator.resonator_id}/render`}
                                />
                            )}
                        </div>
                    </ExpandableCard>
                </div>
                {_.size(resonator.questions) > 0 && (
                    <React.Fragment>
                        <div style={{ marginTop: 40 }}>
                            {this.renderSectionTitle({
                                title: t('criteria.criteria')
                            })}
                            <ResonatorStats
                                resonatorId={resonator.resonator_id}
                                follower={false}
                                followerGroup={false} />
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const followersData = followersSelector(state);
    return {
        follower: _.find(followersData.followers, (f) => f.id === ownProps.match.params.followerId),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchMembersWithResonatorChildren: followerGroupsActions.fetchMembersWithResonatorChildren,
        fetchFollowerGroupResonators: followerGroupsActions.fetchFollowerGroupResonators,
        fetchFollowerResonators: followerActions.fetchFollowerResonators,
        downloadResonatorStats: statsActions.downloadResonatorStats,
        push
    }, dispatch);
}

export default withTranslation(connect(mapStateToProps, mapDispatchToProps)(withWidth()(FollowerShowResonator)));
