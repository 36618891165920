import { connect } from "react-redux";
import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Tabs, Tab } from '@material-ui/core';
import {PayPalButton} from "react-paypal-button-v2";
import Loader from './Loader';

class donatePaypalModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            amount: 50,
            showLoader: true,
            language: 0
        };
        this.paypalClientId = "AehU2y9cvzNuEsPDy4yJ9m7WLSyKWkwC_3Rhdyepx8uzoA37E7ApsDbR-LVt0UenARds4ucNtgYKUN04";
    }

    componentDidMount() {
        if (this.props.user.country === "il" && this.state.language !== 1) this.setState({language: 1});
    }

    render() {
        return (
            <Dialog open={this.props.open} onClose={this.props.onClose}>
                <Loader open={this.state.showLoader} />
                <DialogTitle>Donate</DialogTitle>
                <DialogContent>
                    <Tabs
                        value={this.state.language}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={(e, value) => this.setState({language: value})}
                        style={{ marginBottom: "20px" }}
                    >
                        <Tab label="ENGLISH"/>
                        <Tab label="HEBREW"/>
                    </Tabs>
                    <div hidden={this.state.language !== 0}>
                        Dear friends, Psysession resonator system is operated and completely self-funded by our team of therapists and while using it ourselves, we scale it up and maintain it as a service to our community since 2015. We want to try and keep it operational for you free of charge in order to improve the therapeutic treatment and its results. We would be happy if you could help us maintain and develop our system with a donation. Any amount will help. Fill in your personal details in the following form and we will send you a receipt by e-mail.
                    </div>
                    <div hidden={this.state.language !== 1} style={{ direction: "rtl" }}>
                        חברים יקרים, מערכת הרזונטורז של Psysession מתופעלת על ידינו מכספנו הפרטי. אנו אנשי טיפול בעצמנו ובונים את המערכת למען הקהילה - בשלב זה  - ומאז שנת 2015 ללא כל תשלום על מנת לשפר את הטיפול ותוצאותיו. נשמח אם תעזרו לנו לקיים אותה בעזרת תרומה. מלאו את הפרטים בטפסים שיופיעו במסך הבא ונשלח לכם קבלה בדואר אלקטרוני. בתודה מראש
                    </div>
                    <label for="donateAmount" style={{ display:"block", margin: "20px 0", textAlign: "center" }}>Amount:
                        $<input
                            type="number"
                            defaultValue={this.state.amount}
                            onChange={(e) => this.setState({ amount: e.target.value })}
                            min={5}
                            style={{
                                padding: "5px",
                                margin: "5px",
                                borderRadius: "5px",
                                border: "1px solid grey"
                            }}
                        />
                    </label>
                    <PayPalButton
                        amount={this.state.amount}
                        shippingPreference="NO_SHIPPING"
                        onButtonReady={() => this.setState({ showLoader: false })}
                        onSuccess={(details, data) => {
                            alert("Much appreciated!");
                        }}
                        options={{
                            clientId: this.paypalClientId
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default connect((state) => ({user: state.session.user}), null)(donatePaypalModal);
