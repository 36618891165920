import React, { Component } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import { runMigration } from "../api/dbmigration";
import { withRouter } from "react-router";

class DBMigration extends Component {
    constructor() {
        super();
        this.state = {
            stdout: "",
            stderr: "",
            error: "",
        };
    }

    getErrorString = () => {
        if (this.state.error.length > 0) return "Error: " + this.state.error;
        return "No errors";
    };
    getStderrString = () => {
        if (this.state.stderr.length > 0) return "stderr: " + this.state.stderr;
        return "Nothing in stderr";
    };

    getStdoutString = () => {
        if (this.state.stdout.length > 0) return "stdout: " + this.state.stdout;
        return "Nothing in stdout";
    };

    componentDidMount() {
        const { password } = this.props.match.params;
        runMigration(password).then((result) => {
            this.setState({
                stdout: result.stdout || "",
                stderr: result.stderr || "",
                error: this.error || "",
            });
        });
    }

    render() {
        return (
            <Grid container direction="row">
                <Grid item xs={6} style={{ height: "300px", border: "1px solid #ccc", padding: "5px" }}>
                    {this.getStdoutString()}
                </Grid>
                <Grid item xs={6} style={{ height: "300px", border: "1px solid #ccc", padding: "5px" }}>
                    {this.getStderrString()}
                </Grid>
            </Grid>
        );
    }
}

export default withRouter(DBMigration);
