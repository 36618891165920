import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import { Card, CardHeader, CardContent, Button, Typography } from "@material-ui/core";

import "./LoginForm.scss";
import googleIcon from "../Icons/GoogleIcon";
import TextField from "../FormComponents/TextField";
import { actions as sessionActions } from "../../actions/sessionActions";
import { actions as navigationActions } from "../../actions/navigationActions";
import { withTranslation } from "react-multi-lang";

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLeader: props.isLeaderPage,
        };

        this.handleGoogleLogin = this.handleGoogleLogin.bind(this);
        this.switchLoginMode = this.switchLoginMode.bind(this);
    }

    componentDidMount() {
        if (this.props.isApk) this.handleGoogleLogin();
    }

    handleGoogleLogin() {
        this.props.googleLogin({ isLeader: this.state.isLeader });
    }

    switchLoginMode() {
        this.setState({ isLeader: !this.state.isLeader });
    }

    getGoogleLoginError(t) {
        if (this.props.errorGoogle.includes("not_follower")) {
            const [errorResponse, googleEmail] = this.props.errorGoogle.split(":");
            return t("auth.error_" + errorResponse).replace(/__email__/g, googleEmail);
        }

        return this.props.errorGoogle;
    }

    getGoogleAuthMessage = () => {
        const { t } = this.props;
        if (this.state.isLeader) return t("auth.google_only");
        return null;
    };

    shouldAllowUserPasswordAuth = () => {
        return !this.state.isLeader || (this.props.deploymentType && this.props.deploymentType !== "production");
    };

    render() {
        const { t } = this.props;
        const role = this.state.isLeader ? t("leaders.leader").toUpperCase() : t("followers.follower").toUpperCase();
        const google_error_message = this.props.errorGoogle ? this.getGoogleLoginError(t) : "";

        return (
            <Card className="login-form" elevation={10}>
                <CardHeader title={t("auth.welcome")} subheader={this.getGoogleAuthMessage()} />
                <CardContent>
                    <Typography style={{ color: "#38761d", fontWeight: "bold" }}>SIGN IN AS A {role}</Typography>
                    <Typography paragraph>
                        {this.state.isLeader
                            ? "(Therapist / Research leader)"
                            : "(Patient, Research Participant, Group Member)"}
                    </Typography>
                    {!this.state.isLeader && (
                        <Typography paragraph>Clients registered with Gmail, login via Google:</Typography>
                    )}
                    <Button
                        fullWidth
                        type="button"
                        variant="outlined"
                        startIcon={googleIcon}
                        onClick={this.handleGoogleLogin}
                    >
                        Continue with Google
                    </Button>
                    {google_error_message && (
                        <div className="error" style={{ color: "red", textAlign: "center" }}>
                            {google_error_message}
                        </div>
                    )}
                </CardContent>
                {this.shouldAllowUserPasswordAuth() && (
                    <CardContent>
                        <Typography paragraph>
                            Clients with email other than Gmail, use your email address and password:
                        </Typography>
                        <form onSubmit={this.props.handleSubmit}>
                            <Field type="email" name="email" component={TextField} props={{ placeholder: "Email" }} />
                            <br />
                            <Field
                                type="password"
                                name="password"
                                component={TextField}
                                props={{ placeholder: "Password" }}
                            />
                            {this.props.submitFailed && "Login failed"}
                            <Button
                                type="submit"
                                className="submitBtn"
                                color="primary"
                                variant="contained"
                                style={{ marginTop: 30, marginBottom: 10 }}
                                onClick={() => {
                                    this.props.change("isLeader", this.state.isLeader);
                                }}
                            >
                                submit
                            </Button>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Button
                                    type="button"
                                    onClick={this.props.showForgotPasswordModal}
                                    className="registerBtn"
                                >
                                    Forgot password?
                                </Button>
                            </div>
                        </form>
                    </CardContent>
                )}
                {(this.props.isLeaderPage ||
                    window.matchMedia("(display-mode: standalone)").matches ||
                    window.navigator.standalone ||
                    document.referrer.includes("android-app://")) && ( // Show the switch in Leader mode or in standalone PWA app
                    <CardContent>
                        <div className="leader-switch_mode">
                            <span>
                                Click <Button onClick={this.switchLoginMode}>HERE</Button> to{" "}
                                <b style={{ color: "#38761d" }}>
                                    sign in as a {!this.state.isLeader ? t("leaders.leader") : t("followers.follower")}
                                </b>
                            </span>
                            <Typography paragraph>
                                {!this.state.isLeader
                                    ? "(Therapist / Research leader)"
                                    : "(Patient, Research Participant, Group Member)"}
                            </Typography>
                        </div>
                    </CardContent>
                )}
            </Card>
        );
    }
}

function mapStateToProps(state) {
    return {
        errorGoogle: state.router.location.query.error,
        isApk: state.router.location.query.isapk || false,
        deploymentType: state.init.deploymentType,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            showRegistrationModal: (isLeader) =>
                navigationActions.showModal({
                    name: "registration",
                    props: {
                        isLeader: isLeader,
                    },
                }),
            showForgotPasswordModal: () =>
                navigationActions.showModal({
                    name: "forgotPassword",
                }),
            googleLogin: sessionActions.googleLogin,
        },
        dispatch
    );
}

export default withTranslation(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            form: "login",
        })(LoginForm)
    )
);
