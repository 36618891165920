import _ from "lodash";
import SagaReducerFactory from "../saga-reducers-factory-patch";
import { call, put, select, take } from "redux-saga/effects";
import { actions, types } from "../actions/resonatorCreationActions";
import { actions as navigationActions } from "../actions/navigationActions";
import resonatorsSelector from "../selectors/resonatorsSelector";
import { waitForFollowers, fetchFollowerResonators, updateResonator } from "./followersSaga";
import { waitForFollowerGroups, fetchFollowerGroupResonators, updateGroupResonator } from "./followerGroupsSaga";
import * as resonatorApi from "../api/resonator";
import * as groupResonatorApi from "../api/groupResonator";
import { types as resonatorTypes } from "../actions/resonatorActions";

const targetFactory = {
    ["follower"]: {
        waitForTarget: waitForFollowers,
        fetchResonators: fetchFollowerResonators,
        updateResonator,
        resonatorApi,
        navigationRoute: "followerResonators",
        targetIdName: "followerId",
        targetIdDbName: "follower_id",
    },
    ["followerGroup"]: {
        waitForTarget: waitForFollowerGroups,
        fetchResonators: fetchFollowerGroupResonators,
        updateResonator: updateGroupResonator,
        resonatorApi: groupResonatorApi,
        navigationRoute: "followerGroupResonators",
        targetIdName: "followerGroupId",
        targetIdDbName: "follower_group_id",
    },
};

let { handle, updateState, saga, reducer } = SagaReducerFactory({
    actionTypes: types,
    actionCreators: actions,
    initState: {
        formData: {},
        showSpinnerFinalUpdate: false,
        resonator: null,
    },
});

const formDataSelector = (state) => state.resonatorCreation.formData;

handle(types.UPDATE_FINAL, function* (sagaParams, { payload: { targetType, showPreview, description } }) {
    yield put(updateState({ showSpinnerFinalUpdate: true }));

    const target = targetFactory[targetType];

    let formData = yield getFormData();

    // In Edit mode we keep the description value inside the resonator creation component.
    // If it was changed, we now move it to redux
    if (description) {
        formData = { ...formData, description };
        yield put(updateState({ formData: { ...formData } }));
    } else {
    }
    const targetId = yield select((state) => state.resonatorCreation[target.targetIdName]);

    let resonator = yield select((state) => state.resonatorCreation.resonator);
    //usually the resonator is created on completion of the Schedule step, create it here if that didn't happen
    if (!resonator) {
        yield put(actions.create({ targetType }));
        do {
            resonator = yield select((state) => state.resonatorCreation.resonator);
            if (resonator) break;
            else yield take("*");
        } while (!resonator);
    }

    function cleanupOldFile() {
        if (formData.removeOldFile) {
            const lastPicture = _(resonator.items)
                .filter((i) => i.media_kind === "picture")
                .sortBy((i) => new Date(i.createdAt))
                .last();
            if (lastPicture) return target.resonatorApi.cleanupOldFile(targetId, resonator.id, lastPicture.id);
        }
    }

    function syncMedia() {
        if (formData.imageFile) {
            return target.resonatorApi.uploadMedia(targetId, resonator.id, formData.imageFile);
        } else if (formData.imageUrl) {
            return target.resonatorApi.createResonatorAttachment(targetId, resonator.id, formData.imageUrl);
        } else if (formData.imageBase64) {
            return target.resonatorApi.uploadBase64(targetId, resonator.id, formData.imageBase64);
        }
    }

    function uploadBreatherSounds() {
        if (
            formData.breatherConfig?.inhale_sound ||
            formData.breatherConfig?.exhale_sound ||
            formData.breatherConfig?.inhale_sound === null ||
            formData.breatherConfig?.exhale_sound === null
        ) {
            return target.resonatorApi.uploadBreatherSounds(targetId, resonator.id, formData.breatherConfig);
        }
    }

    function syncCriteria() {
        if (formData.criteria)
            return syncResonatorCriteria(resonator, formData.criteria, target, formData.criteriaOrder);
    }

    function syncCriteriaOrder() {
        if (formData.criteriaOrder) return syncResonatorCriteriaOrder(resonator, formData.criteriaOrder, target);
    }

    function syncResonatorData() {
        const payload = convertFormToPayload({ targetId, target, formData });
        payload.id = resonator.id;
        return target.resonatorApi.update(targetId, payload);
    }

    const promise = Promise.all([cleanupOldFile(), syncMedia(), syncCriteria()])
        .then(syncCriteriaOrder)
        .then(syncResonatorData)
        .then(uploadBreatherSounds);

    const updatedResonator = yield call(() => promise);
    yield target.updateResonator(targetId, { ...resonator, ...updatedResonator });
    yield put(updateState({ showSpinnerFinalUpdate: false }));
    if (showPreview) {
        yield put(
            navigationActions.navigate({
                route: "resonatorStats",
                routeParams: { followerId: targetId, resonatorId: resonator.id },
            })
        );
    } else {
        yield put(
            navigationActions.navigate({
                route: target.navigationRoute,
                routeParams: { [target.targetIdName]: targetId },
            })
        );
    }

    // leave the area clean for next generations to come
    yield put(
        updateState({
            formData: {
                interval: 1,
                contributor: formData.contributor,
                expiration: "36 hours",
            },
            resonator: {},
        })
    );
});

handle(types.UPDATE_CREATION_STEP, function* (sagaParams, { payload }) {
    const currentFormData = yield select(formDataSelector);

    yield put(
        updateState({
            formData: {
                ...currentFormData,
                ...payload,
            },
        })
    );
});

handle(types.RESET, function* (sagaParams, { payload: { targetId, targetType, resonatorId } }) {
    console.log("RESET. Fetching resonator");
    const target = targetFactory[targetType];
    yield target.waitForTarget();
    yield target.fetchResonators(targetId);
    const resonators = yield select(resonatorsSelector);
    const resonator = _.find(resonators, (r) => r.id === resonatorId);
    const leader = yield select((state) => state.leaders.leaders);

    const formData = resonator
        ? convertResonatorToForm(resonator)
        : {
              interval: 1,
              contributor: leader?.title,
              expiration: "36 hours",
          };

    yield put(
        updateState({
            [target.targetIdName]: targetId,
            resonator,
            formData,
            editMode: !!resonatorId,
        })
    );
});

handle(resonatorTypes.ACTIVATE, function* (sagaParams, { payload: { targetId, targetType, resonator } }) {
    const target = targetFactory[targetType];

    const updatedResonator = yield call(target.resonatorApi.update, targetId, resonator);

    yield target.updateResonator(targetId, { ...resonator, ...updatedResonator });
    yield put(updateState({ showSpinnerFinalUpdate: false }));
    yield put(
        navigationActions.navigate({
            route: target.navigationRoute,
            routeParams: { [target.targetIdName]: targetId },
        })
    );
});

handle(resonatorTypes.RESET, function* (sagaParams, { payload: { followerId, resonator } }) {
    const parentId = resonator.parent_resonator_id;
    const resonators = yield select(resonatorsSelector);
    const {
        follower_group_id,
        parent_resonator_id,
        id,
        follower_id,
        last_pop_time,
        createdAt,
        updatedAt,
        // Above are all the fields we don't want to reset
        ...parentResonator
    } = _.find(resonators, (r) => r.id === parentId);

    const updatedResonator = yield call(resonatorApi.update, followerId, { ...resonator, ...parentResonator });

    yield updateResonator(followerId, { ...resonator, ...updatedResonator });
    yield put(updateState({ showSpinnerFinalUpdate: false }));
    yield put(
        navigationActions.navigate({
            route: "followerResonators",
            routeParams: { followerId },
        })
    );
});

handle(types.CREATE, function* (sagaParams, { payload: { targetType } }) {
    const target = targetFactory[targetType];
    const formData = yield getFormData();
    const targetId = yield select((state) => state.resonatorCreation[target.targetIdName]);
    const requestPayload = convertFormToPayload({ targetId, target, formData });
    const response = yield call(target.resonatorApi.create, targetId, requestPayload);

    yield put(
        updateState({
            resonator: response,
        })
    );
});

handle(types.COPY_TO, function* (sagaParams, { payload: { targetType, resonatorId, followerId, groupId } }) {
    const target = targetFactory[targetType];
    const resonators = yield select(resonatorsSelector);
    const resonator = _.find(resonators, (r) => r.id === resonatorId);
    const formData = convertResonatorToForm(_.omit(resonator, ["id", "createdAt", "updatedAt", "follower_id"]));
    const targetId = targetType === "follower" ? followerId : groupId;
    const requestPayload = convertFormToPayload({ targetId, target, formData });

    requestPayload.is_system = false;
    requestPayload.pop_email = false;
    requestPayload.disable_copy_to_leader = true;
    if (requestPayload.breather?.id) requestPayload.breather.id = undefined;

    const response = yield call(target.resonatorApi.create, targetId, requestPayload);

    const resonatorQuestions = _.map(resonator.questions, "question_id");
    if (resonatorQuestions)
        target.resonatorApi.addBulkCriterion(targetId, response.id, resonatorQuestions, formData.criteriaOrder);

    if (resonator.items) {
        resonator.items.forEach((image) => {
            target.resonatorApi.createResonatorAttachment(targetId, response.id, image.link);
        });
    }

    yield target.fetchResonators(targetId);

    yield put(
        navigationActions.navigate({
            route: target.navigationRoute,
            routeParams: { [target.targetIdName]: targetId },
        })
    );
});

function syncResonatorCriteria(resonator, newCriteria, target, newOrder = []) {
    let resonatorQuestions = _.map(resonator.questions, "question_id");
    let addedQids = _.difference(newCriteria, resonatorQuestions);
    let removedQids = _.difference(resonatorQuestions, newCriteria);

    var promisesStack = [];
    let addQuestionsPromises = target.resonatorApi.addBulkCriterion(
        resonator[target.targetIdDbName],
        resonator.id,
        addedQids,
        newOrder
    );
    promisesStack.push(addQuestionsPromises);
    let removedQuestionsPromises = _.map(removedQids, (qid) => {
        let rqid = _.find(resonator.questions, (rq) => rq.question_id === qid).id;
        return target.resonatorApi.removeCriterion(resonator[target.targetIdDbName], resonator.id, rqid);
    });
    if (removedQuestionsPromises.length > 0) {
        promisesStack.push(removedQuestionsPromises);
    }
    return promisesStack;
}

function syncResonatorCriteriaOrder(resonator, newOrder, target) {
    return target.resonatorApi.reorderCriterion(resonator[target.targetIdDbName], resonator.id, newOrder);
}

function* getFormData() {
    const reduxFormData = yield select((state) => state.form.resonatorCreation.values);
    const sagaFormData = yield select(formDataSelector);

    return {
        ...sagaFormData,
        ...reduxFormData,
    };
}

function convertFormToPayload({ targetId, target, formData }) {
    const repeat_days = _.reduce(
        [0, 1, 2, 3, 4, 5, 6],
        (acc, cur) => {
            if (formData[`day${cur}`]) acc.push(cur);
            return acc;
        },
        []
    );

    const payload = {
        [target.targetIdDbName]: targetId,
        title: formData.title,
        content: formData.description,
        one_off: formData.oneOff === "on",
        repeat_days,
        interaction_type: formData.interactionType,
        disable_copy_to_leader: formData.sendMeCopy !== "on",
        link: formData.link,
        tags: formData.tags,
        pop_email: formData.activated === "on",
        pop_time: formData.time?.toISOString(),
        selected_questionnaire: formData.selectedQuestionnaire,
        questionnaire_details: formData.questionnaireDetails,
        interval: formData.interval,
        expiration: formData.expiration || "no",
        is_system: formData.is_system,
        contributor: formData.contributor,
        breather: formData.breather === "on" ? formData.breatherConfig : false,
    };

    return payload;
}

function convertResonatorToForm(resonator) {
    const repeatDays = _.reduce(
        [0, 1, 2, 3, 4, 5, 6],
        (acc, cur) => {
            acc[`day${cur}`] = _.includes(resonator.repeat_days, cur);
            return acc;
        },
        {}
    );
    const criteriaOrder = _.reduce(
        _.orderBy(resonator.questions, (q) => q.updatedAt),
        (q, cur) => {
            if (cur.order >= 0) q[cur.order] = cur.question_id;
            else q.push(cur.question_id);
            return q;
        },
        []
    );

    const form = {
        ...repeatDays,
        title: resonator.title,
        description: resonator.content,
        sendMeCopy: resonator.disable_copy_to_leader ? "off" : "on",
        interactionType: resonator.interaction_type ? 0 : resonator.interaction_type,
        link: resonator.link,
        tags: resonator.tags,
        activated: resonator.pop_email ? "on" : "off",
        time: new Date(resonator.pop_time),
        criteria: _.map(resonator.questions, "question_id"),
        criteriaOrder: criteriaOrder,
        oneOff: resonator.one_off ? "on" : "off",
        selectedQuestionnaire: resonator.selected_questionnaire,
        questionnaireDetails: resonator.questionnaire_details,
        interval: resonator.interval,
        expiration: resonator.expiration,
        is_system: resonator.is_system,
        contributor: resonator.contributor,
        breather: resonator.breather ? "on" : "off",
        breatherConfig: resonator.breather,
    };

    return form;
}

export default { saga, reducer };
