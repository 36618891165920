import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { Bluetooth } from "@material-ui/icons";
import "./Breather.scss";
import { bindActionCreators } from "redux";
import { actions } from "actions/resonatorCreationActions";
import BreatherControls from "./BreatherControls";
import Timer from "./Timer";

var audioInhale;
var audioExhale;
var prevFocus = 0; // used for calculating whether the focus circle is going up or down
const initialPhaseStart = Date.now();

const BreatherApp = function Breather({
    breather,
    updateConfig,
    isFullscreen = false,
    stop = true,
    handleStopBreather,
    HRVConnected,
    connectHRVDevice,
    HRVbpm,
    HRVamp,
}) {
    const t = useTranslation();
    const [inhaleSecs, setInhaleSecs] = useState(breather?.inhale || 5);
    const [holdinSecs, setHoldinSecs] = useState(breather?.hold || 3);
    const [exhaleSecs, setExhaleSecs] = useState(breather?.exhale || 5);
    const [holdoutSecs, setHoldoutSecs] = useState(breather?.pause || 2);
    const [phaseStart, setPhaseStart] = useState(initialPhaseStart);
    const [hideBreather, setHideBreather] = useState(false);
    const [isBreatherPause, setBreateherPause] = useState(false);
    //The code below is kindly borrowed from grc.com/breathe.htm

    const POINTS = 9; // the number of wave points
    const LINE_WIDTH = 5; // wave's line width
    var FOCUS_RADIUS; // diameter of our breathing focus circle
    var FOCUS_LINE;
    var FOCUS_LEFT;
    var FOCUS_DIAM;
    var TWO_PI = Math.PI * 2; // used for circular arc

    var theCanvas;

    var waveSecs;
    var waveMsecs;
    var pixelsPerMsec;
    var hMin; // upper wave height
    var hMax; // lower wave height

    var h; // canvas height (global)
    var w; // canvas width (global)
    var middle; // canvas middle width
    var x = new Array(POINTS); // array of horizontal positions
    var y = new Array(POINTS); // array of vertical positions
    var c;

    var audioStarted = false;

    useEffect(() => {
        handleWakeLock();
        setWaveParams();
        return () => {
            audioInhale && audioInhale.pause();
            audioExhale && audioExhale.pause();
            audioStarted = false;
        };
    }, []);

    useEffect(() => {
        if (stop) {
            audioInhale && audioInhale.pause();
            audioExhale && audioExhale.pause();
            audioStarted = false;
        }
    }, [stop]);

    useEffect(() => {
        if (stop) return;
        ("");
        breather.inhale >= 0 && setInhaleSecs(breather.inhale);
        breather.exhale >= 0 && setExhaleSecs(breather.exhale);
        breather.hold >= 0 && setHoldinSecs(breather.hold);
        breather.pause >= 0 && setHoldoutSecs(breather.pause);
        if (breather.inhale_sound && !(breather.inhale_sound instanceof File))
            audioInhale = new Audio(breather.inhale_sound);
        if (breather.exhale_sound && !(breather.exhale_sound instanceof File))
            audioExhale = new Audio(breather.exhale_sound);

        theCanvas = document.getElementById("canvasOne");
        if (theCanvas) {
            if (!theCanvas.getContext) {
                alert("No Canvas Support!");
            }
            c = theCanvas.getContext("2d"); // canvas context
            showCurrentWave(true);
        }
    }, [breather, stop]);

    useInterval(showCurrentWave, 1000 / 15); // 15 FPS

    async function handleWakeLock() {
        let wakeLock = null;
        if ("wakeLock" in navigator) {
            try {
                wakeLock = await navigator.wakeLock.request("screen");
            } catch (err) {
                console.log("Wakelock is not available");
            }
        }
    }

    function handleInhaleSound(file) {
        if (file) {
            audioInhale = new Audio(URL.createObjectURL(file));
        } else {
            if (audioInhale) audioInhale.pause();
            audioInhale = undefined;
            audioStarted = false;
        }
    }

    function handleExhaleSound(file) {
        if (file) {
            audioExhale = new Audio(URL.createObjectURL(file));
        } else {
            if (audioExhale) audioExhale.pause();
            audioExhale = undefined;
            audioStarted = false;
        }
    }

    function pausePlayBreather(stop) {
        switch (stop) {
            case 0: //start
                handleStopBreather(false);
                break;
            case 1: //pause
                setPhaseStart(Date.now());
                handleStopBreather(true);
                break;
            case 2: //stop
                handleStopBreather(true);
                break;
            case 3: //restart
                setPhaseStart(initialPhaseStart);
                handleStopBreather(true);
                handleStopBreather(false);
                break;
        }
    }

    function setWaveParams() {
        if (!theCanvas) return false;
        w = theCanvas.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        middle = w / 2; // canvas half-width

        FOCUS_RADIUS = 40; // set the diameter of our breathing focus circle
        FOCUS_LINE = 5; // set the diameter of our
        FOCUS_LEFT = middle - FOCUS_RADIUS - FOCUS_LINE / 2;
        FOCUS_DIAM = FOCUS_RADIUS * 2 + FOCUS_LINE;

        // size and position the various page items...
        theCanvas.height = Math.round(h / 2 + FOCUS_DIAM * 2);

        hMin = FOCUS_RADIUS + FOCUS_LINE; // Math.round(0.25 * h);			// upper wave height
        hMax = (FOCUS_RADIUS + FOCUS_LINE) * 2 + h / 2; // lower wave height

        waveSecs = inhaleSecs + exhaleSecs + holdinSecs + holdoutSecs; // total seconds for the wave
        waveMsecs = waveSecs * 1000; // total milliseconds for the wave
        pixelsPerMsec = w / waveMsecs; // time to pixels conversion factor

        var inhaleHorz = Math.round((inhaleSecs / waveSecs) * w);
        var holdinHorz = Math.round((holdinSecs / waveSecs) * w);
        var exhaleHorz = Math.round((exhaleSecs / waveSecs) * w);
        var holdoutHorz = Math.round((holdoutSecs / waveSecs) * w);

        x[0] = 0;
        x[1] = x[0] + inhaleHorz;
        x[2] = x[1] + holdinHorz;
        x[3] = x[2] + exhaleHorz;
        x[4] = x[3] + holdoutHorz;
        x[5] = x[4] + inhaleHorz;
        x[6] = x[5] + holdinHorz;
        x[7] = x[6] + exhaleHorz;
        x[8] = x[7] + holdoutHorz;

        y[0] = y[3] = y[4] = y[7] = y[8] = hMax;
        y[1] = y[2] = y[5] = y[6] = hMin;
    }

    function showCurrentWave(initial = false) {
        theCanvas = document.getElementById("canvasOne");
        c = theCanvas.getContext("2d");

        if (!theCanvas || (!initial && stop)) return false;

        var leftX;
        var rightX;

        setWaveParams();

        c.fillStyle = "white";
        c.fillRect(0, hMin - LINE_WIDTH / 2, w, hMax - hMin + LINE_WIDTH); // remove any previous wave
        c.fillRect(FOCUS_LEFT, hMin - FOCUS_RADIUS - FOCUS_LINE / 2, FOCUS_DIAM, FOCUS_DIAM); // remove the previous focus circle
        c.fillRect(FOCUS_LEFT, hMax - FOCUS_RADIUS - FOCUS_LINE / 2, FOCUS_DIAM, FOCUS_DIAM); // remove the previous focus circle

        var phaseShift = Date.now() - phaseStart; // get our time shift
        if (phaseShift >= waveMsecs) {
            // have we wrapped out of our wave period?
            setPhaseStart((currentPhaseStart) => currentPhaseStart + waveMsecs); // yes, so bump our reference up by the wave's period
            phaseShift -= waveMsecs; // and drop our shift back into range
        }
        var pixelShift = Math.round(pixelsPerMsec * phaseShift);

        c.strokeStyle = "#00bcd4";
        c.lineWidth = LINE_WIDTH;
        c.lineJoin = "round";
        c.beginPath();
        c.moveTo((leftX = x[0] - pixelShift), y[0]);

        for (var j = 1; j < POINTS; j++) {
            c.lineTo((rightX = x[j] - pixelShift), y[j]);
            if (leftX <= middle && rightX >= middle)
                focus = ((y[j] - y[j - 1]) * (middle - leftX)) / (rightX - leftX) + y[j - 1];
            leftX = rightX;
        }
        c.stroke();

        if (prevFocus && prevFocus > focus) {
            c.strokeStyle = "#00914d";
            if (audioExhale) {
                audioExhale.pause();
                audioExhale.currentTime = 0;
                audioStarted = false;
            }
            if (audioInhale && !audioStarted) {
                audioInhale.play();
                audioStarted = true;
            }
        } else if (prevFocus && prevFocus < focus) {
            c.strokeStyle = "#ffd500";
            if (audioInhale) {
                audioInhale.pause();
                audioInhale.currentTime = 0;
                audioStarted = false;
            }
            if (audioExhale && !audioStarted) {
                audioExhale.play();
                audioStarted = true;
            }
        } else if (prevFocus && prevFocus == focus) {
            c.strokeStyle = "#ac3898";
            if (audioInhale) {
                audioInhale.pause();
                audioInhale.currentTime = 0;
            }
            if (audioExhale) {
                audioExhale.pause();
                audioExhale.currentTime = 0;
            }
            audioStarted = false;
        } else {
            c.strokeStyle = "#00bcd4";
            if (audioInhale) {
                audioInhale.pause();
                audioInhale.currentTime = 0;
            }
            if (audioExhale) {
                audioExhale.pause();
                audioExhale.currentTime = 0;
            }
            audioStarted = false;
        }
        prevFocus = focus;

        c.beginPath();
        c.arc(middle, focus, FOCUS_RADIUS, 0, TWO_PI, false);
        c.fillStyle = "white";
        c.fill();
        c.lineWidth = FOCUS_LINE;

        c.stroke();
        c.beginPath();
        c.arc(middle, focus + 9, FOCUS_RADIUS / 1.3, 0, TWO_PI, false);
        c.stroke();
        c.beginPath();
        c.arc(middle, focus + 18, FOCUS_RADIUS / 2, 0, TWO_PI, false);
        c.stroke();
        c.beginPath();
        c.arc(middle, focus + 27, FOCUS_RADIUS / 3.5, 0, TWO_PI, false);
        c.stroke();
    }

    function toggleHideBreather() {
        setHideBreather(!hideBreather);
    }

    return (
        <div className="breatherModule">
            <Timer
                pausePlayBreather={pausePlayBreather}
                HRVConnected={HRVConnected}
                toggleHideBreather={toggleHideBreather}
                bpm={HRVbpm}
                amp={HRVamp}
                setBreateherPause={setBreateherPause}
            />
            <canvas id="canvasOne" className={hideBreather ? "hideBreather" : null} />
            {breather.hrv && !HRVConnected && !isBreatherPause && (
                <div style={{ position: "relative", top: "-150px", textAlign: "center" }}>
                    <Button className="bluetoothButton" variant="contained" color="primary" onClick={connectHRVDevice}>
                        <Bluetooth /> Connect Biofeedback Device
                    </Button>
                </div>
            )}

            {updateConfig && !hideBreather && (
                <BreatherControls
                    breather={breather}
                    updateConfig={updateConfig}
                    audioInhale={audioInhale}
                    audioExhale={audioExhale}
                    handleInhaleSound={handleInhaleSound}
                    handleExhaleSound={handleExhaleSound}
                />
            )}
        </div>
    );
};

function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

function mapStateToProps(state) {
    return {
        resonator: state.resonatorCreation.resonator,
        editMode: state.resonatorCreation.editMode,
        formData: state.resonatorCreation.formData,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateCreationStep: actions.updateCreationStep,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(BreatherApp);
