import _ from "lodash";
import SagaReducerFactory from "../saga-reducers-factory-patch";
import { put, call, select, delay } from "redux-saga/effects";
import { actions, types } from "../actions/sessionActions";
import formErrorAction from "../actions/formError";
import * as sessionApi from "../api/session";
import { actions as navigationActions } from "../actions/navigationActions";
import { unsubscribeFromPushNotifications } from "../push";
import { isSafari } from "../utils";
import { actions as notificationActions } from "../actions/notificationsActions";

let { handle, updateState, saga, reducer } = SagaReducerFactory({
    actionTypes: types,
    actionCreators: actions,
    initState: {
        user: null,
        loggedIn: false,
    },
});

handle(types.RESUME, function* () {
    try {
        const user = yield call(sessionApi.get);
        const loggedIn = yield updateUser(user);
        const currentPath = location.pathname;
        const excludedRedirect = [
            // Need this to keep the query params (Google auth errors)
            "/login",
            "/loginLeader",
            "/registration",
        ];

        const checkedPath = currentPath.includes("/registration/") ? "/registration" : currentPath;

        if (!loggedIn && !excludedRedirect.includes(checkedPath))
            yield put(navigationActions.navigate(currentPath === "/followers" ? "loginLeader" : "login"));
    } catch (err) {
        console.log("resuming session failed", err);
        yield put(navigationActions.navigate("login"));
    }
});

export function* handleLoginResponse(user) {
    let { auth_token } = user;
    // avoid setting auth_token to "undefined". This leads to lockout
    if (!auth_token) {
        console.log("Failed. Login response is missing authentication token");
        return;
    }
    saveAuthToken(auth_token);
    user = _.omit(user, "auth_token");
    let loggedIn = yield updateUser(user);
    if (!loggedIn) yield put(formErrorAction("login"));
}

handle(types.LOGIN, function* (sagaParams, action) {
    let { email, password, isLeader } = action.payload;

    let user;

    try {
        user = yield call(sessionApi.create, email, password, isLeader);
    } catch (err) {
        console.warn("login failed", err);
    }
    yield handleLoginResponse(user);
});

handle(types.LOGOUT, function* () {
    try {
        let user = yield call(sessionApi.get);
        yield call(unsubscribeFromPushNotifications);
        yield call(sessionApi.logout);
        yield put(
            updateState({
                loggedIn: false,
            })
        );
        yield put(navigationActions.navigate(user.isLeader ? "logoutLeader" : "logout"));
    } catch (err) {
        console.warn("logout failed", err);
    }
});

handle(types.REGISTER, function* (sagaParams, { payload }) {
    try {
        yield put(updateState({ registrationFailed: false }));
        let user = yield call(sessionApi.register, payload.email, payload.name, payload.password, payload.isLeader);
        yield updateUser(user);
        yield put(navigationActions.hideModal());
    } catch (err) {
        console.error("registration failed", err);
        yield put(updateState({ registrationFailed: true }));
    }
});

handle(types.GOOGLE_LOGIN, function* (sagaParams, { payload }) {
    try {
        const { url } = yield call(sessionApi.startGoogleLogin, payload.isLeader);
        location.href = url;
    } catch (err) {
        console.error("google login failed", err);
    }
});

handle(types.RECOVER_PASSWORD, function* (sagaParams, { payload }) {
    try {
        yield put(updateState({ forgotPasswordSpinner: true, forgotPasswordFailed: false }));
        yield call(sessionApi.recoverPassword, payload.email);
        yield put(navigationActions.hideModal());
        yield put(
            navigationActions.showModal({
                name: "forgotPasswordSuccess",
            })
        );
        yield put(updateState({ forgotPasswordSpinner: false }));
    } catch (err) {
        console.error("password recovery failed", err);
        yield put(updateState({ forgotPasswordSpinner: false, forgotPasswordFailed: true }));
    }
});

handle(types.RESET_PASSWORD, function* (sagaParams, { payload }) {
    const spin = (active) => put(updateState({ resetPasswordSpinner: active }));

    try {
        yield spin(true);

        const token = yield select((state) => state.init.query.token);

        yield sessionApi.resetPassword({
            password: payload,
            token,
        });

        yield put(
            updateState({
                resetPasswordSuccessful: true,
            })
        );
        yield delay(3500);
        yield spin(false);
        yield put(navigationActions.navigate("login"));
    } catch (err) {
        console.error("password reset failed", err);
        yield spin(false);
        yield put(
            updateState({
                resetPasswordSuccessful: false,
            })
        );
    }
});

handle(types.UPDATE_USER_STATE, function* (sagaParams, { payload }) {
    if (!payload) return false;
    const user = yield select((state) => state.session.user);
    yield put(
        updateState({
            user: { ...user, ...payload },
        })
    );
});

function* updateUser(user = {}) {
    const loggedIn = new Date(user.expires_at) > new Date();

    yield put(
        updateState({
            user,
            loggedIn,
        })
    );

    if (loggedIn) {
        yield put(actions.loginSuccess());
        const currentPath = location.pathname;
        const ua = window.navigator.userAgent;
        if (!isSafari(ua)) {
            yield put(notificationActions.subscribe());
        }

        if (
            // Standalone App or Leader login should lead to /followers when possible, otherwise resonators
            currentPath === "/loginLeader" ||
            ((currentPath === "/login" || currentPath.includes("/registration")) &&
                (window.matchMedia("(display-mode: standalone)").matches ||
                    window.navigator.standalone ||
                    document.referrer.includes("android-app://")))
        ) {
            yield put(navigationActions.navigate(user.isLeader ? "followers" : "follower/resonators"));
        } else if (currentPath === "/" || currentPath === "/login" || currentPath.includes("/registration")) {
            // regular Login should always lead to resonators
            yield put(navigationActions.navigate("follower/resonators"));
        }
    }

    return loggedIn;
}

function saveAuthToken(auth_token) {
    localStorage.setItem("auth_token", auth_token);
}

export default { saga, reducer };
