import { put } from "redux-saga/effects";
import { actions, types } from "../actions/notificationsActions";
import SagaReducerFactory from "../saga-reducers-factory-patch";
import { isSafari, isIosMinimumVersion, isStandalone, isIphone } from "../utils";
import { actions as navigationActions } from "../actions/navigationActions";
import { types as sessionActionTypes } from "../actions/sessionActions";
import { actions as developerActions } from "../actions/DeveloperPageActions";
import * as api from "../api/pushSubscription";
import { pushServerKey } from "../config/push";

let { handle, updateState, saga, reducer } = SagaReducerFactory({
    actionTypes: types,
    actionCreators: actions,
    initState: {
        notificationPermissionSnoozed: 0,
        iPhoneNotificationsEnabled: false,
    },
});

async function isTimeToAskForNotificationPermissions() {
    const lastSnoozed = localStorage.getItem("notificationsLastSnoozed");
    const itsTimeToAsk = lastSnoozed
        ? Math.floor((Date.now() - Date.parse(lastSnoozed)) / (1000 * 60 * 60 * 24)) >= 30 // at least 30 days have passed since last time we asked
        : true; // we never asked

    return itsTimeToAsk;
}

handle(sessionActionTypes.LOGIN_SUCCESS, function* () {
    const ua = window.navigator.userAgent;
    if (!isIphone(ua) || !isSafari(ua) || !isStandalone() || !isIosMinimumVersion(ua, 16.4)) return;

    const registration = yield navigator.serviceWorker.ready;
    const subscription = yield registration?.pushManager?.getSubscription();
    const iPhoneNotificationsEnabled = subscription !== null;
    if (iPhoneNotificationsEnabled) {
        yield put(updateState({ iPhoneNotificationsEnabled: true }));
    } else if (isTimeToAskForNotificationPermissions()) {
        yield put(
            navigationActions.showModal({
                name: "NotificationPermissionRequestModal",
            })
        );
    }
});

handle(types.SNOOZE_NOTIFICATIONS, function* () {
    const now = new Date();
    localStorage.setItem("notificationsLastSnoozed", now.toString());
});

handle(types.SUBSCRIBE, function* () {
    yield put(developerActions.test({ testName: "addlog", logString: "In SUBSCRIBE" }));
    const registration = yield navigator?.serviceWorker?.ready;

    if (!registration.pushManager) return;
    try {
        const subscription = yield registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: pushServerKey,
        });
        yield api.saveSubsctiption(subscription);
        const ua = window.navigator.userAgent;
        if (isIphone(ua)) {
            yield put(updateState({ iPhoneNotificationsEnabled: true }));
            yield put(
                developerActions.test({ testName: "addlog", logString: "Setting iPhoneNotificationsEnabled to True" })
            );
        }
    } catch (error) {
        console.error(error);
    }
});

async function unsubscribeFromPushNotifications() {
    const registration = await navigator.serviceWorker.ready;
    const subscription = await registration?.pushManager?.getSubscription();

    if (subscription) {
        subscription
            .unsubscribe()
            .then(() => api.deleteSubscription(subscription))
            .catch(console.error);
    }
}

export default { saga, reducer };
