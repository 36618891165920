import fetcher from "./fetcher";

// returns
// {user name, user email, leader name}
// or ERROR
export function get(uuid) {
    return fetcher(`/registration/details/${uuid}`);
}

// returns session cookie and OK
// or ERROR
export function registerUserUsingCode(uuid, code, newPassword) {
    return fetcher.post("/registration.json", {
        uuid,
        code,
        newPassword,
    });
}

export function regenerateCode(regid) {
    return fetcher.post(`/registration/${regid}/regenerate.json`);
}

export function sendCodeByEmail(regid) {
    return fetcher.post(`/registration/${regid}/emailcode.json`);
}
