import React from "react";

const BreatherIcon = ({ action }) => {
    return (
        <svg height="24" width="24">
            <polyline fill="none" stroke="black" strokeWidth="2" points="1,14 3,21 9,21 15,3 21,3 23,10" />
            <circle cx="12" cy="12" r="4" stroke="black" fill="black" />
            {action === "hide" && <line x1="18" y1="18" x2="24" y2="24" stroke="#00bcd4" />}
            {action === "hide" && <line x1="24" y1="18" x2="18" y2="24" stroke="#00bcd4" />}
            {action === "show" && <line x1="18" y1="21" x2="24" y2="21" stroke="#00bcd4" />}
            {action === "show" && <line x1="21" y1="18" x2="21" y2="24" stroke="#00bcd4" />}
        </svg>
    );
};

export default BreatherIcon;
