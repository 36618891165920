import React, { useState } from "react";
import * as utils from "components/utils";
import { Divider, Typography, IconButton, Button, Grid } from "@material-ui/core";
import ExpandableCard from "components/ExpandableCard";
import { Event, Timer, GetApp as Download } from "@material-ui/icons";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import moment from "moment";
import FullscreenIcon from "components/Icons/fullscreen.png";
import "./ResonatorHRVReadings.css";
import FullscreenExitIcon from "components/Icons/shrink.png";
import AmplitudeIcon from "./apps/AmplitudeIcon";

function ResonatorHRVReadings({ readings }) {
    var canvasRefs = [];
    const [fullscreenMode, setFullscreenMode] = useState(false);

    const getYaxisScaleMarks = (minbpm, maxbpm) => {
        const roundedMin = 5 * Math.ceil((minbpm + 1) / 5);
        let result = [];
        for (let i = roundedMin; i < maxbpm; i += 5) {
            result.push(i);
        }
        return result;
    };

    const getXaxisScaleMarks = (duration) => {
        // approximately 4 points. Seconds should end with 5 or 0
        const deltaX = 5 * Math.round(duration / 25);
        let result = [];

        for (let mark = deltaX; mark < duration; mark += deltaX) {
            result.push(mark);
        }
        return result;
    };
    const drawCanvas = (id) => {
        const canvas = canvasRefs[id];
        const c = canvas.getContext("2d");
        const reading = readings?.find((r) => r.id === id);
        const xAxisMargin = 10;
        const yAxisMargin = 20;
        const graphMargin = 5;

        const calcY = (height, minBpm, maxBpm, bpm) => {
            return (
                height -
                xAxisMargin -
                graphMargin -
                ((bpm - minBpm) * (height - 2 * graphMargin - xAxisMargin)) / (maxBpm - minBpm)
            );
        };
        if (canvas && c && reading && reading.data.duration) {
            const w = (canvas.width = canvas.parentNode.offsetWidth);
            const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            canvas.height = Math.round(h / 2 + (40 * 2 + 5) * 2);

            c.strokeStyle = "#c82f70";
            c.lineJoin = "round";
            c.lineWidth = 2;
            c.beginPath();
            let newX = 0;
            const minBPM = Math.min(...reading.data.bpms);
            const maxBPM = Math.max(...reading.data.bpms);
            reading.data.bpms.forEach((bpm, i) => {
                const interval = 60 / bpm;
                if (i === 0) {
                    newX = yAxisMargin + graphMargin;
                } else {
                    newX = newX + ((w - yAxisMargin - 2 * graphMargin) * interval) / reading.data.duration;
                }
                const newY = calcY(canvas.height, minBPM, maxBPM, bpm);
                c.lineTo(newX, newY);
            });

            c.stroke();

            // Axes
            c.beginPath();
            c.strokeStyle = "black";
            c.moveTo(yAxisMargin, 0);
            c.lineTo(yAxisMargin, canvas.height - xAxisMargin);
            c.lineTo(w, canvas.height - xAxisMargin);
            c.stroke();
            c.font = "15px Arial";
            const yAxisMarks = getYaxisScaleMarks(minBPM, maxBPM);
            yAxisMarks.forEach((bpm) => {
                c.fillText(String(bpm), yAxisMargin + 5, calcY(canvas.height, minBPM, maxBPM, bpm));
            });
            const xAxisMarks = getXaxisScaleMarks(reading.data.duration);
            const xMarksYvalue = calcY(canvas.height, minBPM, maxBPM, minBPM);
            xAxisMarks.forEach((t) =>
                c.fillText(
                    t < 60 ? String(t) : formatDuration(t),
                    yAxisMargin + graphMargin + ((w - yAxisMargin - 2 * graphMargin) / reading.data.duration) * t,
                    xMarksYvalue
                )
            );
            c.fillText("Time", w - 60, xMarksYvalue);
        }
    };

    const downloadCSV = (id) => {
        const record = readings?.find((r) => r.id === id);
        const reading = record.data;
        if (!reading) return false;
        //const replacer = (key, value) => (value === null ? "" : value);
        //const header = Object.keys(reading);
        const csv = [
            ["Duration", "Maximal Amplitute", "BPMs"],
            [reading.duration, reading.maxAmp, reading.bpms[0]],
            ...reading.bpms.slice(1).map((bpm) => {
                return ["", "", String(bpm)];
            }),
        ].join("\r\n");

        const blob = new Blob([csv], { type: "text/csv" });
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.setAttribute("download", "hrv_" + moment(reading.createdAt).format("D_M_YY") + ".csv");
        a.click();
    };
    // assuming HRV is not more than 1 hour
    const formatDuration = (seconds) => {
        const toTwoDigits = (n) => {
            if (n === 0) return "00";
            if (n < 10) return "0" + String(n);
            return String(n);
        };
        return toTwoDigits(Math.floor(seconds / 60)) + ":" + toTwoDigits(Math.round(seconds) % 60);
    };

    return (
        <>
            <Typography
                variant="h5"
                noWrap
                style={{ margin: "50px 10px 20px 10px", direction: utils.getDir("HRV Readings") }}
            >
                HRV Readings
            </Typography>
            <Divider style={{ margin: 10 }} />
            {readings?.map((r) => {
                const fullScreenHandle = useFullScreenHandle();

                return (
                    <ExpandableCard
                        key={r.id}
                        onExpandChange={(expanded) => expanded && drawCanvas(r.id)}
                        title={
                            <Grid container direction="row" justifyContent="space-around" alignItems="center">
                                <Grid item>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Event style={{ marginRight: "4px" }} />{" "}
                                        {moment(r.createdAt).format("MMM Do [(]HH:mm[)]")}
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Timer style={{ marginRight: "4px" }} /> {formatDuration(r.data.duration)}
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <AmplitudeIcon style={{ marginRight: "6px" }} />
                                        {r.data.maxAmp}
                                    </div>
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={() => downloadCSV(r.id)}>
                                        <Download />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        }
                        style={{ width: "100%", position: "relative" }}
                    >
                        <FullScreen
                            handle={fullScreenHandle}
                            onChange={(isFullscreen) => {
                                setFullscreenMode(isFullscreen);
                                drawCanvas(r.id);
                            }}
                        >
                            <canvas ref={(canvas) => (canvasRefs[r.id] = canvas)} />
                            <div className="HRVReadingCanvasYaxis" />
                            {fullscreenMode && (
                                <Button className="closeFullscreen" onClick={fullScreenHandle.exit}>
                                    <img src={FullscreenExitIcon} />
                                </Button>
                            )}
                        </FullScreen>
                        <Button className="fullscreenIcon_readings" onClick={fullScreenHandle.enter}>
                            <img src={FullscreenIcon} />
                        </Button>
                    </ExpandableCard>
                );
            })}
        </>
    );
}

export default ResonatorHRVReadings;
