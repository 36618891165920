import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { actions } from "../actions/DeveloperPageActions";
import { bindActionCreators } from "redux";

class DeveloperPage extends Component {
    constructor(props) {
        super(props);
    }

    renderLog = () => {
        return (
            <p>
                {this.props.devlog.map((element, index) => (
                    <span key={index}>
                        <span>
                            {element.date}: {element.text}
                        </span>
                        <br />
                    </span>
                ))}
            </p>
        );
    };

    render() {
        return (
            <div>
                <Button
                    type="button"
                    variant="outlined"
                    onClick={() => {
                        this.props.test({ testName: "getSubscription" });
                    }}
                >
                    Get Subscription
                </Button>
                <Button
                    type="button"
                    variant="outlined"
                    onClick={() => {
                        this.props.test({ testName: "getIphoneNotificationsEnabled" });
                    }}
                >
                    Get iPhone Notifications Enabled
                </Button>
                <div>{this.renderLog()}</div>
            </div>
        );
    }
}

export default connect(
    (state) => ({ devlog: state.developer.devlog }),
    (dispatch) =>
        bindActionCreators(
            {
                test: actions.test,
            },
            dispatch
        )
)(DeveloperPage);
