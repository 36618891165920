import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import { Button, Typography } from "@material-ui/core";

import "./HRV.scss";
import { bindActionCreators } from "redux";
import { actions } from "actions/resonatorCreationActions";
import Breather from "./Breather";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {
    Bluetooth,
    BluetoothConnected,
    BluetoothDisabled,
    VisibilityOff as HideIcon,
    Visibility as ShowIcon,
    RecordVoiceOver as BPOn,
    VoiceOverOff as BPOff,
} from "@material-ui/icons";
import FullscreenIcon from "../Icons/fullscreen.png";
import FullscreenExitIcon from "../Icons/shrink.png";

import * as hrvAPI from "../../api/hrv";

const HRVApp = function HRV({ resonatorId, breather, updateConfig }) {
    const t = useTranslation();
    const secondsPerFrame = 120;
    var theCanvas, mainCanvas, c, h, w;
    const [connected, setConnected] = useState(false);
    const [fullscreenMode, setFullscreenMode] = useState(false);
    const [hideBreather, setHideBreather] = useState(false);
    const fullScreenHandle = useFullScreenHandle();
    const [stop, setStop] = useState(true);
    const [BPMPeaks, setBPMPeaks] = useState([]);
    const [BPMLows, setBPMLows] = useState([]);
    const [HRVDataPoints, setHRVDataPoints] = useState({ heartRates: [], frameStart: -1 });
    const [maxAmp, setMaxAmp] = useState(0);

    const getMaxBPM = () => {
        if (!BPMPeaks || BPMPeaks.length < 3) return 90;
        const maxClientBPM = Math.max(...BPMPeaks);
        if (maxClientBPM + 5 < 90) return 90;
        if (maxClientBPM + 5 < 110) return 110;
        if (maxClientBPM + 5 < 130) return 130;
        return 150;
    };

    useEffect(() => {
        return () => disconnectDevice();
    }, []);

    useEffect(() => {
        if (stop || !HRVDataPoints || HRVDataPoints.heartRates.length === 0) return;
        const amp = getAMP();
        if (amp && amp > maxAmp) setMaxAmp(amp);
        const maxBPM = getMaxBPM();
        const minBPM = maxBPM - 50;
        mainCanvas = document.getElementById("canvasOne");
        theCanvas = document.getElementById("HRVCanvas");
        if (!theCanvas) return;
        c = theCanvas.getContext("2d"); // canvas context
        w = theCanvas.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        theCanvas.height = Math.round(h / 2 + (40 * 2 + 5) * 2);

        const hrLength = HRVDataPoints.heartRates.length;
        const extremumWindow = Math.min(60, hrLength);
        const sortedHeartBeats = HRVDataPoints.heartRates
            .slice(-extremumWindow)
            .sort((a, b) => b.heartRate - a.heartRate);
        if (hrLength > 10) {
            setBPMPeaks(() => {
                return sortedHeartBeats.slice(0, 4).map((entry, i) => entry.heartRate);
            });
            setBPMLows(() => {
                return sortedHeartBeats.slice(-5).map((entry, i) => entry.heartRate);
            });
        }

        const calcCanvasY = (y) => {
            return theCanvas.height - ((y - minBPM) * theCanvas.height) / (maxBPM - minBPM);
        };

        // Y axis
        c.font = "30px Arial";
        c.fillText(String(maxBPM - 10), 0, calcCanvasY(maxBPM - 10));
        c.fillText(String(maxBPM - 20), 0, calcCanvasY(maxBPM - 20));
        c.fillText(String(maxBPM - 30), 0, calcCanvasY(maxBPM - 30));
        c.fillText(String(maxBPM - 40), 0, calcCanvasY(maxBPM - 40));

        const getSecondsFormat = (secs) => {
            const min = Math.floor(secs / 60);
            const sec = secs % 60;
            const secStr = sec < 10 ? "0" + sec.toString() : sec.toString();
            if (min > 0) return min.toString() + ":" + secStr;
            return sec.toString();
        };
        // X axis
        const startFrom = HRVDataPoints.heartRates[HRVDataPoints.frameStart].seconds
            ? Math.floor(HRVDataPoints.heartRates[HRVDataPoints.frameStart].seconds / secondsPerFrame) * secondsPerFrame
            : 0;

        for (let secondsThisFrame = 10; secondsThisFrame < secondsPerFrame; secondsThisFrame += 10) {
            const t = secondsThisFrame + startFrom;
            c.fillText(getSecondsFormat(t), (secondsThisFrame * theCanvas.width) / secondsPerFrame, theCanvas.height);
        }
        // Heart
        const sampleLength = HRVDataPoints.heartRates.length;
        if (sampleLength > 1) {
            c.clearRect(theCanvas.width / 2, calcCanvasY(HRVDataPoints.heartRates[sampleLength - 2].heartRate), 50, 50);
        }
        c.strokeStyle = "#c82f70";
        c.lineJoin = "round";
        c.lineWidth = 5;
        c.beginPath();

        // *** BPM
        for (let ndx = HRVDataPoints.frameStart; ndx < HRVDataPoints.heartRates.length; ndx++) {
            const newX =
                (theCanvas.width / secondsPerFrame) * (HRVDataPoints.heartRates[ndx].seconds % secondsPerFrame);
            const newY = calcCanvasY(HRVDataPoints.heartRates[ndx].heartRate);
            c.lineTo(newX, newY);
        }
        c.stroke();

        const peakBPM = BPMPeaks.reduce((sum, num) => sum + num, 0) / BPMPeaks.length;
        let lowBPM = BPMLows.reduce((sum, num) => sum + num, 0) / BPMLows.length;
        c.beginPath();
        c.strokeStyle = "#d3d3d3";
        c.setLineDash([2, 2]);
        const topBPMCanvas = calcCanvasY(peakBPM);
        c.moveTo(10, topBPMCanvas);
        c.lineTo(theCanvas.width - 10, topBPMCanvas);
        c.stroke();

        c.beginPath();
        c.setLineDash([2, 2]);
        const bottomBPMCanvas = calcCanvasY(lowBPM);
        c.moveTo(10, bottomBPMCanvas);
        c.lineTo(theCanvas.width - 10, bottomBPMCanvas);
        c.stroke();
    }, [HRVDataPoints]);

    const connectDevice = () => {
        navigator.bluetooth
            .requestDevice({
                filters: [{ services: ["heart_rate"] }],
            })
            .then((device) => {
                setConnected(device);
                device.addEventListener("gattserverdisconnected", () => setConnected(false));
                return device.gatt.connect();
            })
            .then(async (server) => {
                const HRService = await server.getPrimaryService("heart_rate");
                const HRCharacteristic = await HRService.getCharacteristic("heart_rate_measurement");
                return HRCharacteristic.startNotifications().then((_) => {
                    HRCharacteristic.addEventListener("characteristicvaluechanged", handleNotifications);
                });
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const disconnectDevice = () => {
        if (!connected) return false;
        connected.gatt.disconnect();
        setConnected(false);
        setHRVDataPoints({ heartRates: [], frameStart: -1 });
    };

    async function handleNotifications(event) {
        const value = event.target.value;
        const parsedValue = parseHeartRate(value);
        if (parsedValue) {
            let isStop = false;
            // get the correct value of stop
            setStop((currentStop) => {
                isStop = currentStop;
                return currentStop;
            });
            if (!isStop) {
                setHRVDataPoints((currentPoints) => {
                    //if (!currentPoints) currentPoints = HRVDataPoints;
                    // HRVDataPoints stracture: {frameStart: number, heartRates:[{heartRage:number, seconds:number}]}
                    let frameStart;
                    let heartRates = [];
                    const timestamp = Date.now();
                    if (currentPoints.frameStart === -1) {
                        frameStart = 0;
                        heartRates[0] = { heartRate: parsedValue.heartRate, seconds: 0, timestamp };
                    } else {
                        const hrLength = currentPoints.heartRates.length;
                        // const nextSeconds = currentPoints.heartRates[hrLength - 1].seconds + 60 / parsedValue.heartRate;
                        const nextSeconds = (timestamp - currentPoints.heartRates[0].timestamp) / 1000;
                        if (
                            currentPoints.heartRates[hrLength - 1].seconds % secondsPerFrame >
                            nextSeconds % secondsPerFrame
                        ) {
                            frameStart = hrLength;
                        } else {
                            frameStart = currentPoints.frameStart;
                        }
                        heartRates = currentPoints.heartRates.map((r) => ({
                            heartRate: r.heartRate,
                            seconds: r.seconds,
                            timestamp: r.timestamp,
                        }));
                        heartRates[hrLength] = {
                            heartRate: parsedValue.heartRate,
                            seconds: nextSeconds,
                            timestamp,
                        };
                    }
                    return { frameStart, heartRates };
                });
            }
        }
    }

    function parseHeartRate(value) {
        // In Chrome 50+, a DataView is returned instead of an ArrayBuffer.
        value = value.buffer ? value : new DataView(value);
        let flags = value.getUint8(0);
        let rate16Bits = flags & 0x1;
        let result = {};
        let index = 1;
        if (rate16Bits) {
            result.heartRate = value.getUint16(index, /*littleEndian=*/ true);
            index += 2;
        } else {
            result.heartRate = value.getUint8(index);
            index += 1;
        }
        let contactDetected = flags & 0x2;
        let contactSensorPresent = flags & 0x4;
        if (contactSensorPresent) {
            result.contactDetected = !!contactDetected;
        }
        let energyPresent = flags & 0x8;
        if (energyPresent) {
            result.energyExpended = value.getUint16(index, /*littleEndian=*/ true);
            index += 2;
        }
        let rrIntervalPresent = flags & 0x10;
        if (rrIntervalPresent) {
            let rrIntervals = [];
            for (; index + 1 < value.byteLength; index += 2) {
                rrIntervals.push(value.getUint16(index, /*littleEndian=*/ true));
            }
            result.rrIntervals = rrIntervals;
        }
        return result;
    }

    function handleStopBreather(shouldStop) {
        setStop(shouldStop);
        if (shouldStop) {
            saveHRV();
            setHRVDataPoints({ heartRates: [], frameStart: -1 });
        }
    }

    function saveHRV() {
        if (HRVDataPoints.frameStart > -1 && resonatorId) {
            const hrvRecord = {
                duration: HRVDataPoints.heartRates[HRVDataPoints.heartRates.length - 1].seconds,
                maxAmp: maxAmp,
                bpms: HRVDataPoints.heartRates.map((entry) => entry.heartRate),
            };
            hrvAPI.saveHRVData(resonatorId, hrvRecord);
        }
    }

    theCanvas = document.getElementById("HRVCanvas");

    const getAMP = () => {
        if (BPMLows && BPMPeaks && BPMLows.length > 0 && BPMPeaks.length > 0)
            return BPMPeaks[BPMPeaks.length - 1] - BPMLows[BPMLows.length - 1];
        return null;
    };

    return (
        <div className={fullscreenMode ? "HRVModule fullscreenHRV" : "HRVModule"}>
            <FullScreen handle={fullScreenHandle} onChange={(isFullscreen) => setFullscreenMode(isFullscreen)}>
                {fullscreenMode && (
                    <Button className="closeFullscreen" onClick={fullScreenHandle.exit}>
                        <img src={FullscreenExitIcon} />
                    </Button>
                )}
                <Breather
                    breather={breather}
                    updateConfig={updateConfig}
                    isFullscreen={fullscreenMode}
                    hideBreather={hideBreather}
                    stop={stop}
                    handleStopBreather={handleStopBreather}
                    HRVConnected={connected}
                    connectHRVDevice={connectDevice}
                    HRVbpm={
                        HRVDataPoints?.frameStart >= 0 &&
                        HRVDataPoints.heartRates[HRVDataPoints.heartRates.length - 1].heartRate
                    }
                    HRVamp={getAMP()}
                />
                <canvas id="HRVCanvas" />
                {connected && (
                    <>
                        <div className="HRVLegendX" style={{ top: theCanvas?.offsetHeight - 20 }} />
                        <div className="HRVLegendY" style={{ height: theCanvas?.offsetHeight - 70 }} />
                    </>
                )}
            </FullScreen>
            <Button className="fullscreenIcon" onClick={fullScreenHandle.enter}>
                <img src={FullscreenIcon} />
            </Button>
            {breather.hrv && false && (
                <>
                    {connected ? (
                        <>
                            <Typography variant="h6" color="primary" className="bluetoothConnected">
                                <BluetoothConnected /> Biofeedback device is connected
                            </Typography>
                            <Button
                                className="bluetoothButton"
                                variant="contained"
                                color="secondary"
                                onClick={disconnectDevice}
                            >
                                <BluetoothDisabled /> Disconnect Biofeedback Device
                            </Button>
                        </>
                    ) : (
                        <Button className="bluetoothButton" variant="contained" color="primary" onClick={connectDevice}>
                            <Bluetooth /> Connect Biofeedback Device
                        </Button>
                    )}
                </>
            )}
        </div>
    );
};

function mapStateToProps(state) {
    return {
        resonator: state.resonatorCreation.resonator,
        editMode: state.resonatorCreation.editMode,
        formData: state.resonatorCreation.formData,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateCreationStep: actions.updateCreationStep,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(HRVApp);
