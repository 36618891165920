import _ from "lodash";
import { actions as navigationActions } from "../actions/navigationActions";
import { actions } from "../actions/criteriaActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import React, { Component } from "react";
import EntityTable from "./EntityTable";
import { rowAction } from "./RowActions";
import { push } from "connected-react-router";
import { Typography, MenuItem } from "@material-ui/core";
import { PlayCircleFilled, PauseCircleFilled, Edit, ArrowUpward, ArrowDownward, FileCopy } from "@material-ui/icons";
import OverflowMenu from "./OverflowMenu";
import createSelector from "../selectors/criterionSelector";
import Filter from "./Filter";
import { withTranslation } from "react-multi-lang";

class CriteriaList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openedOverflowMenuFollowerId: null,
            filterText: "",
        };

        this.handleFreezeCriterion = this.handleFreezeCriterion.bind(this);
        this.handleSelectCriteria = this.handleSelectCriteria.bind(this);
    }

    handleFilter = (filterText) => {
        this.setState({ ...this.state, filterText: filterText.toLowerCase() });
    };

    handleFreezeCriterion(criterionId) {
        this.props.showFreezeCriterionPrompt(criterionId);
    }

    handleSelectCriteria(criterionId) {
        this.props.selectCriteria(criterionId);
    }

    toggleOverflowMenu(criterionId) {
        if (!criterionId && !this.state.openedOverflowMenuCriterionId) return; //prevent stack overflow

        this.setState({
            openedOverflowMenuCriterionId: criterionId,
        });
    }

    clone(criterionId) {
        const response = this.props.cloneCriterion(criterionId);
    }

    getRows() {
        const { t } = this.props;
        const ft = this.state.filterText;
        const criteria = this.props.criteria
            .filter(
                (criterion) =>
                    !this.props.tagsFilter?.length > 0 ||
                    criterion.tags?.split(";").some((item) => this.props.tagsFilter?.includes(item.trim())) ||
                    (this.props.tagsFilter?.includes("No Tags") && !criterion.tags?.length)
            )
            .filter(
                (criterion) =>
                    !this.props.typeFilter?.length > 0 ||
                    criterion.is_system === this.props.typeFilter.includes(t("criteria.system")) ||
                    criterion.is_system !== this.props.typeFilter.includes(t("criteria.regular"))
            )
            .filter(
                (criterion) =>
                    !ft ||
                    (criterion.is_system && String("public").includes(ft)) ||
                    criterion.contributor?.toLowerCase().includes(ft) ||
                    criterion.description?.toLowerCase().includes(ft) ||
                    criterion.question_kind.toLowerCase().includes(ft) ||
                    criterion.title?.toLowerCase().includes(ft) ||
                    (criterion.tags && JSON.stringify(criterion.tags).toLowerCase().includes(ft))
            );
        !this.props.alphabetSort ? criteria.reverse() : criteria.sort();

        return _.reduce(
            criteria,
            (acc, c) => {
                let cols = [];
                cols.push(
                    <div>
                        <Typography
                            style={{
                                color: c.removed ? "rgb(157, 155, 155)" : "",
                                fontWeight: c.removed ? "" : "bold",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {c.removed ? <PauseCircleFilled fontSize="small" style={{ marginRight: 5 }} /> : null}

                            <span>{c.title}</span>
                        </Typography>

                        <Typography style={{ marginLeft: c.removed ? 25 : null }} color="textSecondary">
                            {c.description}
                        </Typography>
                        {c.contributor && (
                            <Typography color="textSecondary" style={{ whiteSpace: "nowrap" }}>
                                Contributor: {c.contributor}
                            </Typography>
                        )}
                    </div>
                );
                cols.push(
                    <p style={{ textAlign: "center" }}>{c.is_system ? t("criteria.system") : t("criteria.regular")}</p>
                );
                cols.push(
                    <div style={{ textAlign: "center" }}>
                        {c.tags?.split(";").map((tag) => {
                            if (!tag.trim()) return false;
                            return (
                                <span
                                    className={
                                        this.props.tagsFilter?.includes(tag.trim())
                                            ? "criterionTag active"
                                            : "criterionTag"
                                    }
                                    onClick={() => this.props.toggleFilterTags(tag.trim())}
                                >
                                    {tag};
                                </span>
                            );
                        })}
                    </div>
                );

                acc[c.id] = cols;
                return acc;
            },
            {}
        );
    }

    getToolbox() {
        const { t } = this.props;

        return {
            left: <Typography variant="h6">{t("criteria.criteria")}</Typography>,
            right: (
                <OverflowMenu keepOpen>
                    <MenuItem onClick={() => this.props.toggleDisplayFrozen()}>
                        {this.props.displayFrozen ? t("criteria.hide_deactivated") : t("criteria.show_deactivated")}
                    </MenuItem>
                </OverflowMenu>
            ),
        };
    }

    renderOverflowMenu() {
        const { t } = this.props;

        return (criterionId) => {
            const criteria = this.props.getCriteria(criterionId);

            return (
                <OverflowMenu key="more-options">
                    {criteria.removed ? (
                        <MenuItem onClick={() => this.props.unfreezeCriterion(criterionId)}>
                            {t("criteria.activate")}
                        </MenuItem>
                    ) : (
                        <MenuItem onClick={() => this.handleFreezeCriterion(criterionId)}>
                            {t("criteria.deactivate")}
                        </MenuItem>
                    )}
                </OverflowMenu>
            );
        };
    }

    getExtraRowActions() {
        const { t } = this.props;

        return [
            rowAction({
                title: t("criteria.copy"),
                icon: <FileCopy />,
                onClick: (criterionId) => this.clone(criterionId),
            }),
            rowAction({
                title: t("criteria.activate"),
                icon: <PlayCircleFilled />,
                onClick: this.props.unfreezeCriterion,
                isAvailable: (criterionId) =>
                    (!this.props.getCriteria(criterionId)?.is_system || this.props.isAdmin) &&
                    this.props.getCriteria(criterionId).removed,
            }),
            rowAction({
                title: t("criteria.deactivate"),
                icon: <PauseCircleFilled />,
                onClick: this.handleFreezeCriterion,
                isAvailable: (criterionId) =>
                    (!this.props.getCriteria(criterionId)?.is_system || this.props.isAdmin) &&
                    !this.props.getCriteria(criterionId).removed,
            }),
        ];
    }

    render() {
        const { t } = this.props;
        const getEditRoute = (id) => `${location.pathname}/${id}/edit`;

        return (
            <EntityTable
                addButton={true}
                addText={t("criteria.add_criterion")}
                rows={this.getRows()}
                header={[
                    <div className="link" onClick={this.props.toggleAlphabetSort.bind(this)}>
                        {t("criteria.criteria")} {this.props.alphabetSort ? <ArrowUpward /> : <ArrowDownward />}
                    </div>,
                    <Filter
                        name={t("criteria.type")}
                        list={[t("criteria.system"), t("criteria.regular")]}
                        checkedList={this.props.typeFilter || []}
                        toggleItem={this.props.toggleFilterType.bind(this)}
                        toggleAllItems={this.props.toggleAllFilterTypes.bind(this)}
                    />,
                    this.props.tags.length > 0 ? (
                        <Filter
                            name={t("criteria.tags")}
                            list={[t("criteria.no_tags"), ...this.props.tags]}
                            checkedList={this.props.tagsFilter || []}
                            toggleItem={this.props.toggleFilterTags.bind(this)}
                            toggleAllItems={this.props.toggleAllFilterTags.bind(this)}
                        />
                    ) : (
                        t("criteria.tags")
                    ),
                ]}
                rowActions={[
                    rowAction({
                        title: "Edit",
                        icon: <Edit />,
                        onClick: (criterionId) => this.props.push(getEditRoute(criterionId)),
                        isAvailable: (criterionId) =>
                            !this.props.getCriteria(criterionId)?.is_system || this.props.isAdmin,
                    }),
                ]}
                toolbox={this.getToolbox()}
                extraRowActions={this.getExtraRowActions()}
                onAdd={() => this.props.push(location.pathname + "/new")}
                className="Criteria"
                filterText={this.state.filterText}
                onFilter={this.handleFilter}
            />
        );
    }
}

function mapStateToProps(state) {
    let criteriaData = createSelector(state);
    const isAdmin = state.leaders.leaders.admin_permissions;
    const tags = _.reduce(
        criteriaData.criteria,
        (acc, criterion) => {
            criterion.tags?.split(";").forEach((tag) => {
                if (!acc.includes(tag.trim()) && tag.trim() !== "") acc.push(tag.trim());
            });
            return acc;
        },
        []
    ).sort();

    return {
        ...criteriaData,
        isAdmin,
        getCriteria: (criterionId) => _.find(criteriaData.criteria, (c) => c.id === criterionId),
        tags,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            cloneCriterion: actions.clone,
            unfreezeCriterion: actions.unfreeze,
            toggleDisplayFrozen: actions.toggleDisplayFrozen,
            toggleFilterType: actions.filterType,
            toggleAllFilterTypes: actions.filterTypeAll,
            toggleFilterTags: actions.filterTags,
            toggleAllFilterTags: actions.filterTagsAll,
            toggleAlphabetSort: actions.alphabetSort,
            showDeleteCriterionPrompt: (criterionId) =>
                navigationActions.showModal({
                    name: "deleteCriterion",
                    props: {
                        criterionId,
                    },
                }),
            push,

            showFreezeCriterionPrompt: (criterionId) =>
                navigationActions.showModal({
                    name: "freezeCriterion",
                    props: {
                        criterionId,
                    },
                }),
            selectCriteria: actions.selectCriteria,
        },
        dispatch
    );
}

export default withTranslation(connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(CriteriaList));
