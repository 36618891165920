import _ from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import React, { Component } from "react";
import EntityTable from "./EntityTable";
import { rowAction } from "./RowActions";
import { actions } from "../actions/followersActions";
import { actions as navigationActions } from "../actions/navigationActions";
import { actions as resonatorActions } from "../actions/resonatorActions";
import { actions as resonatorCreationActions } from "../actions/resonatorCreationActions";
import { push } from "connected-react-router";
import * as utils from "./utils";
// import moment from 'moment';
import OverflowMenu from "./OverflowMenu";
import getResonatorImage from "../selectors/getResonatorImage";
import { MenuItem, Typography, Avatar } from "@material-ui/core";
import { RemoveRedEye, PauseCircleFilled, PlayCircleFilled, Autorenew, Group, FileCopy } from "@material-ui/icons";
import { withTranslation } from "react-multi-lang";
import Filter from "components/Filter";

class FollowerResonators extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showDisabled: true,
        };

        this.handleRemoveResonator = this.handleRemoveResonator.bind(this);
        this.toggleShowInactive = this.toggleShowInactive.bind(this);
        this.handleCopyResonator = this.handleCopyResonator.bind(this);
        this.handleActivateResonator = this.handleActivateResonator.bind(this);
        this.handleDeactivateResonator = this.handleDeactivateResonator.bind(this);
        this.handleResetResonator = this.handleResetResonator.bind(this);
    }

    componentDidMount() {
        if (this.props.follower) this.props.fetchFollowerResonators(this.props.follower.id);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.follower && nextProps.follower?.id !== this.props.follower?.id)
            nextProps.fetchFollowerResonators(nextProps.follower.id);
    }

    getHeader() {
        const { t } = this.props;

        const header = [t("resonators.resonator")];
        if (this.props.tags?.length) {
            header.push(
                <Filter
                    name={t("followers.tags")}
                    list={[t("followers.no_tags"), ...this.props.tags]}
                    checkedList={this.props.tagsFilter || []}
                    toggleItem={this.props.toggleFilterTags.bind(this)}
                    toggleAllItems={this.props.toggleAllFilterTags.bind(this)}
                />
            );
        } else {
            header.push("Tags");
        }
        header.push(
            this.props.follower?.is_system ? (
                <Filter
                    name={t("resonators.type")}
                    list={[t("criteria.system"), t("criteria.regular")]}
                    checkedList={this.props.typeFilter || []}
                    toggleItem={this.props.toggleFilterType.bind(this)}
                    toggleAllItems={this.props.toggleAllFilterType.bind(this)}
                />
            ) : null
        );

        return header;
    }

    handleRemoveResonator(id) {
        this.props.showDeleteResonatorPrompt(id);
    }

    renderColumn(resonator) {
        const dir = utils.getResonatorDirection(resonator);
        const resonatorImage = getResonatorImage(resonator);

        return (
            <div style={{ display: "flex", alignItems: "center", filter: resonator.pop_email ? "" : "grayscale(1)" }}>
                {resonatorImage ? <Avatar src={resonatorImage} variant="rounded" /> : null}
                <div
                    style={{
                        direction: dir,
                        margin: "0 15px",
                        textAlign: dir === "rtl" ? "right" : "left",
                        color: resonator.pop_email ? "" : "grey",
                    }}
                >
                    <Typography style={{ fontWeight: "bold", direction: utils.getDir(resonator.title) }}>
                        {_.truncate(resonator.title, { length: 50 })}
                    </Typography>
                    <Typography color="textSecondary">
                        {_.truncate(
                            _.unescape(resonator.content)
                                .replace(/<[^>]*>?/gm, "")
                                .replace(/&nbsp;/g, " "),
                            { length: 50 }
                        )}
                    </Typography>
                    {resonator.contributor && (
                        <Typography color="textSecondary" style={{ whiteSpace: "nowrap" }}>
                            Contributor: {resonator.contributor}
                        </Typography>
                    )}
                </div>
                {resonator.parent_resonator_id ? (
                    <Group
                        variant="rounded"
                        fontSize="small"
                        style={{
                            marginLeft: 5,
                            color: resonator.pop_email ? "" : "grey",
                        }}
                    />
                ) : null}
            </div>
        );
    }

    getRows() {
        const { t } = this.props;
        const orderedResonators = _.orderBy(this.props.resonators, (r) => !r.pop_email)
            .filter(
                (resonator) =>
                    !this.props.tagsFilter?.length > 0 ||
                    resonator.tags?.split(";").some((item) => this.props.tagsFilter?.includes(item.trim())) ||
                    (this.props.tagsFilter?.includes(t("followers.no_tags")) && !resonator.tags?.length)
            )
            .filter(
                (resonator) =>
                    !this.props.follower?.is_system ||
                    !this.props.typeFilter?.length > 0 ||
                    resonator.is_system === this.props.typeFilter.includes(t("criteria.system")) ||
                    resonator.is_system !== this.props.typeFilter.includes(t("criteria.regular"))
            );
        return _.reduce(
            orderedResonators,
            (acc, r) => {
                if ((this.state.showDisabled && !r.pop_email) || r.pop_email)
                    acc[r.id] = [
                        this.renderColumn(r),
                        <div style={{ textAlign: "center" }}>
                            {r.tags?.split(";").map((tag) => {
                                if (!tag.trim()) return false;
                                return (
                                    <span
                                        className={
                                            this.props.tagsFilter?.includes(tag.trim())
                                                ? "followerTag active"
                                                : "followerTag"
                                        }
                                        onClick={() => this.props.toggleFilterTags(tag.trim())}
                                    >
                                        {tag};
                                    </span>
                                );
                            })}
                        </div>,
                        this.props.follower?.is_system ? (r.is_system ? "Public" : "Private") : null,
                    ];

                return acc;
            },
            {}
        );
    }

    toggleShowInactive() {
        this.setState({ showDisabled: !this.state.showDisabled });
    }
    getToolbox() {
        const { t } = this.props;
        const toolbox = {};

        toolbox.left = (
            <Typography variant="h6">
                {this.props.follower
                    ? (this.props.follower?.name === "Generic Follower"
                          ? t("system." + this.props.follower?.name)
                          : this.props.follower?.name) + t("resonators.s_resonators")
                    : "Loading..."}
            </Typography>
        );

        if (!this.props.follower?.is_system) {
            toolbox.right = (
                <OverflowMenu keepOpen>
                    <MenuItem onClick={this.toggleShowInactive}>
                        {this.state.showDisabled ? t("resonators.hide_inactive") : t("resonators.show_inactive")}
                    </MenuItem>
                </OverflowMenu>
            );
        }

        return toolbox;
    }

    handleCopyResonator(resonatorId) {
        this.props.showCopyResonatorModal(resonatorId);
    }

    handleActivateResonator(id) {
        const resonator = _.find(this.props.resonators, (r) => r.id === id);
        resonator.pop_email = true;
        const followerId = resonator.follower_id;
        this.props.activateResonator({ targetId: followerId, targetType: "follower", resonator });
    }

    handleDeactivateResonator(id) {
        const resonator = _.find(this.props.resonators, (r) => r.id === id);
        resonator.pop_email = false;
        const followerId = resonator.follower_id;
        this.props.activateResonator({ targetId: followerId, targetType: "follower", resonator });
    }

    handleResetResonator(id) {
        this.props.showResetResonatorPrompt(id);
    }

    getPreviewRoute(resonatorId) {
        return `/followers/${this.props.match.params.followerId}/resonators/${resonatorId}/show`;
    }

    getEditRoute(resonatorId) {
        return `/followers/${this.props.match.params.followerId}/resonators/${resonatorId}/edit`;
    }

    getAddRoute() {
        return `/followers/${this.props.match.params.followerId}/resonators/new`;
    }

    getRowActions() {
        const { t } = this.props;
        if (this.props.follower?.is_system && !this.props.isAdmin) return [];
        return [
            rowAction({
                title: t("resonators.preview"),
                icon: <RemoveRedEye />,
                onClick: (resonatorId) => this.props.push(this.getPreviewRoute(resonatorId)),
            }),
            rowAction.edit((resonatorId) => this.props.push(this.getEditRoute(resonatorId))),
            rowAction.remove(this.handleRemoveResonator),
        ];
    }

    getResonator(resonatorId) {
        return _.find(this.props.resonators, (resonator) => resonator.id === resonatorId);
    }

    getExtraRowActions() {
        const { t } = this.props;
        return [
            rowAction({
                icon: <FileCopy />,
                title: t("resonators.copy_to"),
                onClick: this.handleCopyResonator,
            }),
            rowAction({
                icon: <PauseCircleFilled />,
                title: t("resonators.deactivate"),
                onClick: this.handleDeactivateResonator,
                isAvailable: (resonatorId) =>
                    !this.props.follower?.is_system && this.getResonator(resonatorId).pop_email,
            }),
            rowAction({
                icon: <PlayCircleFilled />,
                title: t("resonators.activate"),
                onClick: this.handleActivateResonator,
                isAvailable: (resonatorId) =>
                    !this.props.follower?.is_system && !this.getResonator(resonatorId).pop_email,
            }),
            rowAction({
                icon: <Autorenew />,
                title: t("resonators.reset"),
                onClick: this.handleResetResonator,
                isAvailable: (resonatorId) =>
                    !this.props.follower?.is_system && Boolean(this.getResonator(resonatorId).parent_resonator_id),
            }),
        ];
    }

    render() {
        const { t } = this.props;

        return (
            <EntityTable
                addButton={!this.props.follower?.is_system || this.props.isAdmin}
                rows={this.getRows()}
                header={this.getHeader()}
                toolbox={this.getToolbox()}
                rowActions={this.getRowActions()}
                extraRowActions={this.getExtraRowActions()}
                onAdd={() => this.props.push(this.getAddRoute())}
                addText={t("resonators.create")}
            />
        );
    }
}

function mapStateToProps(
    state,
    {
        match: {
            params: { followerId },
        },
    }
) {
    if (!followerId) return {};

    const follower =
        _.find(state.followers.followers, (f) => f.id === followerId) ||
        _.find(state.followers.systemFollowers, (f) => f.id === followerId);
    const resonators = _.get(follower, "resonators");
    const isAdmin = state.leaders.leaders.admin_permissions;
    const tags = _.reduce(
        resonators,
        (acc, resonator) => {
            resonator.tags?.split(";").forEach((tag) => {
                if (!acc.includes(tag.trim()) && tag.trim() !== "") acc.push(tag.trim());
            });
            return acc;
        },
        []
    ).sort();

    return {
        resonators,
        follower,
        tags,
        tagsFilter: state.followers.resonatorsTagsFilter,
        typeFilter: state.followers.resonatorsTypeFilter,
        isAdmin,
    };
}

function mapDispatchToProps(dispatch /* {params: {followerId}} */) {
    return bindActionCreators(
        {
            fetchFollowerResonators: actions.fetchFollowerResonators,
            activateResonator: resonatorActions.activate,
            toggleFilterTags: actions.filterResonatorTags,
            toggleAllFilterTags: actions.filterResonatorTagsAll,
            toggleFilterType: actions.filterResonatorType,
            toggleAllFilterType: actions.filterResonatorTypeAll,
            showCopyResonatorModal: (resonatorId) =>
                navigationActions.showModal({
                    name: "copyResonator",
                    props: {
                        resonatorId,
                    },
                }),
            showResetResonatorPrompt: (resonatorId) =>
                navigationActions.showModal({
                    name: "resetResonator",
                    props: {
                        resonatorId,
                    },
                }),
            showDeleteResonatorPrompt: (resonatorId) =>
                navigationActions.showModal({
                    name: "deleteResonator",
                    props: {
                        resonatorId,
                        isGroup: false,
                    },
                }),
            push,
        },
        dispatch
    );
}

export default withTranslation(connect(mapStateToProps, mapDispatchToProps)(FollowerResonators));
