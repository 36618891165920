import React from "react";
import { bindActionCreators } from "redux";
import { actions } from "../../../actions/resonatorCreationActions";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import NavButtons from "./navButtons";

export default ({ noNext = false, noBack = false, validate = () => ({}) } = {}) =>
    (Component) => {
        let StepBase = (props) => {
            // Form fields that are not connected to the redux form can update this using props.updateUnConnectedFormData
            let unConnectedFormData = null;

            function updateUnConnectedFormData(formData) {
                unConnectedFormData = { ...formData };
            }

            function handleSubmit(formData) {
                let useFormData = formData;
                if (unConnectedFormData) {
                    useFormData = { ...formData, ...unConnectedFormData };
                }

                props.updateCreationStep(useFormData);
                props.onNext();
            }

            const combinedProps = {
                ...props,
                updateUnConnectedFormData: updateUnConnectedFormData,
            };

            return (
                <form onSubmit={props.handleSubmit(handleSubmit)}>
                    <Component {...combinedProps} />
                    {!props.editMode && (
                        <NavButtons
                            noNext={noNext}
                            noBack={noBack}
                            onBack={props.onBack}
                            nextDisabled={props.invalid}
                        />
                    )}
                </form>
            );
        };

        function mapDispatchToProps(dispatch) {
            return bindActionCreators(
                {
                    updateCreationStep: actions.updateCreationStep,
                },
                dispatch
            );
        }

        function mapStateToProps(state) {
            return {
                initialValues: state.resonatorCreation.formData,
                enableReinitialize: true,
            };
        }
        StepBase = reduxForm({
            form: "resonatorCreation",
            destroyOnUnmount: false,
            validate,
        })(StepBase);
        StepBase = connect(mapStateToProps, mapDispatchToProps)(StepBase);
        return StepBase;
    };
