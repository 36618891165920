import React, { Component } from 'react';
import ace from "brace";
import "brace/mode/json";
import "brace/theme/github";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import SaveIcon from '@material-ui/icons/Save';
import {Tabs, Tab, Button, Paper} from '@material-ui/core';
import {bindActionCreators} from "redux";
import {actions} from "actions/TranslationsActions";
import {connect} from "react-redux";

class Translations extends Component {

    constructor(props) {
        super(props);

        this.state = {tab: "EN"};
    }

    async componentDidMount() {
        this.props.fetchTranslations();
    }

    async submit(lang) {
        const saveLang = this.props.translations.find(x => x.lang === lang);
        this.props.update(saveLang);
    }

    onChange(data, lang) {
        const index = this.props.translations.findIndex(x => x.lang === lang);
        const updatedTranslations = this.props.translations;
        updatedTranslations[index] = {lang: lang, file: data};
    }

    render() {
        return (<>
            {this.props.translations?.length > 0 && <>
                <Tabs value={this.state.tab} onChange={(e, value) => this.setState({tab: value})}>
                    {this.props.translations.map((lang) =>
                        <Tab label={lang.lang} value={lang.lang} />
                    )}
                </Tabs>
                {this.props.translations.map((lang) =>
                    <Paper elevation={3} hidden={this.state.tab !== lang.lang}>
                        <Editor
                            id='lang'
                            value={lang.file}
                            onChange={(data) => { this.onChange(data, lang.lang) }}
                            ace={ace}
                            theme="ace/theme/github"
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            startIcon={<SaveIcon />}
                            onClick={() => { this.submit(lang.lang) }}
                        >
                            Save
                        </Button>
                    </Paper>
                )}
            </>}
        </>);
    }
}

function mapStateToProps(state) {
    return {
        translations: state.translations.translations,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchTranslations: actions.fetch,
            update: actions.update,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Translations);
