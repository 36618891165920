import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push as goto } from "connected-react-router";
import {
    List,
    ListItem,
    ListSubheader,
    ListItemAvatar,
    ListItemText,
    ListItemIcon,
    Paper,
    makeStyles,
    Grow,
    Typography,
} from "@material-ui/core";

import ResonatorAvatar from "../ResonatorAvatar";
import { formatResonatorTime } from "./utils";
import { isResonatorExpired } from "../utils";
import * as resonatorApi from "../../api/resonator";
import { useTranslation } from 'react-multi-lang';
import {RemoveRedEye} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    itemBig: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    avatarBig: {
        height: theme.spacing(8),
        width: theme.spacing(8),
    },
    itemTextBig: {
        marginLeft: theme.spacing(2),
    },
    insetTextBig: {
        marginLeft: theme.spacing(3),
    },
    noResults: {
        textAlign: "center",
    },
}));

function ResonatorList({ resonators, subheader, paperProps = {}, big = false, viewStats = false, is_static = false, goto }) {
    const t = useTranslation();
    const classes = useStyles();
    const listItemClass = big ? classes.itemBig : "";
    const avatarClass = big ? classes.avatarBig : "";
    const itemTextClass = big ? classes.itemTextBig : "";
    const insetBigClass = big ? classes.insetTextBig : "";

    return (
        <List>
            <ListSubheader>{subheader}</ListSubheader>
            <Paper {...paperProps}>
                {!resonators.length && (
                    <ListItem className={classes.noResults}>
                        <ListItemText primary={<Typography color="textSecondary">{t('general.no_results')}</Typography>} />
                    </ListItem>
                )}
                {resonators.map((resonator) => (
                    <Grow in key={resonator.id}>
                        <ListItem
                            button
                            divider
                            className={listItemClass}
                            onClick={async () => { if (is_static) {
                                const {sentResonatorId} = await resonatorApi.createStaticResonatorInstance(resonator.id);
                                return goto(`/follower/resonators/${sentResonatorId}`)
                            } else {
                                return goto(`/follower/resonators/${resonator.id}`);
                            }}}
                        >
                            {resonator.picture ? (
                                <ListItemAvatar>
                                    <ResonatorAvatar resonator={resonator} className={avatarClass} />
                                </ListItemAvatar>
                            ) : null}
                            <ListItemText
                                inset={!resonator.picture}
                                primary={resonator.title}
                                secondary={<React.Fragment>
                                    {resonator.clinic_name && <Typography>{t('clinics.clinic')}: {resonator.clinic_name}</Typography>}
                                    {resonator.leader_name && <Typography>{t('leaders.leader')}: {resonator.leader_name}</Typography>}
                                    {!is_static && <>
                                        <Typography>{t('resonators.status')}: { isResonatorExpired(resonator) ? "Expired" : resonator.status}</Typography>
                                        {formatResonatorTime(resonator.time)}
                                    </>}
                                </React.Fragment>}
                                primaryTypographyProps={{ noWrap: true }}
                                classes={{ root: itemTextClass, inset: insetBigClass }}
                            />
                            {/*{viewStats && <ListItemIcon onClick={(e) => {e.stopPropagation(); goto(`/follower/resonators/${resonator.id}/show`)}}><RemoveRedEye /></ListItemIcon>}*/}
                        </ListItem>
                    </Grow>
                ))}
            </Paper>
        </List>
    );
}

export default connect(null, (dispatch) => bindActionCreators({ goto }, dispatch))(ResonatorList);
