import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import api from "../../api";
import LoadingOverlay from "./LoadingOverlay";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-multi-lang";
export default function FollowerDeactivateResonator() {
    const t = useTranslation();
    const { resonatorId } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [res, setRes] = useState(null);

    useEffect(() => {
        if (resonatorId) {
            deactivateResonator(resonatorId);
        }
    }, [resonatorId]);

    const deactivateResonator = (id) => {
        setLoading(true);
        api.put(`/follower/resonators/${id}/deactivate`)
            .then((data) => {
                setRes(data.sentResonator);
            })
            .catch((e) => {
                setError(e.status);
            })
            .finally(() => setLoading(false));
    };
    return (
        <>
            {loading && <LoadingOverlay loading={loading} />}
            {error && <Typography>{error}</Typography>}
            {res && !res.pop_email ? (
                <>
                    <Typography>Resonator {res.title} was successfully disabled.</Typography>
                </>
            ) : null}
        </>
    );
}
