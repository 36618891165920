import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "../actions/followersActions";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Checkbox,
    Select,
    InputLabel,
    MenuItem,
    FormControlLabel,
    Snackbar,
} from "@material-ui/core";
import { Field, reduxForm } from "redux-form";
import Autocomplete from "./FormComponents/Autocomplete";
import navigationInfoSelector from "../selectors/navigationSelector";
import IsSystemRadio from "./IsSystemRadio";
import Papa from "papaparse";
import "./EditFollowerModal.scss";
import TextField from "components/FormComponents/TextField";
import { withTranslation } from "react-multi-lang";
import SimplePrompt from "components/SimplePrompt";

class EditFollowerModal extends Component {
    static propTypes = {
        editMode: PropTypes.bool,
        open: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        const { t } = props;

        let editCfg = {
            title: t("followers.edit") + " " + t("followers.follower"),
            doneBtn: "Update",
        };

        let newCfg = {
            title: t("followers.add"),
            doneBtn: "Create",
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.importFollowers = this.importFollowers.bind(this);
        this.toggleSystem = this.toggleSystem.bind(this);
        this.renderForm = this.renderForm.bind(this);
        this.autocompleteNextField = this.autocompleteNextField.bind(this);
        this.cfg = props.editMode ? editCfg : newCfg;
        this.state = {
            is_system: props.editMode && props.follower.is_system ? props.follower.is_system : false,
            showFollowerExists: false,
        };

        this.nameFieldRef = React.createRef();
        this.emailFieldRef = React.createRef();
    }

    importFollowers(e) {
        const importFile = e.target.files[0];
        Papa.parse(importFile, {
            header: true,
            skipEmptyLines: true,
            complete: ({ data }) => {
                if (_.every(data, (follower) => _.isEmpty(validateInput(trimObj(follower))))) {
                    for (const follower of data) {
                        this.props.create({
                            ...trimObj(follower),
                            clinic: this.props.initialValues.clinic,
                        });
                    }
                    this.props.onClose();
                }
            },
        });
    }

    handleClose() {
        this.props.onClose();
    }

    handleSubmit(formData) {
        const checkExisting = typeof formData.checkExisting !== "undefined" ? formData.checkExisting : true;
        formData.name = formData.field1; // Use the weird field names to disable browser autocomplete
        formData.email = formData.field2;

        formData.is_system = this.state.is_system;
        if (this.props.editMode) {
            this.props.update({ ...formData, id: this.props.followerId });
        } else {
            const existingFollower = this.props.followers.find(
                (f) => f.user?.email?.toLowerCase() === formData.email?.toLowerCase()
            );
            if (checkExisting && existingFollower) {
                this.setState({ showFollowerExists: existingFollower.frozen ? "deactivated" : true });
                return false;
            }
            this.props.create(formData);
        }

        //this.props.onClose();
    }

    toggleSystem() {
        this.setState({
            is_system: !this.state.is_system,
        });
    }

    autocompleteNextField(selectedOption, labelKey) {
        if (labelKey === "name") {
            this.emailFieldRef.current.value = selectedOption.email;
            this.props.formData.values.field2 = selectedOption.email;
        } else {
            this.nameFieldRef.current.value = selectedOption.name;
            this.props.formData.values.field1 = selectedOption.name;
        }
    }

    renderForm() {
        return (
            <form autoComplete="off">
                <Field
                    options={this.props.googleContacts}
                    type="text"
                    placeholder="Name"
                    name="field1"
                    component={Autocomplete}
                    autocompleteNextField={this.autocompleteNextField}
                    labelKey="name"
                    inputRef={this.nameFieldRef}
                />
                <Field
                    options={this.props.googleContacts}
                    type="email"
                    placeholder="Email"
                    name="field2"
                    component={Autocomplete}
                    autocompleteNextField={this.autocompleteNextField}
                    labelKey="email"
                    inputRef={this.emailFieldRef}
                />
                <Field name="tags" label="Tags (Optional, e.g.: OCD; OASIS; ETC)" component={TextField} />

                {!this.props.editMode && (
                    <div>
                        <IsSystemRadio
                            isAdmin={this.props.isAdmin}
                            isSystem={this.state.is_system}
                            toggleSystem={this.toggleSystem}
                            owner={this.props.follower?.user?.name}
                        />
                    </div>
                )}
            </form>
        );
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <Dialog open={this.props.open} className="edit-follower-modal" onClose={this.props.onClose}>
                    <DialogTitle>{this.cfg.title}</DialogTitle>
                    <DialogContent>{this.renderForm()}</DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose}>Cancel</Button>
                        {!this.props.editMode && (
                            <React.Fragment>
                                <input
                                    accept=".csv"
                                    style={{ display: "none" }}
                                    id="import-followers"
                                    type="file"
                                    onChange={this.importFollowers}
                                />
                                <label htmlFor="import-followers">
                                    <Button component="span" variant="contained">
                                        Import
                                    </Button>
                                </label>
                            </React.Fragment>
                        )}

                        <Button
                            onClick={this.props.handleSubmit(this.handleSubmit)}
                            color="primary"
                            variant="contained"
                            className="create-follower-btn"
                        >
                            {this.cfg.doneBtn}
                        </Button>
                    </DialogActions>
                </Dialog>
                <SimplePrompt
                    title={t("followers.follower") + " Exists"}
                    text={`A ${t("followers.follower")} with this email already exists${
                        this.state.showFollowerExists === "deactivated" ? " (On the Deactivated list)" : ""
                    }. Are you sure you want to create a new ${t("followers.follower")} with the same email?`}
                    acceptText="Create"
                    onAccept={() => {
                        this.handleSubmit({ ...this.props.formData.values, checkExisting: false });
                    }}
                    onClose={(onAccept) => {
                        this.setState({ showFollowerExists: false });
                        typeof onAccept === "function" && onAccept();
                    }}
                    open={!!this.state.showFollowerExists}
                />
            </>
        );
    }
}

function trimObj(obj) {
    for (const key in obj) {
        if (typeof obj[key] === "string") {
            const trimmedValue = obj[key].trim();
            const trimmedKey = typeof key === "string" ? key.trim() : key;
            if (trimmedKey !== key) {
                obj[trimmedKey] = trimmedValue;
                delete obj[key];
            } else {
                obj[key] = trimmedValue;
            }
        }
    }

    return obj;
}

function validateInput(data) {
    let errors = {};

    if (!data.name) errors.name = "Required";

    if (!data.email) {
        errors.email = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
        errors.email = "Invalid email address";
    }

    return errors;
}

let Form = reduxForm({
    form: "editFollower",
    validate: validateInput,
})(EditFollowerModal);

function mapStateToProps(state) {
    const {
        modalProps: { followerId, editMode },
    } = navigationInfoSelector(state);
    const followers = state.followers.followers;
    const follower = _.find(followers, (f) => f.id === followerId);
    const clinics = state.clinics.clinics;
    const current_clinic_id = state.leaders.leaders.current_clinic_id;
    const isAdmin = state.leaders.leaders.admin_permissions;
    const googleContacts = state.googleData.googleContacts;
    const formData = state.form.editFollower;

    const ret = {
        followers,
        follower,
        clinics,
        editMode,
        isAdmin,
        googleContacts,
        formData,
    };

    if (follower) {
        ret.initialValues = {
            name: follower.name,
            field1: follower.name,
            email: follower.user.email,
            field2: follower.user.email,
            is_system: follower.user.is_system,
            tags: follower.tags,
        };
    } else {
        ret.initialValues = {
            clinic: current_clinic_id,
        };
    }
    return ret;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            update: actions.update,
            create: actions.create,
        },
        dispatch
    );
}

export default withTranslation(connect(mapStateToProps, mapDispatchToProps)(Form));
