import { connect } from "react-redux";
import React, { useState } from "react";
import { bindActionCreators } from "redux";
import {
    Divider,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Drawer,
    Collapse,
    Toolbar,
    makeStyles,
} from "@material-ui/core";
import {
    Weekend,
    ViewList,
    EventNote,
    ExitToApp,
    ExpandLess,
    ExpandMore,
    Group,
    Person,
    ContactMail,
    DirectionsWalk,
    AddToHomeScreen,
    PermIdentity,
    Book,
    List as ListIcon,
    Translate as TranslateIcon,
} from "@material-ui/icons";
import { useBelowBreakpoint } from "./hooks";
import { actions } from "../actions/menuActions";
import ClinicSettings from "./ClinicSettings";
import navigationSelector from "../selectors/navigationSelector";
import { useTranslation } from "react-multi-lang";
import { isIphone, isIosMinimumVersion, isSafari } from "../utils";
import { NotificationsOff } from "@material-ui/icons";
import { actions as notificationsActions } from "../actions/notificationsActions";

const useStyles = makeStyles((theme) => ({
    drawer: {
        minWidth: 250,
        padding: theme.spacing(1),
    },
    logoutButton: {
        color: theme.palette.error.main,
    },
    notificationsIcon: {
        color: theme.palette.error.main,
    },
    installButton: {
        marginTop: "auto",
        marginBottom: theme.spacing(1),
    },
}));

function SideMenu(props) {
    const t = useTranslation();
    const classes = useStyles(props);
    const screenSmall = useBelowBreakpoint("sm");

    const [followerMenuOpen, setFollowerMenuOpen] = useState(true);

    const addToHomeScreen = () => props.installPrompt.prompt();
    const userAgent = navigator.userAgent;
    const showNotificationButton =
        !props.iPhoneNotificationsEnabled &&
        userAgent &&
        isIphone(userAgent) &&
        isSafari(userAgent) &&
        isIosMinimumVersion(userAgent, 16.4);
    const showDeveloperButton =
        props.leader.id === "ba8bb461-f06a-4a85-befa-7967b9c89ac5" ||
        props.leader.id === "9feb72a3-955c-4074-bee2-f94373b8ce7e";
    return (
        <Drawer
            open={props.menuOpen}
            variant={screenSmall ? "temporary" : "permanent"}
            classes={{ root: classes.drawer, paper: classes.drawer }}
            PaperProps={{ elevation: 4 }}
            onClose={props.toggleMenu}
        >
            {screenSmall ? null : (
                /* a placeholder to keep the content below the app bar.
                   The drawer must have a separate toolbar placeholder because it has position: fixed.
                   Not required on "mobile" cause then we use the temporary drawer style. */
                <Toolbar />
            )}
            {props.user && (
                <List>
                    <ListItem>
                        <ListItemIcon style={{ margin: "0 auto", alignItems: "center" }}>
                            <PermIdentity />
                            <ListItemText primary={props.user.email} />
                            {props.user?.isLeader && props.currentClinic?.is_primary && <ClinicSettings />}
                        </ListItemIcon>
                    </ListItem>
                </List>
            )}
            <Divider />
            <List>
                {props.user?.isLeader && (
                    <>
                        {props.leader.group_permissions ? (
                            <React.Fragment>
                                <ListItem button onClick={() => setFollowerMenuOpen(!followerMenuOpen)}>
                                    <ListItemIcon>
                                        <DirectionsWalk />
                                    </ListItemIcon>
                                    <ListItemText primary={t("followers.followers")} />
                                    {followerMenuOpen ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={followerMenuOpen}>
                                    <List style={{ marginLeft: 20 }}>
                                        <ListItem button onClick={() => props.clickMenuItem("followers")}>
                                            <ListItemIcon>
                                                <Person />
                                            </ListItemIcon>
                                            <ListItemText primary={t("followers.followers_list")} />
                                        </ListItem>

                                        <ListItem button onClick={() => props.clickMenuItem("followerGroups")}>
                                            <ListItemIcon>
                                                <Group />
                                            </ListItemIcon>
                                            <ListItemText primary={t("followers.followers_groups")} />
                                        </ListItem>
                                    </List>
                                </Collapse>
                            </React.Fragment>
                        ) : (
                            <ListItem button onClick={() => props.clickMenuItem("followers")}>
                                <ListItemIcon>
                                    <DirectionsWalk />
                                </ListItemIcon>
                                <ListItemText primary={t("followers.followers")} />
                            </ListItem>
                        )}
                        {false && (
                            <ListItem button onClick={() => props.clickMenuItem("clinics")}>
                                <ListItemIcon>
                                    <ListIcon />
                                </ListItemIcon>
                                <ListItemText primary={t("clinics.clinics_list")} />
                            </ListItem>
                        )}
                        <ListItem button onClick={() => props.clickMenuItem("criteriaList")}>
                            <ListItemIcon>
                                <EventNote />
                            </ListItemIcon>
                            <ListItemText primary={t("criteria.criteria_list")} />
                        </ListItem>
                        <ListItem button onClick={() => props.clickMenuItem("invitations")}>
                            <ListItemIcon>
                                <ContactMail />
                            </ListItemIcon>
                            <ListItemText primary={t("invitations.gmail_invitations")} />
                        </ListItem>
                        <ListItem button onClick={() => props.clickMenuItem("activityLog")}>
                            <ListItemIcon>
                                <Book />
                            </ListItemIcon>
                            <ListItemText primary={t("activity.activity_log")} />
                        </ListItem>
                    </>
                )}
                {props.user?.isFollower && (
                    <ListItem button onClick={() => props.clickMenuItem("follower/resonators")}>
                        <ListItemIcon>
                            <ViewList />
                        </ListItemIcon>
                        <ListItemText primary={t("resonators.incoming_resonators")} />
                    </ListItem>
                )}
                {props.leader?.admin_permissions && (
                    <ListItem button onClick={() => props.clickMenuItem("translations")}>
                        <ListItemIcon>
                            <TranslateIcon />
                        </ListItemIcon>
                        <ListItemText primary="Translations" />
                    </ListItem>
                )}
            </List>
            <Divider />
            <List>
                <ListItem button onClick={() => props.clickMenuItem("logout")} className={classes.logoutButton}>
                    <ListItemIcon>
                        <ExitToApp color="error" />
                    </ListItemIcon>
                    <ListItemText primary={t("general.logout")} />
                </ListItem>
            </List>
            {showNotificationButton && (
                <div>
                    <Divider />
                    <List>
                        <ListItem
                            button
                            onClick={async () => {
                                props.subscribeToPushNotifications();
                            }}
                        >
                            <ListItemIcon className={classes.notificationsIcon}>
                                <NotificationsOff />
                            </ListItemIcon>
                            <ListItemText primary={t("general.enable_notifications")} />
                        </ListItem>
                    </List>
                </div>
            )}
            {showDeveloperButton && (
                <div>
                    <Divider />
                    <List>
                        <ListItem button onClick={() => props.clickMenuItem("developer")}>
                            <ListItemText primary={t("Developer")} />
                        </ListItem>
                    </List>
                </div>
            )}
            {props.installPrompt && (
                <div className={classes.installButton}>
                    <Divider />
                    <List>
                        <ListItem button onClick={addToHomeScreen}>
                            <ListItemIcon>
                                <AddToHomeScreen />
                            </ListItemIcon>
                            <ListItemText primary={t("general.add_to_home_screen")} />
                        </ListItem>
                    </List>
                </div>
            )}
        </Drawer>
    );
}

export default connect(
    (state) => ({
        menuOpen: navigationSelector(state).menuOpen,
        leader: state.leaders.leaders,
        currentClinic: state.clinics?.clinics?.find((c) => c.id === state.leaders?.leaders?.current_clinic_id),
        user: state.session.user,
        installPrompt: state.pwa.installPrompt,
        iPhoneNotificationsEnabled: state.notifications.iPhoneNotificationsEnabled,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                toggleMenu: actions.toggleMenu,
                clickMenuItem: actions.clickMenuItem,
                subscribeToPushNotifications: notificationsActions.subscribe,
            },
            dispatch
        )
)(SideMenu);
