import React, { Component } from "react";
import HRV from "../../apps/HRV";

import "./media.scss";
import ToggleField from "components/FormComponents/ToggleField";
import {Field} from "redux-form";
import StepBase from "components/EditResonator/Steps/stepBase";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {actions} from "actions/resonatorCreationActions";
import {Checkbox, FormControlLabel} from "@material-ui/core";

class EditResonatorApps extends Component {
    render() {
        return (
            <div>
                <Field name='breather'
                       component={ToggleField}
                       label='Breathing Pacer'
                />
                {(this.props.formData?.breather === 'on') && <>
                    <FormControlLabel
                        control={<Checkbox
                            color="primary"
                            checked={this.props.formData?.breatherConfig?.hrv}
                            onChange={() => this.props.updateCreationStep({...this.props.formData, breatherConfig: {...this.props.formData?.breatherConfig, hrv: !this.props.formData?.breatherConfig?.hrv}})}
                        />
                        }
                        label="HRV"
                    />
                    <HRV resonatorId={this.props.resonator.id} breather={this.props.formData?.breatherConfig || false} updateConfig={(payload) => this.props.updateCreationStep({...this.props.formData, breatherConfig: payload})}/>
                </>}
            </div>
        );
    }
}

EditResonatorApps = StepBase({
    noNext: false,
    noBack: false,
})(EditResonatorApps);

EditResonatorApps = connect(
    (state) => ({
        resonator: state.resonatorCreation.resonator,
        formData: state.form.resonatorCreation.values,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                updateCreationStep: actions.updateCreationStep,
            },
            dispatch
        )
)(EditResonatorApps);

export default EditResonatorApps;
