import React, { Component } from "react";
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Typography } from "@material-ui/core";
import { getGroupResonatorStatistics } from "../api/resonatorStats";
import "./ResonatorStatistics.scss";

class ResonatorStatistics extends Component {
    constructor(props) {
        super(props);

        this.state = { dates: [], questions: [], count: [] };
    }

    componentDidMount() {
        const { resonatorId, followerGroupId } = this.props.match.params;
        getGroupResonatorStatistics(followerGroupId, resonatorId).then((report) => {
            this.setState(report);
        });
    }
    getTableHeadRow = () => {
        if (this.state.questions.length === 0)
            return (
                <TableCell align="center">
                    <Typography variant="body1" component="div" align="center">
                        No questions have been answered for this Resonator
                    </Typography>
                </TableCell>
            );
        return (
            <>
                <TableCell>Question</TableCell>
                {this.state.dates.map((date, index) => {
                    const d = new Date(date);
                    return (
                        <TableCell key={index} align="center" style={{ width: "100px" }}>
                            {d.toLocaleString("en-US", { month: "short" }) + "-" + d.getDate()}
                        </TableCell>
                    );
                })}
            </>
        );
    };

    getQuestionRow = (q, qndx) => {
        return (
            <TableRow key={qndx} className="hover-highlight">
                <TableCell component="th" scope="row">
                    {q}
                </TableCell>
                {this.state.counts[qndx].map((count, cndx) => (
                    <TableCell key={cndx} align="center">
                        {count}
                    </TableCell>
                ))}
            </TableRow>
        );
    };

    getAllQuestionRows = () => this.state.questions.map((q, qndx) => this.getQuestionRow(q.title, qndx));

    render() {
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 250 }} size="small">
                    <TableHead>
                        <TableRow key="tabletitle">
                            <TableCell colSpan={this.state.dates.length + 1} align="center">
                                <Typography variant="h6">Answers per question per day</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow key="tableheader">{this.getTableHeadRow()}</TableRow>
                    </TableHead>
                    {this.state.questions.length > 0 && <TableBody>{this.getAllQuestionRows()}</TableBody>}
                </Table>
            </TableContainer>
        );
    }
}

export default ResonatorStatistics;
