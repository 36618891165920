import React, { Component } from "react";
import { Field } from "redux-form";
import TextField from "../../FormComponents/TextField";
import StepBase from "./stepBase";
import MUIRichTextEditor from "mui-rte";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { richEditorTheme } from "../../richEditorTheme";
import { ContentState, convertFromHTML, convertToRaw, convertFromRaw, EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { connect } from "react-redux";

import "./basic.scss";
import { bindActionCreators } from "redux";
import { actions } from "actions/resonatorCreationActions";
import { withTranslation } from "react-multi-lang";
import IsSystemRadio from "components/IsSystemRadio";

class EditResonatorRichText extends Component {
    lastRichTextHTMLValue = null;

    constructor(props) {
        super(props);
    }

    // By catching the onChange event and not passing it to redux, we break the
    // redux form Field loop. We want to avoid re-render while editing this field, otherwise, every time we type the re-rednder event
    // will cause the cusrsor to move to the start. We save the value of "description"
    // at the HOC. For resonator creation we save it in StepBase. For Edit we save it
    // at EditResonator because there is no submit event in this case (Next button)
    onRichTextChange = (state) => {
        this.lastRichTextHTMLValue = stateToHTML(state.getCurrentContent());
        if (this.props.editMode) {
            this.props.updateDescriptionEditMode(this.lastRichTextHTMLValue);
        } else {
            this.props.updateUnConnectedFormData({ description: this.lastRichTextHTMLValue });
        }
    };

    render() {
        let htmlValue = "";
        if (this.props.description && this.props.description.length > 0) {
            htmlValue = this.props.description;
        } else {
            htmlValue = "<p><br></p>";
        }
        const convertedValue = convertFromHTML(htmlValue);
        const valueAsContentState = ContentState.createFromBlockArray(
            convertedValue.contentBlocks,
            convertedValue.entityMap
        );
        const defaultValue = JSON.stringify(convertToRaw(valueAsContentState));

        return (
            <MuiThemeProvider theme={richEditorTheme}>
                <MUIRichTextEditor
                    controls={["title", "bold", "italic", "underline", "strikethrough"]}
                    label="Description"
                    defaultValue={defaultValue}
                    onChange={this.onRichTextChange}
                />
            </MuiThemeProvider>
        );
    }
}
class EditResonatorBasic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: props.formData.title || "",
        };
    }

    render() {
        const { t } = this.props;

        return (
            <div>
                {this.props.isSystemFollower && (
                    <IsSystemRadio
                        isAdmin={this.props.isAdmin || false}
                        isSystem={this.props.formData.is_system || false}
                        toggleSystem={() =>
                            this.props.updateCreationStep({
                                is_system: !this.props.formData.is_system,
                                title: this.state.title,
                            })
                        }
                        owner={this.props.formData.contributor}
                    />
                )}
                {this.props.isAdmin && <Field name="contributor" label="Contributor" component={TextField} />}
                <Field
                    name="title"
                    label={t("resonators.title")}
                    component={TextField}
                    style={{
                        marginBottom: "25px",
                    }}
                    onChange={(e) => this.setState({ title: e.target.value })}
                />
                <Field
                    type="text"
                    placeholder={t("resonators.description")}
                    label={t("resonators.description")}
                    name="description"
                    updateUnConnectedFormData={this.props.updateUnConnectedFormData}
                    updateDescriptionEditMode={this.props.updateDescriptionEditMode}
                    editMode={this.props.editMode}
                    description={this.props.description}
                    component={EditResonatorRichText}
                />
                <Field name="link" label={t("resonators.link")} type="url" component={TextField} />
                <Field name="tags" label="Tags (Optional, e.g.: OCD; OASIS; ETC)" component={TextField} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const isAdmin = state.leaders.leaders.content_permissions;
    const leader = state.leaders.leaders;

    return {
        formData: state.resonatorCreation?.formData,
        isAdmin,
        leader,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateCreationStep: actions.updateCreationStep,
        },
        dispatch
    );
}

export default withTranslation(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        StepBase({
            noBack: true,
            validate(formData) {
                let errors = {};
                if (!formData.title) errors.title = "Required";
                return errors;
            },
        })(EditResonatorBasic)
    )
);
