import { connect } from "react-redux";
import React, { Component } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { actions } from "../actions/notificationsActions";
import { withTranslation } from "react-multi-lang";
import { bindActionCreators } from "redux";

class NotificationPermissionRequestModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { t } = this.props;
        return (
            <Dialog open={this.props.open} onClose={this.props.onClose}>
                <DialogTitle>Allow Psysession to Send Notifications</DialogTitle>
                <DialogContent>
                    <div>{t("general.notification_request")}</div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            this.props.snoozeNotifications();
                            this.props.onClose();
                        }}
                    >
                        Maybe Later
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={async () => {
                            this.props.subscribeToPushNotifications();
                            this.props.onClose();
                        }}
                    >
                        Enable
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            snoozeNotifications: actions.snoozeNotifications,
            subscribeToPushNotifications: actions.subscribe,
        },
        dispatch
    );
}
export default withTranslation(
    connect((state) => ({ user: state.session.user }), mapDispatchToProps)(NotificationPermissionRequestModal)
);
