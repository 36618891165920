export function isSafari(userAgent) {
    return userAgent && !userAgent.match(/chrome/i) && !userAgent.match(/firefox/i) && userAgent.match(/safari/i);
}

export function isIphone(userAgent) {
    return userAgent.match(/iphone/i);
}

export function isIosMinimumVersion(userAgent, requiredMinVersion) {
    if (!isIphone) return false;
    const matchResult = userAgent.match(/OS.* (\d+)_(\d+)_(\d+)/);
    const iosVersion = window.Number(matchResult[1] + "." + matchResult[2]);
    return iosVersion >= requiredMinVersion;
}

export function isStandalone() {
    return window.matchMedia("(display-mode: standalone)").matches;
}
