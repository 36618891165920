import React from "react";

const AmplitudeIcon = ({ style, className }) => {
    return (
        <svg style={{ ...style }} className={className} height="24" width="24" fill="none" stroke="black">
            <path d="M1 14 L3 10 L5 6 L7 4 L9 5 L11 8 L13 12 L15 16 L17 19 L19 20 L21 18 L23 14" strokeWidth="3" />
            <line x1="1" y1="22" x2="23" y2="22" strokeDasharray="4" />
            <line x1="1" y1="2" x2="23" y2="2" strokeDasharray="4" />
        </svg>
    );
};

export default AmplitudeIcon;
