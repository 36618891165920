import React, {Component} from 'react';
import { Typography } from '@material-ui/core';

export default class ValueListCreation extends Component {
    render() {
        return (
            <div className='valuelist-creation'>
                <Typography variant="subtitle1">
                    Value List Creation
                </Typography>
            </div>
        );
    }
}
