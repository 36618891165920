import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-multi-lang";

import "./Breather.scss";
import { TextField, Button, Checkbox, Slider } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { VolumeUp, VolumeDown } from "@material-ui/icons";

const BreatherControls = function Controls({
    breather,
    updateConfig,
    audioInhale,
    audioExhale,
    handleInhaleSound,
    handleExhaleSound,
}) {
    const t = useTranslation();
    const [inhaleSecs, setInhaleSecs] = useState(breather?.inhale || 5);
    const [holdinSecs, setHoldinSecs] = useState(breather?.hold || 3);
    const [exhaleSecs, setExhaleSecs] = useState(breather?.exhale || 5);
    const [holdoutSecs, setHoldoutSecs] = useState(breather?.pause || 2);
    const [inhaleSoundFile, setInhaleSoundFile] = useState(null);
    const [exhaleSoundFile, setExhaleSoundFile] = useState(null);
    const [volumeInhale, setVolumeInhale] = useState(50);
    const [volumeExhale, setVolumeExhale] = useState(50);

    function updateInhaleSound(e) {
        const file = e?.target?.files[0] || false;
        handleInhaleSound(file);
        setInhaleSoundFile(file);
    }

    function updateExhaleSound(e) {
        const file = e?.target?.files[0] || false;
        handleExhaleSound(file);
        setExhaleSoundFile(file);
    }

    useEffect(() => {
        return () => {
            audioInhale && audioInhale.pause();
            audioExhale && audioExhale.pause();
        };
    }, []);

    useEffect(() => {
        updateConfig &&
            updateConfig({
                id: breather.id || undefined,
                inhale: inhaleSecs,
                hold: holdinSecs,
                exhale: exhaleSecs,
                pause: holdoutSecs,
                inhale_sound: inhaleSoundFile,
                exhale_sound: exhaleSoundFile,
                hrv: breather.hrv || false,
            });
    }, [inhaleSecs, holdinSecs, exhaleSecs, holdoutSecs, inhaleSoundFile, exhaleSoundFile]);

    useEffect(() => {
        if (audioExhale) {
            audioExhale.volume = volumeExhale / 100;
        }
        if (audioInhale) {
            audioInhale.volume = volumeInhale / 100;
        }
    }, [volumeInhale, volumeExhale, audioExhale, audioInhale]);

    const totalCycleTime = inhaleSecs + holdinSecs + exhaleSecs + holdoutSecs;

    return (
        <div id="controls">
            <div className="breatherSummary">
                Breathing Cycle totals {Math.round(totalCycleTime * 100) / 100} sec (
                {Math.round((1 / totalCycleTime) * 1000) / 1000} Hz)
            </div>
            <div id="breatherControls">
                <div className="breatherControl">
                    <Button
                        variant="contained"
                        onClick={() => setInhaleSecs(Math.round(Math.max(inhaleSecs - 0.1, 1) * 100) / 100)}
                        className="breatherControlButton"
                    >
                        -
                    </Button>
                    <TextField
                        label="Inhale"
                        type="number"
                        variant="outlined"
                        value={inhaleSecs}
                        onChange={(e) =>
                            setInhaleSecs(Math.round(Math.max(Math.min(+e.target.value, 10), 1) * 100) / 100)
                        }
                    />
                    <Button
                        variant="contained"
                        onClick={() => setInhaleSecs(Math.round(Math.min(inhaleSecs + 0.1, 10) * 100) / 100)}
                        className="breatherControlButton"
                    >
                        +
                    </Button>
                </div>
                <div className="breatherControl">
                    <Button
                        type="button"
                        variant="contained"
                        onClick={() => setHoldinSecs(Math.round(Math.max(holdinSecs - 0.1, 0) * 100) / 100)}
                        className="breatherControlButton"
                    >
                        -
                    </Button>
                    <TextField
                        label="Hold"
                        variant="outlined"
                        type="number"
                        value={holdinSecs}
                        onChange={(e) =>
                            setHoldinSecs(Math.round(Math.max(Math.min(+e.target.value, 10), 0) * 100) / 100)
                        }
                    />
                    <Button
                        type="button"
                        variant="contained"
                        onClick={() => setHoldinSecs(Math.round(Math.min(holdinSecs + 0.1, 10) * 100) / 100)}
                        className="breatherControlButton"
                    >
                        +
                    </Button>
                </div>
                <div className="breatherControl">
                    <Button
                        type="button"
                        variant="contained"
                        onClick={() => setExhaleSecs(Math.round(Math.max(exhaleSecs - 0.1, 1) * 100) / 100)}
                        className="breatherControlButton"
                    >
                        -
                    </Button>
                    <TextField
                        label="Exhale"
                        type="number"
                        variant="outlined"
                        value={exhaleSecs}
                        onChange={(e) =>
                            setExhaleSecs(Math.round(Math.max(Math.min(+e.target.value, 10), 1) * 100) / 100)
                        }
                    />
                    <Button
                        type="button"
                        variant="contained"
                        onClick={() => setExhaleSecs(Math.round(Math.min(exhaleSecs + 0.1, 10) * 100) / 100)}
                        className="breatherControlButton"
                    >
                        +
                    </Button>
                </div>
                <div className="breatherControl">
                    <Button
                        type="button"
                        variant="contained"
                        onClick={() => setHoldoutSecs(Math.round(Math.max(holdoutSecs - 0.1, 0) * 100) / 100)}
                        className="breatherControlButton"
                    >
                        -
                    </Button>
                    <TextField
                        label="Pause"
                        type="number"
                        variant="outlined"
                        value={holdoutSecs}
                        onChange={(e) =>
                            setHoldoutSecs(Math.round(Math.max(Math.min(+e.target.value, 10), 0) * 100) / 100)
                        }
                    />
                    <Button
                        type="button"
                        variant="contained"
                        onClick={() => setHoldoutSecs(Math.round(Math.min(holdoutSecs + 0.1, 10) * 100) / 100)}
                        className="breatherControlButton"
                    >
                        +
                    </Button>
                </div>
            </div>
            <div className="breatherSoundButton">
                <Button variant="contained" component="label" color={audioInhale ? "primary" : "default"}>
                    Inhale Sound
                    <input accept="audio/*" type="file" hidden onChange={updateInhaleSound} />
                </Button>
                {audioInhale && (
                    <CloseIcon
                        onClick={() => {
                            updateInhaleSound(false);
                        }}
                    />
                )}
                <div className="breatherSoundVolume">
                    {!volumeInhale ? (
                        <VolumeDown onClick={() => setVolumeInhale(50)} />
                    ) : (
                        <VolumeUp onClick={() => setVolumeInhale(0)} />
                    )}
                    <Slider value={volumeInhale} onChange={(e, newVolume) => setVolumeInhale(newVolume)} />
                </div>
            </div>
            <div className="breatherSoundButton">
                <Button variant="contained" component="label" color={audioExhale ? "primary" : "default"}>
                    Exhale Sound
                    <input accept="audio/*" type="file" hidden onChange={updateExhaleSound} />
                </Button>
                {audioExhale && (
                    <CloseIcon
                        onClick={() => {
                            updateExhaleSound(false);
                        }}
                    />
                )}
                <div className="breatherSoundVolume">
                    {!volumeExhale ? (
                        <VolumeDown onClick={() => setVolumeExhale(50)} />
                    ) : (
                        <VolumeUp onClick={() => setVolumeExhale(0)} />
                    )}
                    <Slider value={volumeExhale} onChange={(e, newVolume) => setVolumeExhale(newVolume)} />
                </div>
            </div>
        </div>
    );
};

export default BreatherControls;
