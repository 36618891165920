import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Select,
    InputLabel,
    MenuItem,
    Snackbar,
    FormControl,
    TextField,
    Box,
    Typography,
    DialogContentText,
} from "@material-ui/core";
import { reduxForm } from "redux-form";
import navigationInfoSelector from "../../selectors/navigationSelector";
import { bindActionCreators } from "redux";
import { actions as followerGroupsActions } from "../../actions/followerGroupsActions";
import { actions as followerActions } from "../../actions/followersActions";
import { push } from "connected-react-router";
import Cookies from "js-cookie";
import MUIRichTextEditor from "mui-rte";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { ContentState, convertFromHTML, convertToRaw } from "draft-js";
import { makeStyles } from "@material-ui/core";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { withTranslation } from "react-multi-lang";

const dialogStyles = makeStyles((theme) => ({
    customDialogTitle: {
        padding: "8px 16px",
    },
    customDialogContentText: {
        marginBottom: "0px",
    },
    customDialogContent: {
        padding: "8px 12px",
    },
}));

const CustomDialogTitle = ({ title }) => {
    const classes = dialogStyles();
    return <DialogTitle className={classes.customDialogTitle}>{title}</DialogTitle>;
};

const CustomDialogContent = ({ children }) => {
    const classes = dialogStyles();
    return <DialogContent className={classes.customDialogContent}>{children}</DialogContent>;
};

const CustomDialogContentText = ({ children }) => {
    const classes = dialogStyles();
    return <DialogContentText className={classes.customDialogContentText}> {children} </DialogContentText>;
};

const editorTheme = createTheme({
    overrides: {
        MUIRichTextEditor: {
            root: {
                width: "100%",
                minWidth: "500px",
            },
            editor: {
                padding: "5px",
                height: "150px",
                maxHeight: "200px",
                overflow: "auto",
                fontSize: "0.75rem",
            },
            toolbar: {
                borderTop: "1px solid gray",
                backgroundColor: "#ebebeb",
            },
            placeHolder: {
                backgroundColor: "#ebebeb",
                paddingLeft: 20,
                width: "inherit",
            },
            anchorLink: {
                color: "#333333",
                textDecoration: "underline",
            },
        },
    },
});
const snackBarReasons = {
    email_sent_successfully: "Email successful",
    email_failed: "Failed to send email",
    copied_to_clipboard: "copied_to_clipboard",
};

class InviteFollowerModal extends Component {
    static propTypes = {
        editMode: PropTypes.bool,
        open: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        const { t } = props;
        this.editorRef = React.createRef();
        this.modifiedEditorContentHTML = null;
        this.handleClose = this.handleClose.bind(this);
        this.copyInvitation = this.copyInvitation.bind(this);
        this.selectInvitation = this.selectInvitation.bind(this);
        this.renderForm = this.renderForm.bind(this);
        this.cfg = {
            title:
                t("invitations.invite_follower") +
                " " +
                (() => (props.follower ? props.follower.name : "Group " + props.followerGroup.group_name))(),
            doneBtn: t("invitations.send_gmail"),
        };
        this.state = {
            invitation: Cookies.get("defaultInvitation")
                ? this.props.invitations.find((x) => x.id === Cookies.get("defaultInvitation"))
                : this.props.invitations[0],
            snackBar: { reason: "", show: false, text: "", severity: "" },
        };
    }
    handleEditorTextChange = (state) => {
        this.modifiedEditorContentHTML = stateToHTML(state.getCurrentContent());
    };
    saveEditorContent = (data) => {
        const editorState = convertFromRaw(JSON.parse(data));
        this.editorConentHTML = stateToHTML(editorState);
        this.editorConentPlainText = editorState.getPlainText();
    };

    followerRegistrationRequired = () => {
        const { followerRegistration } = this.props;
        return followerRegistration && !followerRegistration.completed;
    };
    getFollowerRegistrationLink = () => {
        if (!this.props.followerRegistration) return null;
        const reg_id = this.props.followerRegistration.registration_id;
        return `https://www.psysession.com/registration/${reg_id}`;
    };

    getFollowerRegistrationNotice = (format = "text") => {
        const { t, follower } = this.props;
        if (!follower || !this.followerRegistrationRequired()) return "";
        if (format === "text")
            return t("invitations.use_registration_link") + ": " + this.getFollowerRegistrationLink();
        return (
            "<p>" +
            t("invitations.use_registration_link") +
            ":</p>" +
            "<p>" +
            this.getFollowerRegistrationLink() +
            "</p>"
        );
    };

    getUpdatedEditorContent = () => {
        const selectedHTML = this.modifiedEditorContentHTML
            ? this.modifiedEditorContentHTML
            : this.state.invitation.body;
        return this.htmlToRichTextDefaultValueFormat(selectedHTML);
    };
    htmlToRichTextDefaultValueFormat = (html) => {
        const convertedValue = convertFromHTML(html);
        const valueAsContentState = ContentState.createFromBlockArray(
            convertedValue.contentBlocks,
            convertedValue.entityMap
        );
        const defaultValue = JSON.stringify(convertToRaw(valueAsContentState));
        return defaultValue;
    };

    getOverviewText = () => {
        const { follower, followerGroup, followerRegistration } = this.props;

        if (follower) {
            if (followerRegistration && !followerRegistration.completed)
                // case: follower and requires registration
                return `You can invite ${follower.name} via email. The email will be sent by Psysession to ${follower.user.email}. ${follower.name} is required to register. A registration link will be added automatomatically at the end of the email body`;
            //case: follower and already registered user (by another leader)
            return `You can invite ${follower.name} via email. The email will be sent by Psysession to ${follower.user.email}`;
        }
        return `You can invite all members of group ${followerGroup.group_name} via email by filling the email subject and body and clicking the Send Email button. Please note that a registration link will be added at the end of the body for each member as required`;
    };
    handleCloseSnackBar = () => {
        if (this.state.snackBar.reason === snackBarReasons.email_sent_successfully) {
            this.handleClose();
        } else {
            let newState = this.state;
            newState.snackBar.show = false;
            this.setState(newState);
        }
    };
    handleSendEmailResult = ({ success }) => {
        const { t } = this.props;
        let newState = this.state;
        newState.invitation.show = true;
        if (success) {
            newState.snackBar.reason = snackBarReasons.email_sent_successfully;
            newState.snackBar.text = t("invitations.email_sent_successfully");
            newState.snackBar.severity = "success";
            newState.snackBar.show = true;
        } else {
            newState.snackBar.reason = snackBarReasons.email_failed;
            newState.snackBar.text = t("invitations.email_failed");
            newState.snackBar.severity = "error";
        }
        this.setState(newState);
    };

    componentDidMount() {
        if (this.props.followerGroup) this.props.fetchFollowerGroupMembers(this.props.followerGroup.id);
    }

    handleClose() {
        this.props.onClose();
    }
    handleSubjectChange = (e) => {
        const newInvitation = { ...this.state.invitation, subject: e.target.value };
        this.setState({ ...this.state, invitation: newInvitation });
    };

    handleSubmit = () => {
        const { follower, followerGroup } = this.props;
        const { subject, body } = this.state.invitation;
        if (follower) {
            const finalEmailBody = body + this.getFollowerRegistrationNotice("html");
            this.props.sendInvitationEmail({
                followerId: follower.id,
                emailSubject: subject,
                emailBody: finalEmailBody,
                onSendResult: this.handleSendEmailResult,
            });
        } else {
            this.props.sendInvitationEmailToMembers({
                groupId: followerGroup.id,
                emailSubject: subject,
                emailBody: body,
                onSendResult: this.handleSendEmailResult,
            });
        }
    };

    copyInvitation() {
        if (this.editorRef.current) {
            this.editorRef.current.save();
        }

        if (navigator) {
            const cbdItem = new ClipboardItem({
                ["text/html"]: new Blob(
                    [this.editorConentHTML + "<p>" + this.getFollowerRegistrationNotice() + "</p>"],
                    { type: "text/html" }
                ),
                ["text/plain"]: new Blob([this.editorConentPlainText + "\n" + this.getFollowerRegistrationNotice()], {
                    type: "text/plain",
                }),
            });
            navigator.clipboard.write([cbdItem]).then(
                () => {
                    const { t } = this.props;
                    let newState = this.state;
                    newState.snackBar.reason = snackBarReasons.copied_to_clipboard;
                    newState.snackBar.show = true;
                    newState.snackBar.text = t("invitations.copied_invitation");
                    this.setState(newState);
                },
                (err) => {
                    window.prompt(
                        "Copy to clipboard: Ctrl+C, Enter",
                        this.editorConentPlainText + "\n" + this.getFollowerRegistrationNotice()
                    );
                }
            );
        } else {
            window.prompt(
                "Copy to clipboard: Ctrl+C, Enter",
                this.editorConentPlainText + "\n" + this.getFollowerRegistrationNotice()
            );
        }
    }

    selectInvitation(event) {
        this.modifiedEditorContentHTML = null;
        this.setState({ invitation: this.props.invitations.find((x) => x.id === event.target.value) });
    }

    renderForm() {
        const { t } = this.props;
        return (
            <form autoComplete="off">
                {!this.props.editMode && (
                    <div>
                        {this.props.invitations.length > 0 && (
                            <Grid container direction="column" id="inviteGmail">
                                <Grid item>
                                    <FormControl>
                                        <InputLabel id="select_invitation-label">
                                            {t("invitations.invitation_template")}
                                        </InputLabel>
                                        <Select
                                            id="select_invitation"
                                            labelId="select_invitation-label"
                                            defaultValue={
                                                Cookies.get("defaultInvitation")
                                                    ? Cookies.get("defaultInvitation")
                                                    : this.props.invitations[0].id
                                            }
                                            style={{ width: "100%", marginBottom: "10px" }}
                                            onChange={this.selectInvitation}
                                        >
                                            {this.props.invitations.map((invitation, i) => (
                                                <MenuItem value={invitation.id} key={i}>
                                                    {invitation.title ? invitation.title : invitation.subject}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <Box border={2} p={2}>
                                        <Grid container direction="column">
                                            <Grid item style={{ marginBottom: "20px" }}>
                                                <div>
                                                    <InputLabel>{t("invitations.email_subject") + ":"}</InputLabel>
                                                    <TextField
                                                        id="email_subject"
                                                        variant="standard"
                                                        style={{ width: "100%" }}
                                                        inputProps={{ style: { fontSize: "0.75rem" } }}
                                                        value={this.state.invitation.subject}
                                                        onChange={this.handleSubjectChange}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item>
                                                <div>
                                                    <div>
                                                        <InputLabel>{t("invitations.email_body") + ":"}</InputLabel>
                                                        <MuiThemeProvider theme={editorTheme}>
                                                            <MUIRichTextEditor
                                                                ref={this.editorRef}
                                                                onSave={this.saveEditorContent}
                                                                controls={[
                                                                    "title",
                                                                    "bold",
                                                                    "italic",
                                                                    "underline",
                                                                    "strikethroug",
                                                                ]}
                                                                toolbarButtonSize="small"
                                                                onChange={this.handleEditorTextChange}
                                                                defaultValue={this.getUpdatedEditorContent()}
                                                            />
                                                        </MuiThemeProvider>
                                                    </div>
                                                </div>
                                            </Grid>
                                            {this.props.follower && this.followerRegistrationRequired() && (
                                                <Grid item>
                                                    <Box
                                                        sx={{
                                                            backgroundColor: "#e0e0e0",
                                                            padding: "2px",
                                                            color: "rgba(0, 0, 0, 0.87)",
                                                            borderTop: "1px solid gray",
                                                            marginTop: "1px",
                                                        }}
                                                    >
                                                        <Typography variant="caption" display="block">
                                                            {t("invitations.registration_link_append_message") + ":"}
                                                        </Typography>
                                                        <Typography variant="caption" display="block">
                                                            {this.getFollowerRegistrationNotice()}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        )}
                    </div>
                )}
            </form>
        );
    }

    render() {
        const { t } = this.props;

        return (
            <Dialog open={this.props.open} onClose={this.props.onClose}>
                <CustomDialogTitle title={this.cfg.title}></CustomDialogTitle>
                <CustomDialogContent>
                    <CustomDialogContentText>{this.getOverviewText()}</CustomDialogContentText>
                </CustomDialogContent>
                <CustomDialogContent>{this.renderForm()}</CustomDialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose}>Cancel</Button>
                    <Button component="span" variant="contained" onClick={this.copyInvitation}>
                        {t("invitations.copy_invitation")}
                    </Button>
                    <Snackbar
                        open={this.state.snackBar.show}
                        onClose={this.handleCloseSnackBar}
                        autoHideDuration={1000}
                        message={this.state.snackBar.text}
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    />
                    <Button
                        onClick={this.handleSubmit}
                        color="primary"
                        variant="contained"
                        className="create-follower-btn"
                    >
                        {"Send Email"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

let Form = reduxForm({
    form: "inviteFollower",
})(InviteFollowerModal);

function mapStateToProps(state) {
    const {
        modalProps: { followerId, followerGroupId },
    } = navigationInfoSelector(state);

    const follower = followerId ? _.find(state.followers.followers, (f) => f.id === followerId) : null;
    const followers = state.followers.followers;
    const invitations = state.invitations.invitations;

    const followerGroup = _.find(state.followerGroups.followerGroups, (fg) => fg.id === followerGroupId);
    const followerRegistration = followerId
        ? _.find(state.followers.followerRegistrations, (entry) => entry.follower_id === followerId)
        : null;
    const members = _.get(followerGroup, "members");

    return {
        follower,
        followers,
        followerGroup,
        members,
        invitations,
        followerRegistration,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchFollowerGroupMembers: followerGroupsActions.fetchFollowerGroupMembers,
            sendInvitationEmail: followerActions.sendInvitationEmail,
            sendInvitationEmailToMembers: followerGroupsActions.sendInvitationEmailToMembers,
            push,
        },
        dispatch
    );
}

export default withTranslation(connect(mapStateToProps, mapDispatchToProps)(Form));
