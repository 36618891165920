import React from 'react';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    loader: {
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        right:0,
        top:0,
        bottom:0,
        backgroundColor: theme.palette.background.paper,
        zIndex: 1001,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        '&:before': {
            content: '""',
            borderRadius: "50%",
            width: "10em",
            height: "10em",
            border: "6px inset " + theme.palette.primary.light,
            animation: "$spinLoader 1s infinite",
        }
    },
    '@keyframes spinLoader': {
        '0%': {
            transform: 'rotate(0deg)',
        },
        '100%': {
            transform: 'rotate(360deg)',
        }
    }
}));

export default function Loader({ open }) {
    const classes = useStyles();

    return (open && <div className={classes.loader}/>)
}
