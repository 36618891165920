import React, { useState, useEffect } from "react";
import { Typography, makeStyles } from "@material-ui/core";

import api from "../api";
import ActivityList from "./ActivityList";
import LoadingOverlay from "./followers/LoadingOverlay";

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles((theme) => ({
    activitiesSubheader: {
        marginBottom: theme.spacing(2),
    },
}));

export default function ActivityLog() {
    const t = useTranslation();
    const [activities, setActivities] = useState([]);
    const [loading, setLoading] = useState(true);

    const classes = useStyles();

    useEffect(() => {
        setLoading(true);
        api.get(`/leader_clinics/activity_log`)
            .then((data) => {
                setActivities(activities.concat(data.activities));
            })
            .finally(() => setLoading(false));
    }, []);

    return loading ? (<LoadingOverlay loading={loading} />) : (
        <>
            <ActivityList
                paperProps={{ elevation: 6 }}
                activities={activities}
                subheader={<Typography variant="h6" className={classes.activitiesSubheader}>{t('activity.activity_log')}</Typography>}
            />
        </>
    );
}
