import { put, select } from "redux-saga/effects";
import { actions, types } from "../actions/DeveloperPageActions";
import SagaReducerFactory from "../saga-reducers-factory-patch";

let { handle, updateState, saga, reducer } = SagaReducerFactory({
    actionTypes: types,
    actionCreators: actions,
    initState: {
        devlog: [],
    },
});

handle(types.TEST, function* (sagaParams, { payload }) {
    switch (payload.testName) {
        case "getSubscription":
            const registration = yield navigator?.serviceWorker?.ready;
            let subscription = yield registration?.pushManager?.getSubscription();
            if (subscription === null) subscription = "null";
            yield appendToDevLog("subscription is: " + JSON.stringify(subscription));
            break;
        case "getIphoneNotificationsEnabled":
            const enabled = yield select((state) => state.notifications.iPhoneNotificationsEnabled);
            yield appendToDevLog("iPhoneNotificationsEnabled: " + enabled);
            break;
        case "addlog":
            yield appendToDevLog(payload.logString);
            break;
        default:
            console.log("unsupported tes name");
    }
});

function* appendToDevLog(text) {
    const currentLog = yield select((state) => state.developer.devlog);
    const now = new Date();
    yield put(updateState({ devlog: [...currentLog, { date: now.toString(), text: text }] }));
}
export default { saga, reducer };
