import SagaReducerFactory from "../saga-reducers-factory-patch";
import { put } from "redux-saga/effects";
import { actions, types } from "../actions/registrationActions";
//import formErrorAction from "../actions/formError";
import * as registrationApi from "../api/registration";
import { call } from "redux-saga/effects";
import { handleLoginResponse } from "./sessionSaga";

let { handle, updateState, saga, reducer } = SagaReducerFactory({
    actionTypes: types,
    actionCreators: actions,
    initState: {
        clientName: null,
        clientEmail: null,
        leaderName: null,
        registrationError: null,
    },
});

handle(types.REGISTER_BY_CODE, function* (sagaParams, { payload }) {
    const { regid, code, password } = payload;

    const registrationResult = yield call(registrationApi.registerUserUsingCode, regid, code, password);
    if (registrationResult.error) {
        yield put(updateState({ registrationError: registrationResult.error }));
    } else {
        yield handleLoginResponse(registrationResult);
    }
});

handle(types.GET_REGISTRATION_DETAILS, function* (sagaParams, { payload }) {
    const { regid } = payload;
    const registrationDetails = yield call(registrationApi.get, regid);
    const { follower_name, follower_email, leader_name } = registrationDetails;
    yield put(updateState({ clientName: follower_name, clientEmail: follower_email, leaderName: leader_name }));
});

handle(types.SEND_CODE_BY_EMAIL, function* (sagaParams, { payload }) {
    const { regid } = payload;
    const sendResult = yield call(registrationApi.sendCodeByEmail, regid);
    if (sendResult.error) {
        yield put(updateState({ registrationError: "Failed to send code by email" }));
    }
});

handle(types.REGENERATE_CODE, function* (sagaParams, { payload }) {
    const { regid } = payload;
    const result = yield call(registrationApi.regenerateCode, regid);
    payload.onResendResult(result);
});
export default { saga, reducer };
