import _ from "lodash";
import React, { Component } from "react";
import { actions } from "../actions/followersActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions as navigationActions } from "../actions/navigationActions";
import followersSelector from "../selectors/followersSelector";
import { MenuItem, Select, InputLabel, Link as MuiLink, Typography } from "@material-ui/core";
import {
    PlayCircleFilled,
    PauseCircleFilled,
    ContactMail,
    Edit,
    Delete,
    ArrowUpward,
    ArrowDownward,
} from "@material-ui/icons";
import EntityTable from "./EntityTable";
import { rowAction } from "./RowActions";
import { Link } from "react-router-dom";
import OverflowMenu from "./OverflowMenu";
import Filter from "components/Filter";
import { InlineIcon } from "@iconify/react";
import googleDrive from "@iconify-icons/mdi/google-drive";
import { withTranslation } from "react-multi-lang";

class Followers extends Component {
    constructor() {
        super();

        this.state = {
            showEmails: false,
            openedOverflowMenuFollowerId: null,
            filterText: "",
        };

        this.handleClinicFilterChange = this.handleClinicFilterChange.bind(this);
        this.handleSelectFollower = this.handleSelectFollower.bind(this);
        this.handleEditFollower = this.handleEditFollower.bind(this);
        this.handleRemoveFollower = this.handleRemoveFollower.bind(this);
        this.handleAddFollower = this.handleAddFollower.bind(this);
        this.handleFreezeFollower = this.handleFreezeFollower.bind(this);
        this.handleInviteFollower = this.handleInviteFollower.bind(this);
    }

    componentDidMount() {
        this.props.fetchFollowers();
    }

    handleClinicFilterChange(ev, idx, value) {
        this.props.filterByClinicId(value);
    }

    handleSelectFollower(followerId) {
        this.props.selectFollower(followerId);
    }

    handleAddFollower() {
        this.props.showCreateFollowerModal();
    }

    handleEditFollower(id) {
        this.props.showEditFollowerModal(id);
    }

    handleRemoveFollower(id) {
        this.props.showDeleteFollowerPrompt(id);
    }

    handleFreezeFollower(id) {
        this.props.showFreezeFollowerPrompt(id);
    }

    handleInviteFollower(id) {
        this.props.showInvitationModal(id);
    }

    toggleOverflowMenu(followerId) {
        if (!followerId && !this.state.openedOverflowMenuFollowerId) return; //prevent stack overflow

        this.setState({
            openedOverflowMenuFollowerId: followerId,
        });
    }

    toggleShowEmails() {
        this.setState({ showEmails: !this.state.showEmails });
    }

    canFollowerEdit(followerId) {
        const follower = this.props.getFollower(followerId);
        return !follower.is_system;
    }

    canFollowerDelete(followerId) {
        const follower = this.props.getFollower(followerId);
        return !follower.is_system || this.props.isAdmin;
    }

    renderClinicFilter() {
        return [
            <InputLabel id="clinic-filter-label">Clinic</InputLabel>,
            <Select
                labelId="clinic-filter-label"
                value={this.props.clinicIdFilter}
                onChange={this.handleClinicFilterChange}
            >
                <MenuItem value="all">All</MenuItem>
                {this.props.clinics.map((clinic, i) => (
                    <MenuItem value={clinic.id} key={i}>
                        {clinic.name}
                    </MenuItem>
                ))}
            </Select>,
        ];
    }

    getHeader() {
        const { t } = this.props;
        const header = [];
        const groupsList = [
            "SYSTEM",
            "NO GROUP",
            ..._.reduce(
                this.props.followerGroups.filter((group) => group.memberCount > 0),
                (acc, group) => {
                    acc.push(group.group_name);
                    return acc;
                },
                []
            ),
        ];
        header.push(
            <div className="link" onClick={this.props.toggleAlphabetSort.bind(this)}>
                Name {this.props.alphabetSort ? <ArrowUpward /> : <ArrowDownward />}
            </div>
        );
        this.state.showEmails && header.push("Email");
        header.push(
            <Filter
                name={t("followers.groups")}
                list={groupsList}
                checkedList={this.props.groupsFilter || []}
                toggleItem={this.props.toggleFilterGroups.bind(this)}
                toggleAllItems={this.props.toggleAllFilterGroups.bind(this)}
            />
        );
        header.push(
            this.props.tags.length > 0 ? (
                <Filter
                    name={t("followers.tags")}
                    list={[t("followers.no_tags"), ...this.props.tags]}
                    checkedList={this.props.tagsFilter || []}
                    toggleItem={this.props.toggleFilterTags.bind(this)}
                    toggleAllItems={this.props.toggleAllFilterTags.bind(this)}
                />
            ) : (
                "Tags"
            )
        );
        return header;
    }

    checkFollowerFilter = (f) => {
        const ft = this.state.filterText;

        const cond =
            f.name.toLowerCase().includes(ft) ||
            JSON.stringify(f.tags)?.toLowerCase().includes(ft) ||
            (f.is_system && String("system").includes(ft)) ||
            this.props.followerGroups.filter((g) => f.groups.includes(g.id) && g.group_name?.toLowerCase().includes(ft))
                .length > 0;
        return cond;
    };
    getRows() {
        const { t } = this.props;
        !this.props.alphabetSort
            ? this.props.systemFollowers.sort((a, b) => a.name.localeCompare(b.name))
            : this.props.systemFollowers.sort((a, b) => b.name.localeCompare(a.name));
        !this.props.alphabetSort
            ? this.props.followers.sort((a, b) => a.name.localeCompare(b.name))
            : this.props.followers.sort((a, b) => b.name.localeCompare(a.name));
        const systemFollowers = _.reduce(
            this.props.systemFollowers
                .filter(
                    (systemFollower) =>
                        !this.props.groupsFilter?.length > 0 || this.props.groupsFilter.includes("SYSTEM")
                )
                .filter(
                    (follower) =>
                        !this.props.tagsFilter?.length > 0 ||
                        follower.tags?.split(";").some((item) => this.props.tagsFilter?.includes(item.trim())) ||
                        (this.props.tagsFilter?.includes(t("followers.no_tags")) && !follower.tags?.length)
                )
                .filter((f) => !this.state.filterText || this.checkFollowerFilter(f)),
            (acc, f) => {
                let cols = [];
                cols.push(
                    <MuiLink
                        to={`/followers/${f.id}/resonators`}
                        component={Link}
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <span>{f.name === "Generic Follower" ? t("system." + f.name) : f.name}</span>
                    </MuiLink>
                );
                cols.push(
                    <div className="followerGroupsTag">
                        <span
                            className={
                                this.props.groupsFilter?.includes("SYSTEM")
                                    ? "followerGroupTag active"
                                    : "followerGroupTag"
                            }
                            onClick={() => this.props.toggleFilterGroups("SYSTEM")}
                        >
                            SYSTEM
                        </span>
                    </div>
                );
                cols.push(
                    <div style={{ textAlign: "center" }}>
                        {f.tags?.split(";").map((tag) => {
                            if (!tag.trim()) return false;
                            return (
                                <span
                                    className={
                                        this.props.tagsFilter?.includes(tag.trim())
                                            ? "followerTag active"
                                            : "followerTag"
                                    }
                                    onClick={() => this.props.toggleFilterTags(tag.trim())}
                                >
                                    {tag};
                                </span>
                            );
                        })}
                    </div>
                );
                acc[f.id] = cols;
                return acc;
            },
            {}
        );

        const followers = _.reduce(
            this.props.followers
                .filter(
                    (follower) =>
                        !this.props.groupsFilter?.length > 0 ||
                        follower.groups?.some((group) =>
                            this.props.groupsFilter?.includes(
                                this.props.followerGroups.find((g) => g.id === group)?.group_name
                            )
                        ) ||
                        (!follower.groups.length && this.props.groupsFilter?.includes("NO GROUP"))
                )
                .filter(
                    (follower) =>
                        !this.props.tagsFilter?.length > 0 ||
                        follower.tags?.split(";").some((item) => this.props.tagsFilter?.includes(item.trim())) ||
                        (this.props.tagsFilter?.includes(t("followers.no_tags")) && !follower.tags?.length)
                )
                .filter((f) => !this.state.filterText || this.checkFollowerFilter(f)),
            (acc, f) => {
                let cols = [];
                cols.push(
                    <>
                        <MuiLink
                            to={`/followers/${f.id}/resonators`}
                            component={Link}
                            style={{
                                color: f.frozen ? "rgb(157, 155, 155)" : "",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {f.frozen ? <PauseCircleFilled fontSize="small" style={{ marginRight: 5 }} /> : null}
                            <span>{f.name}</span>
                        </MuiLink>
                        {f.gdrive_link && (
                            <MuiLink href={f.gdrive_link} target="_blank">
                                <InlineIcon icon={googleDrive} />
                            </MuiLink>
                        )}
                    </>
                );
                this.state.showEmails && cols.push(f.user.email);
                cols.push(
                    <div className="followerGroupsTag">
                        {f.groups.map((groupId) => {
                            const group = this.props.followerGroups.find((g) => g.id === groupId);
                            let groupName = group?.group_name;
                            if (!groupName) return false;

                            return (
                                <span
                                    key={groupId}
                                    className={
                                        this.props.groupsFilter?.includes(groupName)
                                            ? "followerGroupTag active"
                                            : "followerGroupTag"
                                    }
                                    onClick={() => this.props.toggleFilterGroups(groupName)}
                                >
                                    {groupName};
                                </span>
                            );
                        })}
                    </div>
                );
                cols.push(
                    <div style={{ textAlign: "center" }}>
                        {f.tags?.split(";").map((tag) => {
                            if (!tag.trim()) return false;
                            return (
                                <span
                                    className={
                                        this.props.tagsFilter?.includes(tag.trim())
                                            ? "followerTag active"
                                            : "followerTag"
                                    }
                                    onClick={() => this.props.toggleFilterTags(tag.trim())}
                                >
                                    {tag};
                                </span>
                            );
                        })}
                    </div>
                );
                acc[f.id] = cols;
                return acc;
            },
            {}
        );

        return { ...systemFollowers, ...followers };
    }

    getToolbox() {
        const { t } = this.props;

        return {
            left: <Typography variant="h6">{t("followers.header")}</Typography>,
            right: (
                <OverflowMenu keepOpen>
                    <MenuItem onClick={() => this.toggleShowEmails()}>
                        {this.state.showEmails ? t("followers.hide_emails") : t("followers.show_emails")}
                    </MenuItem>
                    <MenuItem onClick={() => this.props.toggleDisplayFrozen()}>
                        {this.props.displayFrozen ? t("followers.hide_deactivated") : t("followers.show_deactivated")}
                    </MenuItem>
                </OverflowMenu>
            ),
        };
    }

    renderOverflowMenu() {
        const { t } = this.props;

        return (followerId) => {
            const follower = this.props.getFollower(followerId);
            return (
                <OverflowMenu key="more-options">
                    {follower.frozen ? (
                        <MenuItem onClick={() => this.props.unfreezeFollower(followerId)}>
                            {t("followers.activate")}
                        </MenuItem>
                    ) : (
                        <MenuItem onClick={() => this.handleFreezeFollower(followerId)}>
                            {t("followers.deactivate")}
                        </MenuItem>
                    )}
                </OverflowMenu>
            );
        };
    }

    getRowActions() {
        const { t } = this.props;

        return [
            rowAction({
                title: t("followers.edit"),
                icon: <Edit />,
                onClick: this.handleEditFollower,
                isAvailable: (followerId) => this.canFollowerEdit(followerId),
            }),
            rowAction({
                title: t("followers.remove"),
                icon: <Delete />,
                onClick: this.handleRemoveFollower,
                isAvailable: (followerId) => this.canFollowerDelete(followerId),
            }),
        ];
    }

    getExtraRowActions() {
        const { t } = this.props;

        return [
            rowAction({
                title: t("invitations.invite_follower"),
                icon: <ContactMail />,
                onClick: this.handleInviteFollower,
                isAvailable: (followerId) => this.props.invitationsLength > 0 && this.canFollowerEdit(followerId),
            }),
            rowAction({
                title: t("followers.activate"),
                icon: <PlayCircleFilled />,
                onClick: this.props.unfreezeFollower,
                isAvailable: (followerId) =>
                    this.props.getFollower(followerId).frozen && this.canFollowerEdit(followerId),
            }),
            rowAction({
                title: t("followers.deactivate"),
                icon: <PauseCircleFilled />,
                onClick: this.handleFreezeFollower,
                isAvailable: (followerId) =>
                    !this.props.getFollower(followerId).frozen && this.canFollowerEdit(followerId),
            }),
        ];
    }

    handleFilter = (filterText) => {
        this.setState({ ...this.state, filterText: filterText.toLowerCase() });
    };

    render() {
        const { t } = this.props;

        return (
            <EntityTable
                addButton={true}
                rows={this.getRows()}
                header={this.getHeader()}
                toolbox={this.getToolbox()}
                rowActions={this.getRowActions()}
                extraRowActions={this.getExtraRowActions()}
                onAdd={this.handleAddFollower}
                className="followers"
                addText={t("followers.add")}
                filterText={this.state.filterText}
                onFilter={this.handleFilter}
            />
        );
    }
}

function mapStateToProps(state) {
    let followersData = followersSelector(state);
    const systemFollowers = state.followers.systemFollowers;
    const invitationsLength = state.invitations.invitations.length;
    const isAdmin = state.leaders.leaders.admin_permissions;
    const followerGroups = state.followerGroups.followerGroups;
    const tags = _.reduce(
        followersData.followers,
        (acc, follower) => {
            follower.tags?.split(";").forEach((tag) => {
                if (!acc.includes(tag.trim()) && tag.trim() !== "") acc.push(tag.trim());
            });
            return acc;
        },
        []
    ).sort();

    return {
        ...followersData,
        getFollower: (followerId) =>
            _.find(followersData.followers, (f) => f.id === followerId) ||
            _.find(systemFollowers, (f) => f.id === followerId),
        followerGroups,
        invitationsLength,
        systemFollowers,
        isAdmin,
        tags,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchFollowers: actions.fetch,
            editFollower: actions.edit,
            toggleFilterGroups: actions.filterGroups,
            toggleAllFilterGroups: actions.filterGroupsAll,
            unfreezeFollower: actions.unfreeze,
            filterByClinicId: actions.filterByClinicId,
            toggleDisplayFrozen: actions.toggleDisplayFrozen,
            toggleAlphabetSort: actions.alphabetSort,
            toggleFilterTags: actions.filterTags,
            toggleAllFilterTags: actions.filterTagsAll,
            showEditFollowerModal: (followerId) =>
                navigationActions.showModal({
                    name: "editFollower",
                    props: {
                        followerId,
                        editMode: true,
                    },
                }),
            showCreateFollowerModal: () =>
                navigationActions.showModal({
                    name: "editFollower",
                    props: {
                        editMode: false,
                    },
                }),
            showDeleteFollowerPrompt: (followerId) =>
                navigationActions.showModal({
                    name: "deleteFollower",
                    props: {
                        followerId,
                    },
                }),
            showFreezeFollowerPrompt: (followerId) =>
                navigationActions.showModal({
                    name: "freezeFollower",
                    props: {
                        followerId,
                    },
                }),
            showInvitationModal: (followerId) =>
                navigationActions.showModal({
                    name: "inviteFollower",
                    props: {
                        followerId,
                    },
                }),
            selectFollower: actions.selectFollower,
        },
        dispatch
    );
}

export default withTranslation(connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(Followers));
