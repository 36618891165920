import _ from "lodash";
import { connect } from "react-redux";
import React, { Component } from "react";
import { actions as statsActions } from "../actions/resonatorStatsActions";
import { actions as followerActions } from "../actions/followersActions";
import { actions as followerGroupsActions } from "../actions/followerGroupsActions";
import { bindActionCreators } from "redux";
import resonatorsSelector from "../selectors/resonatorsSelector";
import followersSelector from "../selectors/followersSelector";
import followerGroupsSelector from "../selectors/followerGroupsSelector";
import ExpandableCard from "./ExpandableCard";
import ResonatorStats from "./ResonatorStats";
import ResonatorHRVReadings from "./ResonatorHRVReadings";
import {
    CircularProgress,
    Typography,
    Divider,
    IconButton,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    withWidth,
} from "@material-ui/core";
import { RemoveRedEye, GetApp, ChevronRightRounded, ChevronLeftRounded, Edit } from "@material-ui/icons";
import { isMobile } from "./utils";
import * as utils from "components/utils";
import { push } from "connected-react-router";
import { withTranslation } from "react-multi-lang";
import HRV from "components/apps/HRV";

class ShowResonator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            iframeWidth: 0,
            iframeHeight: 0,
            expanded: false,
            hrv_readings: [],
        };

        this.handleIframeLoad = this.handleIframeLoad.bind(this);
        this.handleMemberChange = this.handleMemberChange.bind(this);
        this.handleMemberArrowClick = this.handleMemberArrowClick.bind(this);
    }

    onHRV_Readings = (hrv_readings) => {
        this.setState({ hrv_readings });
    };

    componentDidMount() {
        if (this.props.followerGroup) {
            this.props.fetchMembersWithResonatorChildren({
                followerGroupId: this.props.followerGroup.id,
                resonatorId: this.props.resonator.id,
            });
            this.props.followerGroup.members &&
                this.setState({ member: this.props.followerGroup.members[0], memberIndex: 0 });
        } else {
            this.props.fetchFollowerResonators(this.props.match.params.followerId);
            this.props.fetchResonatorHrvReadings({
                followerId: this.props.match.params.followerId,
                resonatorId: this.props.match.params.resonatorId,
                saveHRV_Readings: this.onHRV_Readings,
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.followerGroup, this.props.followerGroup)) {
            this.setState({ member: this.props.followerGroup.members?.[0], memberIndex: 0 });
        }
        if (prevProps.childResonatorCount !== this.props.childResonatorCount) {
            if (this.props.followerGroup.members) {
                const childResonator = this.props.getChildResonator(
                    this.props.followerGroup.members[this.state.memberIndex].id
                );
                const rid = childResonator?.id;
                if (rid)
                    this.props.fetchResonatorHrvReadings({
                        followerId: this.props.followerGroup.members[this.state.memberIndex].id,
                        resonatorId: rid,
                        saveHRV_Readings: this.onHRV_Readings,
                    });
            }
        }
    }

    handleIframeLoad(ev) {
        let { scrollWidth, scrollHeight } = ev.target.contentWindow.document.body;

        this.setState({
            iframeWidth: scrollWidth * 1.04,
            iframeHeight: scrollHeight + 21,
        });
    }

    renderSectionTitle({ title, bottomLeftActions, bottomRightActions, rightActions }) {
        return (
            <div style={{ marginBottom: 20 }}>
                <div key="titlewrapper" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div key="title">
                        <Typography variant="h5" align="center" noWrap style={{ direction: utils.getDir(title) }}>
                            {title}
                        </Typography>
                    </div>
                    <div key="rightactions">{rightActions}</div>
                </div>
                <Divider key="d" style={{ margin: 10 }} />
                <div key="bottomactions" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div key="bla">{bottomLeftActions}</div>
                    <div key="bra">{bottomRightActions}</div>
                </div>
            </div>
        );
    }

    renderDownloadButton(resonatorId) {
        return (
            <Tooltip title="Download CSV">
                <IconButton onClick={() => this.props.downloadResonatorStats({ resonatorId })}>
                    <GetApp />
                </IconButton>
            </Tooltip>
        );
    }

    renderEditButton(resonatorId) {
        return (
            <Tooltip title="Edit">
                <IconButton
                    onClick={() =>
                        this.props.push(
                            `/followers/${this.props.match.params.followerId}/resonators/${resonatorId}/edit`
                        )
                    }
                >
                    <Edit />
                </IconButton>
            </Tooltip>
        );
    }

    renderNextMemberArrow() {
        const { memberIndex } = this.state;
        return (
            <IconButton
                onClick={() =>
                    this.handleMemberArrowClick(() => (memberIndex + 1) % this.props.followerGroup.members.length)
                }
            >
                <Tooltip title="Next">
                    <ChevronRightRounded />
                </Tooltip>
            </IconButton>
        );
    }

    renderPrevMemberArrow() {
        const { memberIndex } = this.state;
        return (
            <IconButton
                onClick={() =>
                    this.handleMemberArrowClick(() =>
                        memberIndex - 1 < 0 ? this.props.followerGroup.members.length - 1 : memberIndex - 1
                    )
                }
            >
                <Tooltip title="Previous">
                    <ChevronLeftRounded />
                </Tooltip>
            </IconButton>
        );
    }

    renderMemberSelectInput() {
        const { member, memberIndex } = this.state;
        const { getFollower, followerGroup } = this.props;
        return (
            <FormControl variant="outlined">
                <InputLabel id="memberSelectLabel">Member</InputLabel>
                <Select
                    labelId="memberSelectLabel"
                    value={member}
                    onChange={this.handleMemberChange}
                    label="Member"
                    style={{
                        minWidth: "15vw",
                        width: "30vw",
                    }}
                    IconComponent={() => (
                        <Typography className="MuiSelect-icon MuiSelect-iconOutlined" variant="subtitle1">
                            {memberIndex + 1}/{followerGroup.members.length}
                        </Typography>
                    )}
                    renderValue={() =>
                        _.truncate(getFollower(member.id).name, {
                            length: isMobile(this.props.width) ? 15 : 30,
                        })
                    }
                >
                    {followerGroup.members.map((member) => (
                        <MenuItem key={member.id} value={member}>
                            {getFollower(member.id).name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }

    renderMemberSelect() {
        return (
            <div style={{ display: "flex" }}>
                {this.renderPrevMemberArrow()}
                {this.renderMemberSelectInput()}
                {this.renderNextMemberArrow()}
            </div>
        );
    }

    handleMemberChange(event) {
        this.setState({
            member: event.target.value,
            memberIndex: _.findIndex(this.props.followerGroup.members, (member) =>
                _.isEqual(member, event.target.value)
            ),
        });
    }

    handleMemberArrowClick(findIndex) {
        const { followerGroup } = this.props;
        const newIndex = findIndex();
        this.setState({
            member: followerGroup.members[newIndex],
            memberIndex: newIndex,
        });
        const rid = this.props.getChildResonator(followerGroup.members[newIndex].id)?.id;
        if (rid)
            this.props.fetchResonatorHrvReadings({
                followerId: followerGroup.members[newIndex].id,
                resonatorId: rid,
                saveHRV_Readings: this.onHRV_Readings,
            });
    }

    render() {
        const { t } = this.props;
        const { follower, followerGroup, getFollower, resonator, getChildResonator } = this.props;
        if (!_.get(this.props, "match.params.resonatorId")) return null;

        if (!resonator) return null;

        const resonatorId = this.props.match.params.resonatorId;

        return (
            <div>
                {this.renderSectionTitle({
                    title: resonator.title,
                    rightActions: this.renderEditButton(this.props.resonator.id),
                })}
                <div>
                    <ExpandableCard
                        onExpandChange={(expanded) => {
                            if (!expanded) {
                                this.setState({
                                    iframeWidth: 0,
                                    iframeHeight: 0,
                                    expanded: expanded,
                                });
                            } else {
                                this.setState({
                                    expanded: expanded,
                                });
                            }
                        }}
                        width={this.state.iframeWidth || 497}
                        height={this.state.iframeHeight || 60}
                        id={`resonatorPreview-${resonatorId}`}
                        defaultCardData={{
                            expanded: _.size(resonator.questions) === 0,
                        }}
                        title={t("resonators.resonator") + " Preview"}
                        avatar={<RemoveRedEye />}
                    >
                        <div style={{ height: this.state.iframeHeight, overflow: "auto" }}>
                            {!this.state.expanded && (
                                <CircularProgress style={{ margin: "0 auto", display: "block" }} />
                            )}
                            {this.state.expanded && (
                                <iframe
                                    onLoad={this.handleIframeLoad}
                                    style={{ border: 0, height: "100%", width: "100%" }}
                                    src={`/api/reminders/${resonatorId}/render`}
                                />
                            )}
                            {this.state.expanded && resonator.breather && (
                                <HRV resonatorId={resonator.id} breather={resonator.breather} />
                            )}
                        </div>
                    </ExpandableCard>
                </div>
                {_.size(resonator.questions) > 0 && (
                    <React.Fragment>
                        <div style={{ marginTop: 40 }}>
                            {this.renderSectionTitle({
                                title: t("criteria.criteria"),
                                rightActions: this.renderDownloadButton(this.props.resonator.id),
                            })}
                            <ResonatorStats
                                resonatorId={resonatorId}
                                follower={follower}
                                followerGroup={followerGroup}
                            />
                        </div>
                        {followerGroup?.members && this.state.member && (
                            <div style={{ marginTop: 40 }}>
                                {this.renderSectionTitle({
                                    title: "Member Responses",
                                    bottomLeftActions: this.renderMemberSelect(),
                                    bottomRightActions: this.renderDownloadButton(
                                        getChildResonator(this.state.member.id)?.id
                                    ),
                                })}
                                <ResonatorStats
                                    resonatorId={getChildResonator(this.state.member.id)?.id}
                                    follower={getFollower(this.state.member.id)}
                                />
                            </div>
                        )}
                    </React.Fragment>
                )}
                {this.state.hrv_readings.length > 0 && (
                    <React.Fragment>
                        <ResonatorHRVReadings readings={this.state.hrv_readings} />
                    </React.Fragment>
                )}
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const resonators = resonatorsSelector(state);
    const followersData = followersSelector(state);
    const followerGroupsData = followerGroupsSelector(state);
    const resonator = _.find(resonators, (r) => r.id === ownProps.match.params.resonatorId);
    const followerGroup = _.find(
        followerGroupsData.followerGroups,
        (fg) => fg.id === ownProps.match.params.followerGroupId
    );
    const childResonatorCount = followerGroup?.resonators?.length;

    return {
        resonator,
        follower: _.find(followersData.followers, (f) => f.id === ownProps.match.params.followerId),
        followerGroup: followerGroup,
        getFollower: (followerId) => _.find(followersData.followers, (f) => f.id === followerId),
        getChildResonator: (followerId) =>
            _.find(
                followerGroup.resonators,
                (r) => r.parent_resonator_id === ownProps.match.params.resonatorId && r.follower_id === followerId
            ),
        childResonatorCount: childResonatorCount,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchMembersWithResonatorChildren: followerGroupsActions.fetchMembersWithResonatorChildren,
            fetchFollowerGroupResonators: followerGroupsActions.fetchFollowerGroupResonators,
            fetchFollowerResonators: followerActions.fetchFollowerResonators,
            downloadResonatorStats: statsActions.downloadResonatorStats,
            fetchResonatorHrvReadings: followerActions.fetchResonatorHrvReadings,
            push,
        },
        dispatch
    );
}

export default withTranslation(connect(mapStateToProps, mapDispatchToProps)(withWidth()(ShowResonator)));
