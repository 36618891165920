import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push as goto } from "connected-react-router";
import {
    List,
    ListItem,
    ListSubheader,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Paper,
    makeStyles,
    Grow,
    Typography,
} from "@material-ui/core";

import ResonatorAvatar from "./ResonatorAvatar";
import { formatResonatorTime } from "./followers/utils";
import {RemoveRedEye} from "@material-ui/icons";
import moment from 'moment';
import { useTranslation } from 'react-multi-lang';
import * as utils from "components/utils";

const useStyles = makeStyles((theme) => ({
    itemBig: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    avatarBig: {
        height: theme.spacing(8),
        width: theme.spacing(8),
    },
    itemTextBig: {
        marginLeft: theme.spacing(2),
    },
    insetTextBig: {
        marginLeft: theme.spacing(3),
    },
    noResults: {
        textAlign: "center",
    },
}));

function renderActivity(activity, goto) {
    const t = useTranslation();
    const classes = useStyles();
    const listItemClass = classes.itemBig;
    const avatarClass = classes.avatarBig;
    const itemTextClass = classes.itemTextBig;
    const insetBigClass = classes.insetTextBig;

    return (
        <ListItem
            divider
            className={listItemClass}
        >
            {activity.picture ? (
                <ListItemAvatar>
                    <ResonatorAvatar resonator={activity} className={avatarClass} />
                </ListItemAvatar>
            ) : null}
            <ListItemText
                inset={!activity.picture}
                primary={t('followers.follower')+": " + activity.follower_name}
                secondary={<React.Fragment>
                    <Typography>{t('resonators.resonator')}: <span style={{ direction: utils.getDir(activity.resonator_name) || "ltr" }}>{activity.resonator_name}</span></Typography>
                    <Typography>{activity.type}</Typography>
                    {activity.is_group_resonator && <Typography>{t('resonators.type')}: {t('resonators.group_resonator')}</Typography>}
                    <Typography>{formatResonatorTime(activity.date)}</Typography>
                    {(activity.read_date && moment(activity.date).diff(moment(activity.read_date), "minutes") >= 10) && <Typography>{"(Read: " + formatResonatorTime(activity.read_date) + ")"}</Typography>}
                </React.Fragment>}
                primaryTypographyProps={{ noWrap: true }}
                classes={{ root: itemTextClass, inset: insetBigClass }}
            />
            <ListItemSecondaryAction>
                <IconButton onClick={() => goto(`/followers/${activity.follower_id}/resonators/${activity.resonator_id}/show`)}>
                    <RemoveRedEye />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
}

function ActivityList({ activities, subheader, paperProps = {}, goto }) {
    const t = useTranslation();
    const classes = useStyles();
    const todayActivities = activities.filter(a => moment(a.date) >= moment().startOf('day'));
    const yesterdayActivities = activities.filter(a => moment(a.date) < moment().startOf('day') && moment(a.date) >= moment().subtract(1, 'days').startOf('day'));
    const thisWeekActivities = activities.filter(a => moment(a.date) < moment().subtract(1, 'days').startOf('day') && moment(a.date) >= moment().startOf('week'));
    const lastWeekActivities = activities.filter(a => moment(a.date) < moment().startOf('week') && moment(a.date) >= moment().subtract(1, 'week').startOf('week'));
    const restOfActivities = activities.filter(a => moment(a.date) < moment().subtract(1, 'week').startOf('week'));
    const groupedByMonthActivities = _.groupBy(restOfActivities, (a) => moment(a.date).format('MMMM YYYY'));

    return (
        <List>
            <ListSubheader>{subheader}</ListSubheader>
            <Paper {...paperProps}>
                {!activities.length && (
                    <ListItem className={classes.noResults}>
                        <ListItemText primary={<Typography color="textSecondary">{t('general.no_results')}</Typography>} />
                    </ListItem>
                )}
                {todayActivities.length > 0 && (
                    <>
                        <ListSubheader>{t('activity.today')}</ListSubheader>
                        {todayActivities.map((activity, i) => (
                            <Grow in key={"today_"+i}>{renderActivity(activity, goto)}</Grow>
                        ))}
                    </>
                )}
                {yesterdayActivities.length > 0 && (
                    <>
                        <ListSubheader>{t('activity.yesterday')}</ListSubheader>
                        {yesterdayActivities.map((activity, i) => (
                            <Grow in key={"yesterday_"+i}>{renderActivity(activity, goto)}</Grow>
                        ))}
                    </>
                )}
                {thisWeekActivities.length > 0 && (
                    <>
                        <ListSubheader>{t('activity.this_week')}</ListSubheader>
                        {thisWeekActivities.map((activity, i) => (
                            <Grow in key={"this_week_"+i}>{renderActivity(activity, goto)}</Grow>
                        ))}
                    </>
                )}
                {lastWeekActivities.length > 0 && (
                    <>
                        <ListSubheader>{t('activity.last_week')}</ListSubheader>
                        {lastWeekActivities.map((activity, i) => (
                            <Grow in key={"last_week_"+i}>{renderActivity(activity, goto)}</Grow>
                        ))}
                    </>
                )}
                {Object.entries(groupedByMonthActivities).map((monthActivities, x) => (
                        <>
                            <ListSubheader>{monthActivities[0]}</ListSubheader>
                            {monthActivities[1].map((activity, i) => (
                                <Grow in key={x+"_"+i}>{renderActivity(activity, goto)}</Grow>
                            ))}
                        </>
                    ))
                }
            </Paper>
        </List>
    );
}

export default connect(null, (dispatch) => bindActionCreators({ goto }, dispatch))(ActivityList);
