import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { actions } from "../../actions/registrationActions";
import { connect } from "react-redux";
import {
    Card,
    CardHeader,
    CardContent,
    Typography,
    Button,
    Box,
    Grid,
    IconButton,
    Tooltip,
    CircularProgress,
    Snackbar,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Field, reduxForm } from "redux-form";
import TextField from "../FormComponents/TextField";
import zxcvbn from "zxcvbn";
import * as validations from "../FormComponents/Validations";
import { withTranslation } from "react-multi-lang";
import "./RegitrationCode.scss";

const passwordScoreStrings = ["Very weak", "Weak", "Moderate", "Strong", "Very Strong"];
const passwordScoreColors = ["red", "red", "orange", "green", "green"];

class RegistrationCodeForm extends Component {
    constructor() {
        super();

        this.state = {
            password: "",
            passwordStrength: 0,
            passwordStrengthSuggestions: [],
            showPassowrd: false,
            waitForCodeResent: false,
            snackbar: {
                show: false,
                text: "",
                severity: "success",
            },
        };
    }

    toggleShowPassword = () => {
        this.setState({ ...this.state, showPassowrd: !this.state.showPassowrd });
    };

    showHidePasswordText = () => (this.state.showPassowrd && "Hide Password") || "Show Password";

    getPasswordStrenghtStyle = () => {
        if (this.state.password.length > 0) return { color: passwordScoreColors[this.state.passwordStrength] };
        return { color: "black" };
    };

    getPasswordStrengthText = () => {
        if (this.state.password.length > 0) return passwordScoreStrings[this.state.passwordStrength];
        return "N/A";
    };

    handleResendCode = () => {
        this.setState({ ...this.state, waitForCodeResent: true });
        setTimeout(() => this.setState({ ...this.state, waitForCodeResent: false }), 1000);
        this.props.regenerateCode({ regid: this.props.regid, onResendResult: this.handleResendCodeResult });
    };

    handleResendCodeResult = (result) => {
        if (result.error) {
            const failureSnackbar = { show: true, text: "Failed to regenerate code", severity: "error" };
            this.setState({ ...this.state, waitForCodeResent: false, snackbar: failureSnackbar });
        } else {
            const successSnackbar = {
                show: true,
                text: "Code was sent successfully. Check your email...",
                severity: "success",
            };
            this.setState({ ...this.state, waitForCodeResent: false, snackbar: successSnackbar });
        }
    };

    handleCloseSnackBar = () => {
        const newSnackbarState = { ...this.state.snackbar, show: false };
        this.setState({ ...this.state, snackbar: newSnackbarState });
    };

    renderPasswordSuggestions = () => {
        const { t } = this.props;
        if (this.state.password.length === 0)
            return <Typography variant="caption">{t("registration.password_suggestions_default_message")}</Typography>;
        return this.state.passwordStrengthSuggestions.map((item, index) => (
            <Typography key={index} variant="caption">
                {String(index + 1) + ". " + item}
            </Typography>
        ));
    };
    updatePasswordStrength = (event, newValue, previousValue, name) => {
        const passwordEval = zxcvbn(newValue);
        this.setState({
            ...this.state,
            password: newValue,
            passwordStrength: passwordEval.score,
            passwordStrengthSuggestions: passwordEval.feedback.suggestions,
        });
    };
    componentDidMount() {
        const { regid } = this.props;
        this.props.sendCodeByEmail({ regid });
        this.props.getRegistrationDetails({ regid });
    }

    isRegitrationErrorOn = () => {
        return typeof this.props.registrationError === "string" && this.props.registrationError.length > 0;
    };

    render() {
        return (
            <Card>
                <CardHeader title="Create Your Password" />
                <CardContent>
                    <Typography>
                        Welcome {this.props.clientName},
                        <br />
                        {this.props.leaderName} has added you as a client on the Psysession platform.
                        <br />
                        To verify it's you we just emailed a code to {this.props.clientEmail}. Please enter the code and
                        create a new password to continue:
                    </Typography>
                </CardContent>
                <CardContent>
                    <form
                        autoComplete="off"
                        onSubmit={this.props.handleSubmit((values) =>
                            this.props.registerByCode({ ...values, regid: this.props.regid })
                        )}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography style={{ fontWeight: "bold" }}> Enter the code from your email:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    type="text"
                                    name="code"
                                    component={TextField}
                                    props={{
                                        placeholder: "Code",
                                        error: this.isRegitrationErrorOn(),
                                        helperText: this.props.registrationError,
                                    }}
                                />
                            </Grid>
                            <Grid id="resendCodeButtonGrid" item xs={6}>
                                {(this.state.waitForCodeResent && <CircularProgress />) || (
                                    <Button id="resendCodeButton" variant="contained" onClick={this.handleResendCode}>
                                        Resend Code
                                    </Button>
                                )}
                                <Snackbar
                                    open={this.state.snackbar.show}
                                    onClose={this.handleCloseSnackBar}
                                    autoHideDuration={2000}
                                    message={this.state.snackbar.text}
                                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                    severity={this.state.snackbar.severity}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    <br></br>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{ fontWeight: "bold" }}> Enter a new password:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    type={this.state.showPassowrd ? "text" : "password"}
                                    name="password"
                                    component={TextField}
                                    props={{ placeholder: "Password" }}
                                    onChange={this.updatePasswordStrength}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Tooltip title={this.showHidePasswordText()}>
                                    <IconButton onClick={this.toggleShowPassword}>
                                        {(!this.state.showPassowrd && <Visibility />) ||
                                            (this.state.showPassowrd && <VisibilityOff />)}
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={3} />
                            <Grid item xs={6}>
                                <Field
                                    type={this.state.showPassowrd ? "text" : "password"}
                                    name="confirmPassword"
                                    component={TextField}
                                    props={{ placeholder: "Confirm Password" }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" marginTop={3}>
                                    <Typography variant="caption" style={{ fontWeight: "bold" }}>
                                        Password Strength:
                                    </Typography>
                                    <Box marginLeft={1}>
                                        <Typography variant="caption" style={this.getPasswordStrenghtStyle()}>
                                            {this.getPasswordStrengthText()}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box display="block">
                                    <Typography variant="caption" style={{ fontWeight: "bold" }}>
                                        Suggestions:
                                    </Typography>
                                    <Box className="password-suggestions" display="block" marginLeft={1}>
                                        {this.renderPasswordSuggestions()}
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box display="flex" justifyContent="center" marginTop={3}>
                            <Button type="submit" className="submitBtn" color="primary" variant="contained">
                                Create Password
                            </Button>
                        </Box>
                    </form>
                </CardContent>
            </Card>
        );
    }
}

function mapStateToProps(state) {
    return {
        registrationError: state.registration.registrationError,
        clientName: state.registration.clientName,
        clientEmail: state.registration.clientEmail,
        leaderName: state.registration.leaderName,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            registerByCode: (values) => actions.registerByCode(values),
            getRegistrationDetails: actions.getRegistrationDetails,
            regenerateCode: actions.regenerateCode,
            sendCodeByEmail: actions.sendCodeByEmail,
        },
        dispatch
    );
}

export default withTranslation(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            form: "registration",
            validate(form) {
                let errors = {};

                errors.confirmPassword = validations.required(form.confirmPassword);

                if (form.password !== form.confirmPassword) errors["confirmPassword"] = "Passwords do not match";

                errors.password = validations.required(form.password);

                return errors;
            },
        })(RegistrationCodeForm)
    )
);
