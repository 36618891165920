import React from "react";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";

export default ({ isAdmin, isSystem, toggleSystem, owner }) => {
    if (!isAdmin) return false;
    return (<>
        {owner && <div style={{textAlign: 'center'}}>Contributor: {owner}</div>}
        <RadioGroup
            style={{ justifyContent: 'center', position: "relative" }}
            row
            name="is_system"
            value={isSystem}
            onChange={toggleSystem}
        >
            <FormControlLabel value={false} control={<Radio color="primary" />} label="Private" />
            <FormControlLabel value={true} control={<Radio color="primary" />} label="Public" />
        </RadioGroup>
    </>);
};
