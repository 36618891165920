import React, { useState, useEffect } from "react";
import { useTranslation } from "react-multi-lang";
import moment from "moment";
import { Button, ButtonGroup, IconButton } from "@material-ui/core";
import {
    PlayArrow as PlayButton,
    Stop as StopButton,
    Pause as PauseButton,
    Replay as RestartButton,
    Favorite,
} from "@material-ui/icons";

import "./Timer.scss";
import BreatherIcon from "./BreatherIcon";
import AmplitudeIcon from "./AmplitudeIcon";

const BreatherTimer = function Timer({
    pausePlayBreather,
    HRVConnected,
    toggleHideBreather,
    bpm,
    amp,
    setBreateherPause,
}) {
    const t = useTranslation();
    const [time, setTime] = useState(0);
    const [timeInterval, setTimeInterval] = useState(false);
    const [action, setAction] = useState("hide");

    useEffect(() => {
        if (timeInterval) {
            setBreateherPause(true);
        } else {
            setBreateherPause(false);
        }
    }, [timeInterval]);

    const toggleAction = () => {
        if (action === "show") {
            setAction("hide");
        } else {
            setAction("show");
        }
        // callback to the Breather component
        toggleHideBreather();
    };

    function startTimer() {
        if (timeInterval) return false;
        const interval = window.setInterval(() => {
            setTime((currentTime) => currentTime + 1);
        }, 1000);
        setTimeInterval(interval);
        pausePlayBreather(0);
    }

    function pauseTimer(stop = false) {
        clearInterval(timeInterval);
        setTimeInterval(false);
        stop !== true && pausePlayBreather(1);
    }

    function stopTimer() {
        pauseTimer(true);
        setTime(0);
        pausePlayBreather(2);
    }

    function restartTimer() {
        setTime(0);
        pausePlayBreather(3);
    }

    return (
        <div id="breatherTimer">
            <div className="breather_buttons">
                <ButtonGroup>
                    {timeInterval ? (
                        <Button size="small" onClick={pauseTimer}>
                            <PauseButton />
                        </Button>
                    ) : (
                        <Button size="small" onClick={startTimer}>
                            <PlayButton />
                        </Button>
                    )}
                    <Button size="small" onClick={restartTimer}>
                        <RestartButton />
                    </Button>
                    <Button size="small" onClick={stopTimer}>
                        <StopButton />
                    </Button>
                    {HRVConnected && (
                        <Button size="small" onClick={toggleAction}>
                            <BreatherIcon action={action} />
                        </Button>
                    )}
                </ButtonGroup>
            </div>
            <div className="breather_time">{moment.utc(time * 1000).format("mm:ss")}</div>
            {bpm && (
                <div id="BPM_Metric" className="HRV_Metrics">
                    <Favorite className="HRV_icon" /> BPM: {bpm}
                </div>
            )}
            {amp && (
                <div className="HRV_Metrics">
                    <AmplitudeIcon className="HRV_icon" /> AMP: {amp}
                </div>
            )}
        </div>
    );
};

export default BreatherTimer;
