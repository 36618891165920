import React from "react";
import { Typography } from "@material-ui/core";
import logoImg from '../images/logo.svg';
import './HeaderLogo.scss'

export default function HeaderLogo() {
    return (<div className="headerLogo">
            <img src={logoImg} />
            <Typography
                variant="h6"
                style={{
                    fontSize: "14px",
                }}
            >
                Psysession
            </Typography>
        </div>
    );
}
