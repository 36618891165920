import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useParams } from "react-router-dom";

import RegistrationCodeForm from "./RegistrationCodeForm";
import Layout from "../layouts/EmptyCenteredLayout";
import { actions } from "../../actions/registrationActions";

const RegistrationPage = (props) => {
    const { uuid } = useParams();
    return (
        <Layout colorful>
            <RegistrationCodeForm regid={uuid} />
        </Layout>
    );
};

export default RegistrationPage;
