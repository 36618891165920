import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "../../actions/resonatorCreationActions";
import BasicStep from "./Steps/basic";
import ScheduleStep from "./Steps/schedule";
import ActivationStep from "./Steps/activation";
import MediaStep from "./Steps/media";
import AppsStep from "./Steps/Apps";
import CriteriaStep from "./Steps/Criteria";
import InteractionTypes from "./Steps/InteractionTypes";
import { Typography, Button, CircularProgress, Stepper, Step, StepLabel, StepContent } from "@material-ui/core";
import Questionnaire from "./Steps/Questionnaire";
import DailyDiary from "./Steps/DailyDiary";
import BackButton from "./Steps/backButton";
import followersSelector from "../../selectors/followersSelector";
import { withTranslation } from "react-multi-lang";
import _ from "lodash";

class EditResonator extends Component {
    static propTypes = {
        editMode: PropTypes.bool,
    };

    static defaultProps = {
        editMode: true,
    };

    constructor(props) {
        super(props);

        // workaround to handle MUIRichTextEditor as an unconnected form Field.
        // This field can't be re-rendered during edit because cursor jumps to start of line.
        // To avoid this, we manage this value out of redux. Two cases: Edit and Create (editMode is false)
        // In Create mode the value is updated at the steBase level (wrapper of the BasicStep component). In Edit mode the value is kept here.
        this.description = null;

        this.editMode = false;

        this.state = {
            activeStep: 0,
            noCreationStep: props.resonator,
            interactionTypeUsed: 0,
            oneOffValue: "off",
            maxCompletedStep: 0,
        };

        this.steps = [
            this.renderBasic.bind(this),
            this.renderSchedule.bind(this),
            this.renderMedia.bind(this),
            // this.renderInteractionTypes.bind(this),
            this.renderInteraction.bind(this),
            this.renderApps.bind(this),
            this.renderFinal.bind(this),
        ];

        this.handleNext = this.handleNext.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.updateActiveStep = this.updateActiveStep.bind(this);
        this.handleFinalUpdate = this.handleFinalUpdate.bind(this);
        this.updateIntractionType = this.updateIntractionType.bind(this);
        this.updateOneOff = this.updateOneOff.bind(this);
        this.renderCriteria = this.renderCriteria.bind(this);
        this.renderQuestionnaire = this.renderQuestionnaire.bind(this);
        this.renderDailyDiary = this.renderDailyDiary.bind(this);
        this.updateDescriptionEditMode = this.updateDescriptionEditMode.bind(this);
    }

    updateDescriptionEditMode(newValue) {
        this.description = newValue;
    }
    componentDidMount() {
        const { followerId, followerGroupId, resonatorId } = this.props.match.params;
        this.props.reset({
            targetType: followerId ? "follower" : "followerGroup",
            targetId: followerId || followerGroupId,
            resonatorId,
        });
    }

    handleNext() {
        const { followerId } = this.props.match.params;
        if (followerId && this.props.getFollower(followerId)?.is_system && this.state.activeStep === 0) {
            this.updateActiveStep(this.state.activeStep + 2);
        } else {
            this.updateActiveStep(this.state.activeStep + 1);
        }
    }

    handleBack() {
        const { followerId } = this.props.match.params;
        if (followerId && this.props.getFollower(followerId)?.is_system && this.state.activeStep === 2) {
            this.updateActiveStep(this.state.activeStep - 2);
        } else {
            this.updateActiveStep(this.state.activeStep - 1);
        }
    }

    handleFinalUpdate(showPreview = false) {
        const { followerId } = this.props.match.params;
        if (this.editMode) {
            this.props.updateFinal({
                targetType: followerId ? "follower" : "followerGroup",
                showPreview,
                description: this.description,
            });
        } else {
            this.props.updateFinal({
                targetType: followerId ? "follower" : "followerGroup",
                showPreview,
            });
        }
    }

    updateActiveStep(activeStep) {
        this.setState({
            activeStep,
            maxCompletedStep: Math.max(this.state.maxCompletedStep, activeStep - 1),
        });
    }

    updateIntractionType = (interactionTypeUsed) => {
        this.setState({ interactionTypeUsed });
        if (this.formData !== undefined) this.formData.interactionType = interactionTypeUsed;
    };

    updateOneOff = (oneOffValue) => {
        this.setState({ oneOffValue });
    };

    renderBasic() {
        const { t } = this.props;
        const { followerId } = this.props.match.params;

        // note how we override the value of description. We don't want the value from resonatorCreation because
        // during edit we manage the value internally and resonatorCreation is not up-to-date (see comment above)
        function resolveDescription(thisDescription, thisEditMode, props) {
            if (thisEditMode && props.resonator) {
                return thisDescription ? thisDescription : props.formData.description;
            } else {
                return null;
            }
        }
        return {
            label: t("resonators.step_basic"),
            content: (
                <BasicStep
                    isSystemFollower={this.props.getFollower(followerId)?.is_system}
                    editMode={this.editMode}
                    onNext={this.handleNext}
                    updateDescriptionEditMode={this.updateDescriptionEditMode}
                    description={resolveDescription(this.description, this.editMode, this.props)}
                />
            ),
        };
    }

    renderActivation() {
        return {
            label: "Activation",
            content: <ActivationStep editMode={this.editMode} onNext={this.handleNext} onBack={this.handleBack} />,
        };
    }

    renderSchedule() {
        const { t } = this.props;
        const { followerId } = this.props.match.params;
        if (this.props.getFollower(followerId)?.is_system) return false;

        return {
            label: t("resonators.step_schedule"),
            content: (
                <ScheduleStep
                    editMode={this.editMode}
                    onNext={this.handleNext}
                    onBack={this.handleBack}
                    updateOneOff={this.updateOneOff}
                    targetType={followerId ? "follower" : "followerGroup"}
                />
            ),
        };
    }

    renderMedia() {
        const { t } = this.props;

        return {
            label: t("resonators.step_media"),
            content: <MediaStep editMode={this.editMode} onNext={this.handleNext} onBack={this.handleBack} />,
        };
    }

    renderApps() {
        const { t } = this.props;

        return {
            label: t("resonators.step_apps"),
            content: <AppsStep editMode={this.editMode} onNext={this.handleNext} onBack={this.handleBack} />,
        };
    }

    renderInteractionTypes() {
        const { t } = this.props;

        return {
            label: t("resonators.step_interaction"),
            content: (
                <InteractionTypes
                    editMode={this.editMode}
                    onNext={this.handleNext}
                    onBack={this.handleBack}
                    updateIntractionType={this.updateIntractionType}
                />
            ),
        };
    }

    renderInteraction() {
        if (this.state.interactionTypeUsed === undefined || this.state.interactionTypeUsed == 0)
            return this.renderCriteria();
        else if (this.state.interactionTypeUsed == 1) return this.renderQuestionnaire();
        else if (this.state.interactionTypeUsed == 2) return this.renderDailyDiary();
    }

    renderCriteria() {
        const { t } = this.props;

        return {
            label: t("criteria.criteria"),
            content: <CriteriaStep editMode={this.editMode} onNext={this.handleNext} onBack={this.handleBack} />,
        };
    }

    renderQuestionnaire() {
        return {
            label: "Questionnaire",
            content: <Questionnaire editMode={this.editMode} onNext={this.handleNext} onBack={this.handleBack} />,
        };
    }

    renderDailyDiary() {
        return {
            label: "Daily Diary",
            content: <DailyDiary editMode={this.editMode} onNext={this.handleNext} onBack={this.handleBack} />,
        };
    }

    renderFinal() {
        const { t } = this.props;
        const { followerId } = this.props.match.params;

        return {
            label: t("resonators.step_final"),
            content: (
                <div>
                    {!this.props.getFollower(followerId)?.is_system && <ActivationStep />}
                    <div className="navButtons">
                        {!this.editMode && (
                            <BackButton
                                onClick={this.handleBack}
                                style={{ marginRight: 8 }}
                                disabled={this.props.showSpinnerFinalUpdate}
                            />
                        )}
                        <span style={{ position: "relative", width: "max-content" }}>
                            <Button
                                color="primary"
                                variant="contained"
                                style={{ margin: "5px" }}
                                onClick={() => this.handleFinalUpdate(false)}
                                disabled={this.props.showSpinnerFinalUpdate}
                            >
                                {this.editMode ? "Update" : "Finish"}
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                style={{ margin: "5px" }}
                                onClick={() => this.handleFinalUpdate(true)}
                                disabled={this.props.showSpinnerFinalUpdate}
                            >
                                {this.editMode ? "Update" : "Finish"} and Preview
                            </Button>
                            {this.props.showSpinnerFinalUpdate && (
                                <CircularProgress
                                    size={24}
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        marginTop: -12,
                                        marginLeft: -12,
                                    }}
                                />
                            )}
                        </span>
                    </div>
                </div>
            ),
        };
    }

    renderStep(index, { label, content }) {
        return (
            <Step
                key={index}
                completed={this.editMode || index <= this.state.maxCompletedStep}
                active={this.editMode || index === this.state.activeStep}
            >
                <StepLabel>{label}</StepLabel>
                <StepContent>{content}</StepContent>
            </Step>
        );
    }

    render() {
        const { t } = this.props;
        const stepsWithContent = this.steps.map((step) => step());
        const { resonatorId } = this.props.match.params;
        this.editMode = !!resonatorId;
        const resonatorFetched = resonatorId === this.props.resonator?.id;

        return (
            (this.editMode && !resonatorFetched && (
                <CircularProgress
                    size={24}
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: -12,
                        marginLeft: -12,
                    }}
                />
            )) || (
                <div style={{ flex: 1, padding: "10px 20px" }}>
                    <Typography variant="h5">
                        {this.editMode ? t("resonators.edit") : t("resonators.create")}
                    </Typography>
                    <Stepper nonLinear={true} activeStep={this.state.activeStep} orientation="vertical">
                        {stepsWithContent.map((step, index) => this.renderStep(index, step))}
                    </Stepper>
                </div>
            )
        );
    }
}

function mapStateToProps(state) {
    let followersData = followersSelector(state);
    const systemFollowers = state.followers.systemFollowers;

    return {
        resonator: state.resonatorCreation.resonator,
        editMode: state.resonatorCreation.editMode,
        interactionType: state.resonatorCreation.interactionType,
        showSpinnerFinalUpdate: state.resonatorCreation.showSpinnerFinalUpdate,
        formData: state.resonatorCreation.formData,
        getFollower: (followerId) =>
            _.find(followersData.followers, (f) => f.id === followerId) ||
            _.find(systemFollowers, (f) => f.id === followerId),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateFinal: actions.updateFinal,
            reset: actions.reset,
        },
        dispatch
    );
}

export default withTranslation(connect(mapStateToProps, mapDispatchToProps)(EditResonator));
