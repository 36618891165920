import { useParams } from "react-router";
import React, { useState, useEffect } from "react";
import { Typography, makeStyles, Tabs, Tab, Paper } from "@material-ui/core";

import api from "../../api";
import ResonatorList from "./ResonatorList";
import SentResonator from "./SentResonator";
import LoadMoreButton from "./LoadMoreButton";
import LoadingOverlay from "./LoadingOverlay";
import { isResonatorExpired } from "../utils";

const useStyles = makeStyles((theme) => ({
    openResonatorsSubheader: {
        marginBottom: theme.spacing(2),
    },
}));

export default function ResonatorsOverview() {
    const [resonators, setResonators] = useState([]);
    const [staticResonators, setStaticResonators] = useState([]);
    const [tab, setTab] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [page, setPages] = useState(0);

    const classes = useStyles();
    const { sentResonatorId } = useParams();

    function updateResonator(resonator) {
        setResonators(resonators.map((r) => (r.id === resonator.id ? { ...r, done: resonator.done, status: resonator.status } : r)));
    }

    useEffect(() => {
        setLoading(true);
        api.get(`/follower/resonators?page=${page}`)
            .then((data) => {
                setTotalCount(data.totalCount);
                setResonators(resonators.concat(data.resonators));
                setStaticResonators(resonators.concat(data.staticResonators));
                if (!data.totalCount) setTab(1);
            })
            .finally(() => setLoading(false));
    }, [page]);

    return loading ? (
        <LoadingOverlay loading={loading} />
    ) : sentResonatorId ? (
        <SentResonator sentResonatorId={sentResonatorId} updateResonator={updateResonator} />
    ) : (
        <>
            <Tabs value={tab} onChange={(event, newValue) => setTab(newValue)} indicatorColor="primary">
                <Tab label="Ongoing" />
                <Tab label="Something Happened!" />
            </Tabs>
            <Paper hidden={tab !== 0}>
                <ResonatorList
                    big
                    viewStats
                    paperProps={{ elevation: 6 }}
                    resonators={resonators.filter((resonator) => !resonator.done && !isResonatorExpired(resonator))}
                    subheader={
                        <Typography variant="h6" className={classes.openResonatorsSubheader}>
                            Pending Resonators
                        </Typography>
                    }
                />
                <ResonatorList
                    viewStats
                    paperProps={{ variant: "outlined" }}
                    resonators={resonators.filter((resonator) => resonator.done || isResonatorExpired(resonator))}
                    subheader="Finished Resonators"
                />
                {resonators.length === totalCount ? null : (
                    <LoadMoreButton text="load older" loading={loading} loadMore={() => setPages(page + 1)} />
                )}
            </Paper>
            <Paper hidden={tab !== 1}>
                <ResonatorList
                    big
                    is_static
                    paperProps={{ variant: "outlined" }}
                    resonators={staticResonators}
                    subheader={
                        <Typography variant="h6" className={classes.openResonatorsSubheader}>
                            Static Resonators
                        </Typography>
                    }
                />
            </Paper>
        </>
    );
}
