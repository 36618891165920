import { ActionCreatorHelper } from "../saga-reducers-factory-patch";

const actionsList = [
    "FETCH",
    "UPDATE",
    "CREATE",
    "DELETE",
    "FREEZE",
    "UNFREEZE",
    "TOGGLE_DISPLAY_FROZEN",
    "FILTER_GROUPS",
    "FILTER_GROUPS_ALL",
    "EDIT",
    "FILTER_BY_CLINIC_ID",
    "FETCH_FOLLOWER_RESONATORS",
    "ALPHABET_SORT",
    "FILTER_TAGS",
    "FILTER_TAGS_ALL",
    "FILTER_RESONATOR_TAGS",
    "FILTER_RESONATOR_TAGS_ALL",
    "FILTER_RESONATOR_TYPE",
    "FILTER_RESONATOR_TYPE_ALL",
    "SEND_INVITATION_EMAIL",
    "FETCH_RESONATOR_HRV_READINGS",
];

export const types = ActionCreatorHelper.createTypes(actionsList, "FOLLOWERS_");
export const actions = ActionCreatorHelper.createActions(actionsList, "FOLLOWERS_");
